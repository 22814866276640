import DeleteTaxZone from "../modals/DeleteTaxZone";
import NewTaxZone from "../modals/NewTaxZone";
import TaxInUse from "../modals/TaxInUse";

const TaxZone = (taxZone, taxRates, t, shopsData, moviesData, projectCount, readOnly, isCorporate) => {
    const dataType = "tax-zone";

    const isUsed = projectCount > 0;

    const taxesCount = taxRates?.length;
    const taxesTooltip = taxesCount
        ? `<div class='w-auto min-w-40 mb-3 text-left'>${taxRates
              .map(
                  (t) =>
                      `<div class='flex items-center w-full justify-between my-2'><span class="pr-2">${t.name}</span><span>${t.value}%</span></div>`
              )
              .join("")}</div>`
        : "";

    return {
        id: taxZone.id,
        dataType,
        executeSectionQuery: true,
        name: taxZone.name,
        taxRates,
        info: [
            {
                name: "name",
                value: taxZone.name,
                cellContent: "text",
                specialWidth: "w-3/12",
            },
            {
                cellContent: "labels",
                value: [[0, `${taxesCount} ${t("taxes")}`, "#0F63BD", taxesTooltip, true]],
                specialWidth: "w-3/12 truncate",
                cellAdjust: "labels",
            },
            isCorporate
                ? {
                      cellContent: "button",
                      modal: TaxInUse(taxZone, t, { taxZone, shopsData, moviesData }),
                      name: isUsed ? t("used in x projects", { count: projectCount }) : "",
                      specialWidth: "w-5/12 truncate",
                      adjust: "text-zafiro-600 pl-1 w-auto w-fit-content cursor-pointer",
                      adjustName: "auto",
                  }
                : null,
            ...(readOnly
                ? []
                : [
                      {
                          cellContent: "icon",
                          value: taxZone.id,
                          evaluate: true,
                          specialWidth: "w-0.5/12",
                          isAction: true,
                          modal: [NewTaxZone(taxZone, taxRates, readOnly)],
                          name: "tax-zone",
                          icon: {
                              name: "edit",
                              id: taxZone.id,
                              values: taxZone.id,
                              itemType: dataType,
                          },
                      },
                      {
                          cellContent: "icon",
                          value: taxZone.id,
                          evaluate: true,
                          specialWidth: "w-0.5/12",
                          isAction: true,
                          modal: DeleteTaxZone(taxZone.id, taxZone.name, taxRates),
                          name: "tax-zone",
                          disabledIcon: isUsed,
                          tooltip: isUsed ? t("tax-zone-in-use") : null,
                          icon: {
                              name: "delete",
                              values: taxZone.id,
                              itemType: dataType,
                          },
                      },
                  ]),
        ],
    };
};

export default TaxZone;
