import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";

const UseModalTaxZoneAssociatedTaxRates = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store
    const { data, readOnly, name } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);

    //States
    const [items, setItems] = useState([]);

    //Listeners
    useEffect(() => {
        if (data && data.taxRates && data.taxRates.length > 0) {
            setItems(arrangeItems(data.taxRates));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(changeActionValues({ [name]: items }));
        // eslint-disable-next-line
    }, [items]);

    //Arrange functions
    const arrangeItems = (taxRates) => {
        let response = [];
        if (taxRates && taxRates.length > 0) {
            // eslint-disable-next-line
            taxRates.map((taxRate) => {
                response.push({
                    id: taxRate.id,
                    name: taxRate.name,
                    value: taxRate.value,
                    categoryId: taxRate.category ? taxRate.category.id : null,
                    enabled: taxRate.enabled,
                });
            });
        }
        return response;
    };

    //handle functions
    const handleAddOption = () => {
        let itemsClone = [...items];
        itemsClone.push({
            id: null,
            name: "",
            value: 0,
            enabled: true,
            categoryId: null,
        });
        setItems(itemsClone);
    };

    const handleChange = (index, name, value) => {
        let itemsClone = [...items];
        itemsClone[index][name] = value;
        setItems(itemsClone);
    };

    return (
        <>
            <div className="w-full flex">
                <div className="w-8/12">
                    <div className="flex items-center">
                        <span className="font-bold text-gray-900 first-capital">{t("associated-taxes")}</span>
                    </div>
                </div>
                <div className="w-4/12">
                    <div className="flex items-center">
                        <span className="text-gray-900 first-capital">{t("percentage")}</span>
                    </div>
                </div>
            </div>
            {items
                ? items.map((item, index) =>
                      item.enabled ? (
                          <div className="w-full flex my-2">
                              <div className="w-8/12">
                                  <div className="flex items-center pr-5">
                                      {readOnly ? (
                                          item?.name
                                      ) : (
                                          <input
                                              id={`add-tax-zone-input-text-${index}`}
                                              type="text"
                                              className={`w-full h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400
                                            ${
                                                validations && validations[`tax-name_${index}`] === false
                                                    ? " border border-red-100 "
                                                    : ""
                                            }`}
                                              value={item.name}
                                              onChange={(e) => handleChange(index, "name", e.target.value)}
                                          />
                                      )}
                                  </div>
                              </div>
                              <div className="w-2/12">
                                  <div className="flex items-center relative">
                                      {readOnly ? (
                                          `${item.value}%`
                                      ) : (
                                          <>
                                              <span className="absolute right-8 text-gray-700 w-3 text-left">%</span>
                                              <input
                                                  id={`add-tax-zone-input-number-${index}`}
                                                  type="number"
                                                  min={0}
                                                  max={100}
                                                  value={item.value}
                                                  className={`w-24 h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400
                                            ${
                                                validations && validations[`tax-value_${index}`] === false
                                                    ? " border border-red-100 "
                                                    : ""
                                            }`}
                                                  onChange={(e) => handleChange(index, "value", e.target.value)}
                                              />
                                          </>
                                      )}
                                  </div>
                              </div>
                              {readOnly ? null : (
                                  <div className="w-2/12">
                                      <div className="flex items-center h-full pl-4">
                                          <span
                                              className="icon icon-delete text-xl cursor-pointer text-center"
                                              onClick={(e) => handleChange(index, "enabled", false)}
                                          ></span>
                                      </div>
                                  </div>
                              )}
                          </div>
                      ) : null
                  )
                : null}
            {readOnly ? null : (
                <div className="w-full flex mt-5">
                    <div id={`add-tax-zone-add-option-button`} className="w-12/12 text-left">
                        <span className="text-zafiro-400 font-bold cursor-pointer" onClick={handleAddOption}>
                            <span className="icon icon-add mr-2"></span>
                            {t("add-option")}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default UseModalTaxZoneAssociatedTaxRates;
