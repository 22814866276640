import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function useInputPercent({
    id,
    name,
    ph,
    value,
    adjust,
    limits = { min: 0, max: 100 },
    action,
    disabled,
    percentDisplay = "%",
    adjustArrowTop,
    adjustArrowBottom,
}) {
    const [inputValue, setInputValue] = useState(value);
    const { max, min } = limits;
    const { t } = useTranslation();

    const showValue = (e) => {
        e.target.value = e.target.value.replace(` ${percentDisplay}`, ``);
    };

    const showPercent = (e) => {
        //invalid not numeric data
        if (!/^\d*\.?\d*$/.test(e.target.value)) {
            const inputP = document.querySelector(`#${id}`);
            inputP.value = inputValue + ` ${percentDisplay}`;
            return;
        }
        if (e.target.value > max) {
            e.target.value = max;
        }
        if (e.target.value < min) {
            e.target.value = min;
        }
        setInputValue(e.target.value);
        if (action) {
            action(e.target.value, id);
        }

        e.target.value += ` ${percentDisplay}`;
    };

    const movePercent = (direction) => {
        if (disabled) {
            return;
        }

        const inputP = document.querySelector(`#${id}`);
        let _percent = parseInt(inputP.value.replace(` %`, ``));

        if (direction === "up") {
            _percent += 1;
        }
        if (direction === "down") {
            _percent -= 1;
        }
        if (_percent > max) {
            _percent = max;
        }
        if (_percent < min) {
            _percent = min;
        }

        inputP.value = _percent + ` ${percentDisplay}`;

        setInputValue(_percent);
        if (action) {
            action(_percent, id);
        }
    };
    useEffect(() => {
        if (document.querySelector(`#${id}`)) {
            const inputP = document.querySelector(`#${id}`);
            let _percent = parseInt(inputP.value.replace(` ${percentDisplay}`, ``));
            inputP.value = _percent + ` ${percentDisplay}`;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const inputP = document.querySelector(`#${id}`);
        setInputValue(value);
        inputP.value = value + ` ${percentDisplay}`;
        // eslint-disable-next-line
    }, [value]);

    return (
        <div className="grid grid-flow-col grid-cols-1">
            <input
                id={id}
                type="text"
                className={`flex-inline float-left w-full bg-gray-200 rounded p-2 ${adjust} ${
                    disabled ? "text-gray-700" : ""
                }`}
                placeholder={ph ? t(ph) : t(name)}
                defaultValue={inputValue}
                disabled={disabled}
                onFocus={showValue}
                onBlur={showPercent}
            ></input>
            <div className={`flex flex-col flex-inline float-left  text-xs m-auto pl-2`}>
                <button
                    id={`up-${id}`}
                    className={`icon-chevron-up ${adjustArrowTop ? adjustArrowTop : " pb-1"} m-auto pb-2 ${
                        !disabled && "cursor-pointer"
                    } ${disabled ? "text-gray-700" : " text-gray-900"}`}
                    onClick={() => movePercent("up")}
                ></button>
                <button
                    id={`down-${id}`}
                    className={`icon-chevron ${adjustArrowBottom ? adjustArrowBottom : ""} m-auto ${
                        !disabled && "cursor-pointer"
                    } ${disabled ? "text-gray-700" : " text-gray-900"}`}
                    onClick={() => movePercent("down")}
                ></button>
            </div>
        </div>
    );
}

export default useInputPercent;
