import Icon from "components/Icon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import TextInput from "components/TextInput";
import UseLabel from "components/Inputs/useLabel";
import Checkbox from "components/Inputs/Checkbox";
import Select from "components/Select";
import Button from "components/Button";
import { NetworkSettingsContext } from "contexts/NetworkSettings";

export const NetworkCard = ({ network, type }) => {
    const { castSettings, setCastSettings, setWifiSettings } = useContext(NetworkSettingsContext);
    const history = useHistory();
    const { t } = useTranslation();
    const isAReportCCStatus = network.id === "reportccstatus";
    const NETWORKSETTING = "networkSettings";

    const handleCheck = (value) => {
        setCastSettings((prev) => {
            return {
                ...prev,
                [network.id]: {
                    ...prev[network.id],
                    check: value?.checked,
                },
            };
        });
    };

    const handleSelectTime = (value) => {
        setCastSettings((prev) => {
            return {
                ...prev,
                [network.id]: {
                    ...prev[network.id],
                    time: `${value}:00`,
                },
            };
        });
    };

    const handleSelect = (value) => {
        setCastSettings((prev) => {
            return {
                ...prev,
                [network.id]: {
                    ...prev[network.id],
                    auth: value,
                },
            };
        });
    };

    const handleClick = () => {
        if (type === "cast") {
            setCastSettings((prev) => {
                return {
                    ...prev,
                    [network.id]: {
                        ...prev[network.id],
                        save: true,
                    },
                };
            });
        } else if (type === "wifi") {
            setWifiSettings((prev) => {
                return {
                    ...prev,
                    [network.id]: {
                        ...prev[network.id],
                        save: true,
                    },
                };
            });
        }
    };

    const handleTextInputs = (value, id) => {
        setWifiSettings((prev) => {
            return {
                ...prev,
                [network.id]: {
                    name: id === "hotelwifiname" ? value : prev[network.id]?.name,
                    password: id === "hotelwifipass" ? value : prev[network.id]?.password,
                },
            };
        });
    };

    const renderFields = (fields) => {
        return fields?.map((field) => {
            switch (field?.type) {
                case "input":
                case "text":
                    return (
                        <div key={field?.id}>
                            <UseLabel label={field?.name} zoneStyles={NETWORKSETTING} translatable={true} />
                            <div className="mt-1">
                                <TextInput
                                    id={field?.id}
                                    value={field?.value}
                                    disabled={
                                        !field?.enabled &&
                                        (field?.id === "castwifiname" || field?.id === "castwifipass")
                                    }
                                    onChange={(value) => handleTextInputs(value, field?.id)}
                                />
                            </div>
                        </div>
                    );
                case "checkbox":
                    return (
                        <div key={field?.id} className="min-h-4 table">
                            <Checkbox
                                id={field?.id}
                                checked={field?.value}
                                disabled={!field?.enabled && field?.id === "castwifihidden"}
                                label={t(field?.name)}
                                onChange={(value) => handleCheck(value)}
                            />
                        </div>
                    );
                case "select":
                    return (
                        <div key={field?.id}>
                            <UseLabel label={field?.name} zoneStyles={NETWORKSETTING} />
                            <div className="mb-2">
                                <Select
                                    id={field?.id}
                                    allowUnselect={false}
                                    options={field?.data}
                                    value={field?.activeValue}
                                    disabled={!castSettings[network?.id]?.check || field?.disabled}
                                    onChange={(value) =>
                                        field?.id === "castwifiauth" ? handleSelect(value) : handleSelectTime(value)
                                    }
                                    search={false}
                                />
                            </div>
                        </div>
                    );
                case "counter":
                    return (
                        <p className="font-bold text-5xl" key={field.id}>
                            <span>{field?.licenses}</span>
                            <span className="text-2xl ml-2 font-semibold">{`/${field?.total}`}</span>
                        </p>
                    );
                default:
                    break;
            }
        });
    };

    return (
        <div className={`${network.adjust} bg-white text-center h-auto pt-12 pb-6 px-2 rounded relative`}>
            <div className="w-96 m-auto">
                <div className="w-18 h-18 rounded-full bg-zafiro-200 m-auto relative flex items-center justify-center">
                    <Icon type={network.icon} size="4xl" className="text-white" />
                </div>
                <h1 className="font-bold text-3xl mt-3">{t(network.title)}</h1>
                <p className="text-gray-800">
                    {t(network.description ? network.description : `${network.title}-description`)}
                </p>
                <div
                    className={`m-auto mt-5 grid grid-flow-row gap-y-4 ${
                        isAReportCCStatus ? " w-12/12 -ml-3" : "w-9/12"
                    }`}
                >
                    {renderFields(network.fields)}
                </div>
                {network.save ? (
                    <div className="w-32 mt-3 mx-auto">
                        <Button id={`${network.id}-save-button`} className="btn-blue mt-3" onClick={handleClick}>
                            {t("save")}
                        </Button>
                    </div>
                ) : network.redirect ? (
                    <div
                        id={`${network.id}-redirect-button`}
                        className="mt-3 mx-auto flex items-center justify-center"
                        onClick={() => history(network.redirectValue)}
                    >
                        <Button id="redirect-button" adjust="px-3" className="btn-blue">
                            {t(network.redirectNameButton)}
                        </Button>
                    </div>
                ) : (
                    <div className="pt-5"></div>
                )}
                {network.button && (
                    <div className="mt-3 mx-auto flex items-center justify-center">{network.button}</div>
                )}
            </div>
        </div>
    );
};
