import React, { useRef, cloneElement } from "react";
import classNames from "classnames";

import Button from "components/Button";
import Select from "components/Select";

/**
 * @params {string} id - The id of the DropdownButton
 * @param {Array} options - The options of the DropdownButton
 * @params {JSX.Element} children - The children of the DropdownButton (if options is not provided)
 * @param {string} label - The label of the DropdownButton (default option)
 * @param {boolean} disabled - The disabled state of the DropdownButton
 * @param {string} design The design of the button (blue, blue-outline)
 * @params {string} handlerPadding - Custom padding for the handler
 * @returns
 */
const DropdownButton = (props) => {
    if (!props?.id) {
        throw new Error("DropdownButton requires an id prop");
    }

    const dropdownRef = useRef(null);

    const items = props?.options || props?.children;
    const defaultOption = props?.label || (Array.isArray(items) ? items?.[0] : items);
    const others = props?.label ? items : Array.isArray(items) ? items?.slice(1) : null;
    const design = props?.design ?? "blue";
    const disabled = props?.disabled;
    const handlerPadding = props?.handlerPadding ?? "px-4 py-1";

    const isSolid = design && !["link", "classic-link"].some((d) => d === design);

    const designClass = {
        dropdown: classNames({
            // Solid button
            "rounded inline-block flex items-center justify-center": isSolid,

            // Blue
            "bg-blue-300 text-white hover:bg-blue-200 active:bg-blue-400": design === "blue" && !disabled,

            // Blue outline
            "bg-white text-blue-300 border border-blue-300 hover:border-blue-200 hover:bg-gray-200 active:bg-gray-300":
                design === "blue-outline" && !disabled,

            // Red
            "bg-red-100 text-white hover:bg-red-600 active:bg-red-800": design === "red" && !disabled,

            // Basic
            "text-black hover:text-gray-900": design === "basic" && !disabled,

            // Dark blue
            "bg-blue-800 text-white hover:bg-blue-700 active:bg-blue-900": design === "dark-blue" && !disabled,

            // White
            "bg-white text-blue-300 hover:border hover:border-gray-100": design === "white" && !disabled,

            // Disabled
            "bg-gray-300 text-gray-700": disabled && ["red", "blue", "dark-blue"].some((d) => d === design),
            "border border-gray-400 bg-white text-gray-700":
                disabled && ["blue-outline", "basic", "white"].some((d) => d === design),
        }),
        handler: classNames("w-auto rounded flex items-center space-x-2 w-full whitespace-no-wrap", handlerPadding),
        handlerIcon: classNames({
            "border-l px-3 py-1": design !== "basic" || disabled,

            // Blue outline
            "border-blue-300 hover:border-blue-200": design === "blue-outline" && !disabled,

            // White
            "border-gray-100 hover:border-gray-200": design === "white" && !disabled,

            // Disabled
            "border-gray-400": disabled,
        }),
    };

    const parseOption = (option, isDefault = false) => {
        const key = (option) => option?.key || option?.id || String(option);
        const customProps = {
            key: key(option),
            disabled,
            onClick: () => {
                if (dropdownRef.current) {
                    dropdownRef.current.close();
                }
            },
        };

        if (typeof option === "string") {
            return <div {...customProps}>{option}</div>;
        }

        if (React.isValidElement(option)) {
            return cloneElement(option, {
                key: key(option?.props),
                // if the option is not disabled, use dropdown disabled prop
                disabled: option?.props?.disabled || customProps.disabled,
                onClick: (...args) => {
                    if (option?.props?.onClick) {
                        option.props.onClick(...args);
                    }
                    customProps.onClick(...args);
                    if (args?.[0]) {
                        args[0].stopPropagation();
                    }
                },
            });
        }
        if (typeof option === "object") {
            const label = option?.label || "";
            const optionProps = {
                ...option,
                key: key(option),
                // remove label prop from option (label is rendered as children)
                label: undefined,
                // use custom class if provided, otherwise use the option's class
                className: customProps.className || option?.className,
                // if the option is not disabled, use dropdown disabled prop
                disabled: option?.disabled || customProps.disabled,
                onClick: (...args) => {
                    if (option?.onClick) {
                        option.onClick(...args);
                    }
                    customProps.onClick(...args);
                    if (args?.[0]) {
                        args[0].stopPropagation();
                    }
                },
            };
            if (isDefault) {
                return <DefaultOption {...optionProps}>{label}</DefaultOption>;
            }
            return <Option {...optionProps}>{label}</Option>;
        }
        throw new Error("Invalid option provided to DropdownButton");
    };

    return (
        <Select
            {...{
                ...props,
                options: undefined, // remove options prop (are rendered as children)
                value: "default",
                ref: dropdownRef,
                showArrow: !!others?.length,
                designClass,
                placeholder: parseOption(defaultOption, true),
            }}
        >
            {others?.length ? (
                <div key={`${props?.id}-options`} className="flex flex-col items">
                    {others.map((option) => parseOption(option))}
                </div>
            ) : null}
        </Select>
    );
};
DropdownButton.displayName = "DropdownButton";

/**
 * @param id {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @param href {string}
 * @param className {string}
 * @param children {React.Children}
 * @returns {JSX.Element}
 * @constructor
 */
export const Option = (props) => {
    const children = props?.children;

    let buttonProps = {
        design: props?.design ?? "text-link",
        ...props,
        className: classNames({
            "px-4 py-2": true,
            [props?.className]: props?.className,
        }),
    };
    delete buttonProps?.children;

    return (
        <Button design="link" {...buttonProps}>
            {children}
        </Button>
    );
};

export const DefaultOption = (props) => {
    return <Button {...{ ...props, value: "default" }} />;
};

export default DropdownButton;
