import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../useSectionHeader";
import Table, { stringValue } from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { useListPassCodes } from "hooks/GraphqlCalls/Customers/useListPassCodes";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useDeletePasscodes } from "hooks/GraphqlCalls/Customers/useDeletePasscodes";
import Loading from "components/Loading";
import { AddNewPassCode } from "./AddNewPassCode/AddNewPassCode";
import { capitalizeFirst } from "hooks/Utils/Utils";

const PasscodesConfiguration = () => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [unlimitedDuration, setUnlimitedDuration] = useState(null);
    const [accessProfilesId, setAccessProfilesId] = useState(null);
    const [init, setInit] = useState(null);
    const [active, setActive] = useState(true);
    const [sort, setSort] = useState(null);

    const [newOptions, setNewOptions] = useState([
        {
            id: "active",
            label: "active",
            value: "active",
            active: true,
        },
        {
            id: "expired",
            label: "expired",
            value: "expired",
            active: false,
        },
    ]);
    const [cols, setCols] = useState([]);
    const [header, setHeader] = useState({});
    const [filters, setFilters] = useState([]);

    const { t } = useTranslation();
    const { open, close } = useModal();
    const { passCodes, filter, refetch, loadingRefresh, setLoadingRefresh, count } = useListPassCodes(
        page,
        search,
        unlimitedDuration,
        accessProfilesId,
        init,
        active,
        sort
    );

    const handleClicked = (id) => {
        const updatedOptions = newOptions.map((option) => {
            if (option.id === id) {
                if (id === "expired") {
                    setActive(false);
                } else if (id === "active") {
                    setActive(true);
                }
                return { ...option, active: true };
            } else {
                return { ...option, active: false };
            }
        });
        setNewOptions(updatedOptions);
    };

    useEffect(() => {
        const passcodeHeader = {
            code: { title: capitalizeFirst(t("passcode")), sortable: true },
            renovation: { title: t("access-profile"), sortable: true },
            max_users: { title: capitalizeFirst(t("limit-of-devices")), sortable: true },
            duration: { title: capitalizeFirst(t("duration")), sortable: true },
        };

        const activeHeader = {
            ...passcodeHeader,
            init: { title: capitalizeFirst(t("status")), sortable: true },
            preview: { title: "", width: "10%" },
            delete: { title: "", width: "10%" },
        };

        const expiredHeader = {
            ...passcodeHeader,
            delete: { title: "", width: "5%" },
        };

        const passcodeCols = ["code", "renovation", "max_users", "duration"];
        const activeCols = [...passcodeCols, "init", "preview", "delete"];
        const expiredCols = [...passcodeCols, "delete"];

        const activeFilters = [
            {
                id: "duration",
                title: t("duration"),
                options: [
                    {
                        id: "all",
                        value: null,
                        label: `-- ${t("all")} --`,
                    },
                    {
                        id: "unlimited",
                        label: t("unlimited"),
                        value: true,
                    },
                ],
                onChange: (value) => setUnlimitedDuration(value),
            },
            {
                id: "access-profile",
                title: t("access-profile"),
                multiple: true,
                options: filter,
                onChange: (value) => setAccessProfilesId(value),
            },
            {
                id: "status",
                title: t("status"),
                options: [
                    {
                        id: "all",
                        value: null,
                        label: `-- ${t("all")} --`,
                    },
                    {
                        id: "started",
                        label: t("started"),
                        value: true,
                    },
                    {
                        id: "not-began",
                        label: t("not-started"),
                        value: false,
                    },
                ],
                onChange: (value) => setInit(value),
            },
        ];

        const expiredFilters = [
            {
                id: "access-profile",
                title: t("access-profile"),
                multiple: true,
                options: filter,
                onChange: (value) => setAccessProfilesId(value),
            },
        ];

        const header = newOptions[0]?.active ? activeHeader : expiredHeader;
        const cols = newOptions[0]?.active ? activeCols : expiredCols;
        const filters = newOptions[0]?.active ? activeFilters : expiredFilters;

        setHeader(header);
        setCols(cols);
        setFilters(filters);
    }, [newOptions, passCodes]);

    return (
        <>
            <UseSectionHeader title={["passcodes-configuration"]} />
            <main className="main-container">
                {!loadingRefresh ? (
                    <Table
                        id="passcodes-configuration"
                        paginate={true}
                        folders={
                            <div className="flex items-center">
                                {newOptions?.map((folder, index) => (
                                    <Button
                                        id={`${folder.id}-button`}
                                        key={index}
                                        onClick={() => handleClicked(folder.id)}
                                        className={`border-b-4 ${
                                            folder?.active
                                                ? "text-blue-800 border-blue-700 font-black"
                                                : "border-gray-400 text-gray-800"
                                        } px-6 py-2 text-md w-40 justify-center font-bold hover:text-zafiro-600`}
                                    >
                                        {capitalizeFirst(t(folder?.label))}
                                    </Button>
                                ))}
                            </div>
                        }
                        cols={cols}
                        header={header}
                        customPagination={{ value: page, onChange: setPage }}
                        customCount={count || 0}
                        customSearch={{ value: search, onChange: setSearch }}
                        rows={passCodes}
                        customSort={{ value: sort, onChange: setSort }}
                        search={true}
                        showCount={true}
                        topRightCorner={
                            <Button
                                id="add-passcode"
                                className="btn btn-blue px-6"
                                onClick={() =>
                                    open(
                                        <Modal
                                            id="add-passcode"
                                            title={t("new-passcode")}
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <AddNewPassCode refetch={refetch} />
                                        </Modal>
                                    )
                                }
                            >
                                {capitalizeFirst(t("add-passcode"))}
                            </Button>
                        }
                        filters={filters}
                        batchActions={{
                            options: [
                                {
                                    id: "delete",
                                    value: "delete",
                                    label: t("delete"),
                                },
                            ],
                            onChange: (action, rows) => {
                                if (action === "delete") {
                                    open(
                                        <Modal
                                            id="delete-passcode"
                                            title={capitalizeFirst(t("delete"))}
                                            className="w-1/2 p-10"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <DeleteAction rows={rows} setLoadingRefresh={setLoadingRefresh} />
                                        </Modal>
                                    );
                                }
                            },
                        }}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </main>
        </>
    );
};

export default PasscodesConfiguration;

const DeleteAction = ({ rows, setLoadingRefresh }) => {
    const [id] = useState(rows?.map((room) => room.id));
    const { t } = useTranslation();
    const { close } = useModal();
    const { deletePasscodes } = useDeletePasscodes({ id });

    const deletePasscode = () => {
        deletePasscodes();
        close();
        setLoadingRefresh(true);
    };

    return (
        <div>
            <p>{t("delete-passcodes-confirm")}</p>
            <div className="flex gap-2 items-center mt-4">
                {rows.map((row, index) => (
                    <p key={index} className="bg-gray-200 px-5 py-1 rounded">
                        {row?.code}
                    </p>
                ))}
            </div>
            <div className="flex justify-end gap-4 mt-8">
                <Button id="cancel" onClick={close} className="btn btn-white">
                    {t("cancel")}
                </Button>
                <Button id="delete" onClick={deletePasscode} className="btn btn-red">
                    {t("delete")}
                </Button>
            </div>
        </div>
    );
};
