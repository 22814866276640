import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { TVInput } from "hooks/GraphqlCalls/Hotel/Monitoring/models/Devices";
import { DEVICE_FUNCTIONS, DEVICE_TYPES } from "hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SeeSignageSchedule } from "./SeeSignageSchedule";

export const ExpandedInfo = ({ device, inputGroupData, deviceLocation, roomName, contents, roomTVID }) => {
    const { function: deviceFunction, ref, type, wifiSignal, firmwareVersion, commercialName, tvInputID, ip } = device;

    const renderExpandedInfo = (deviceFunction) => {
        switch (deviceFunction) {
            case DEVICE_FUNCTIONS.cast.value:
                if (type === DEVICE_TYPES.googleTV.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            wifiSignal={wifiSignal}
                            reference={ref}
                            deviceLocation={deviceLocation}
                            roomName={roomName}
                            contents={contents}
                            roomTVID={roomTVID}
                            deviceFunction={deviceFunction}
                        />
                    );
                } else {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            wifiSignal={wifiSignal}
                            roomName={roomName}
                            contents={contents}
                            deviceFunction={deviceFunction}
                            deviceLocation={deviceLocation}
                            roomTVID={roomTVID}
                        />
                    );
                }

            case DEVICE_FUNCTIONS.tv.value:
                if (type === DEVICE_TYPES.pc.value) {
                    return (
                        <ExpandedRows
                            type={type}
                            commercialName={commercialName}
                            inputGroupData={inputGroupData}
                            deviceLocation={deviceLocation}
                            tvInputID={tvInputID}
                            roomTVID={roomTVID}
                            deviceFunction={deviceFunction}
                            contents={contents}
                            roomName={roomName}
                        />
                    );
                } else if (type === DEVICE_TYPES.googleTV.value) {
                    return (
                        <ExpandedRows
                            type={type}
                            commercialName={commercialName}
                            reference={ref}
                            roomTVID={roomTVID}
                            deviceFunction={deviceFunction}
                            roomName={roomName}
                            deviceLocation={deviceLocation}
                            contents={contents}
                        />
                    );
                } else {
                    switch (type) {
                        case DEVICE_TYPES.samsung.value:
                        case DEVICE_TYPES.samsungOrsay.value:
                        case DEVICE_TYPES.samsungTizen.value:
                        case DEVICE_TYPES.lg.value:
                            return (
                                <ExpandedRows
                                    type={type}
                                    commercialName={commercialName}
                                    firmwareVersion={firmwareVersion}
                                    inputGroupData={inputGroupData}
                                    deviceLocation={deviceLocation}
                                    roomName={roomName}
                                    deviceFunction={deviceFunction}
                                    roomTVID={roomTVID}
                                    contents={contents}
                                    tvInputID={tvInputID}
                                />
                            );
                    }
                }
            case DEVICE_FUNCTIONS.tvAndcast.value:
                if (type === DEVICE_TYPES.googleTV.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            wifiSignal={wifiSignal}
                            deviceLocation={deviceLocation}
                            roomName={roomName}
                            deviceFunction={deviceFunction}
                            contents={contents}
                            reference={ref}
                            roomTVID={roomTVID}
                        />
                    );
                } else if (type === DEVICE_TYPES.philips.value || type === DEVICE_TYPES.lg.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            reference={ref}
                            inputGroupData={inputGroupData}
                            deviceFunction={deviceFunction}
                            deviceLocation={deviceLocation}
                            roomName={roomName}
                            contents={contents}
                            roomTVID={roomTVID}
                            tvInputID={tvInputID}
                        />
                    );
                }
            case "":
                return (
                    <ExpandedRows
                        ip={ip}
                        type={type}
                        commercialName={commercialName}
                        wifiSignal={wifiSignal}
                        deviceLocation={deviceLocation}
                        roomName={roomName}
                        deviceFunction={deviceFunction}
                        contents={contents}
                        reference={ref}
                        roomTVID={roomTVID}
                    />
                );
            default:
                throw new Error(`Device function (${deviceFunction}) not found in the list`);
        }
    };

    return renderExpandedInfo(deviceFunction);
};

const ExpandedRows = ({
    ip,
    type,
    commercialName,
    wifiSignal,
    reference,
    inputGroupData,
    tvInputID,
    firmwareVersion,
    deviceLocation,
    roomName,
    contents,
    deviceFunction,
    roomTVID,
}) => {
    const { t } = useTranslation();

    const filteredContents = contents?.flatMap((room) => room.roomTVs)?.filter((roomTV) => roomTV.id === roomTVID);

    const { open, close } = useModal();

    const contentOrder = ["TVCHANNELS", "SIGNAGESEQUENCES", "CASTINSTRUCTIONSCREEN"];

    const sortedContents = filteredContents[0]?.contents?.sort(
        (a, b) => contentOrder.indexOf(a) - contentOrder.indexOf(b)
    );

    return (
        <div className={`text-sm text-gray-800 leading-6 grid grid-cols-4 w-7/12 ml-20`}>
            <div className="flex flex-col ml-2 mt-2">
                {reference && (
                    <p>
                        {t("ref")}: {reference}
                    </p>
                )}
                {ip && (
                    <p>
                        {t("ip")}: {ip}
                    </p>
                )}
                {firmwareVersion && (
                    <p>
                        {t("tv-firmware")}: {firmwareVersion}
                    </p>
                )}
            </div>
            <div>
                <div className="flex flex-col mt-2 ml-2">
                    <p>
                        {t("type")}: {type}
                    </p>
                    <p>
                        {t("model")}: {commercialName}
                    </p>
                </div>
            </div>
            {wifiSignal && parseInt(wifiSignal) < 0 ? (
                <div className="flex items-center gap-2 mt-2 ml-2">
                    <img
                        src={require(`../../../../../assets/images/icons/${getWifiSignal(wifiSignal)}.svg`)}
                        alt="wifi-signal"
                    />
                    {t("wifi-signal")}: {wifiSignal} dBm
                </div>
            ) : null}
            {inputGroupData?.name && deviceLocation !== "COMMONZONE" && (
                <div className="mt-3">
                    <TVInput inputGroupData={inputGroupData} tvInputID={tvInputID} type={type} />
                </div>
            )}

            {deviceLocation === "COMMONZONE" ? (
                <div className="flex flex-col space-y-3">
                    <div className="flex items-center space-x-4">
                        <p className="whitespace-nowrap">{t("device-content")}:</p>
                        <div className="flex items-center space-x-4">
                            {Array.from(new Set(sortedContents)).map((content) => {
                                const isCompatible =
                                    (content === "TVCHANNELS" &&
                                        (deviceFunction === DEVICE_FUNCTIONS.tv.value ||
                                            deviceFunction === DEVICE_FUNCTIONS.tvAndcast.value) &&
                                        deviceFunction !== DEVICE_FUNCTIONS.cast.value) ||
                                    deviceFunction === DEVICE_TYPES.googleTV.value ||
                                    (content === "CASTINSTRUCTIONSCREEN" &&
                                        (deviceFunction === DEVICE_FUNCTIONS.cast.value ||
                                            deviceFunction === DEVICE_FUNCTIONS.tvAndcast.value)) ||
                                    (content === "SIGNAGESEQUENCES" &&
                                        (deviceFunction === DEVICE_FUNCTIONS.tv.value ||
                                            deviceFunction === DEVICE_TYPES.googleTV.value ||
                                            deviceFunction === DEVICE_FUNCTIONS.tvAndcast.value) &&
                                        deviceFunction !== DEVICE_FUNCTIONS.cast.value);

                                return (
                                    <div
                                        key={content}
                                        className={`relative flex items-center justify-center p-1 w-8 border-2 rounded-lg ${
                                            content === "TVCHANNELS"
                                                ? isCompatible
                                                    ? "border-green-400"
                                                    : "border-gray-400"
                                                : content === "SIGNAGESEQUENCES"
                                                ? isCompatible
                                                    ? "border-purple-900"
                                                    : "border-gray-400"
                                                : isCompatible
                                                ? "border-zafiro-400"
                                                : "border-gray-400"
                                        }`}
                                    >
                                        <Icon
                                            type={
                                                content === "TVCHANNELS"
                                                    ? "tv-settings"
                                                    : content === "SIGNAGESEQUENCES"
                                                    ? "advertising"
                                                    : "wificast"
                                            }
                                            className={`icon inline-block text-xl lg:text-xl font-bold ${
                                                content === "TVCHANNELS"
                                                    ? isCompatible
                                                        ? "text-green-400"
                                                        : "text-gray-400"
                                                    : content === "SIGNAGESEQUENCES"
                                                    ? isCompatible
                                                        ? "text-purple-900"
                                                        : "text-gray-400"
                                                    : isCompatible
                                                    ? "text-zafiro-400"
                                                    : "text-gray-400"
                                            }`}
                                            size={1.5}
                                        />
                                        {!isCompatible && (
                                            <Icon
                                                type="warning"
                                                size={1}
                                                className="absolute bottom-0 right-0 text-red-500 bg-white rounded-full"
                                                tooltip={
                                                    "The device function does not allow the configured content to be displayed."
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {filteredContents[0]?.contents?.includes("SIGNAGESEQUENCES") &&
                        deviceFunction !== DEVICE_FUNCTIONS.cast.value && (
                            <div
                                id="signage-schedule-button"
                                className="cursor-pointer text-left font-bold text-zafiro-600"
                                onClick={() => {
                                    open(
                                        <Modal
                                            title={
                                                <div className="flex items-center text-3xl font-sans font-bold">
                                                    {`${roomName} - ${t("signage-schedule")}`}
                                                </div>
                                            }
                                            onClose={close}
                                            className="w-2/3 p-10"
                                            footer={
                                                <footer className="flex w-full gap-4 mt-10 justify-end">
                                                    <Button id="close" design="blue" onClick={() => close()}>
                                                        {t("close")}
                                                    </Button>
                                                </footer>
                                            }
                                        >
                                            <SeeSignageSchedule roomTvId={roomTVID} />
                                        </Modal>
                                    );
                                }}
                            >
                                {t("signage-schedule")}
                            </div>
                        )}
                </div>
            ) : null}
        </div>
    );
};

const getWifiSignal = (wifiSignal) => {
    let res = "";
    if (parseInt(wifiSignal) >= -50) {
        res = "wifi-signal-high";
    } else if (parseInt(wifiSignal) < -50 && parseInt(wifiSignal) >= -60) {
        res = "wifi-signal-good";
    } else if (parseInt(wifiSignal) < -60 && parseInt(wifiSignal) >= -70) {
        res = "wifi-signal-medium";
    } else if (parseInt(wifiSignal) < -70) {
        res = "wifi-signal-low";
    }
    return res;
};
