import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";

const User = (user, t) => {
    const chain = tmpGetChain();
    const currentUser = Session.getUser();

    const { id, ref, fullName, email, roles, properties, hasCorporate, brands, managerRole, assignedAll } = user;
    const dataType = "user";
    let listRoles = [];
    let listProperties = [];
    let temporalIndex = 0;
    let disableCheckbox = false;
    if (roles.length > 0) {
        roles.forEach((role, index) => {
            let listAcceses = [`<strong>${t("{{accesses, capitalize}}", { accesses: t("accesses-text") })}:</strong>`];
            role.accesses.map((accesse) => listAcceses.push(t(accesse.name)));
            listRoles.push([
                index,
                `${role.name}`,
                role.type === "CUSTOMISED" ? "bg-zafiro-600" : "bg-zafiro-800",
                listAcceses,
            ]);
        });
    }
    if (brands.length > 0) {
        brands.forEach((brand) => {
            listProperties.push([temporalIndex, `${brand.name}`, "bg-orange-100"]);
            temporalIndex++;
        });
    }
    if (properties.length > 0) {
        properties.forEach((property) => {
            listProperties.push([temporalIndex, `${property.name}`, "bg-teal-500"]);
            temporalIndex++;
        });
    }
    if (assignedAll) {
        listProperties = [[1, chain?.name, "bg-purple-600"]];
    }

    const isSameUser =
        parseInt(user.id) === parseInt(currentUser?.id) && Boolean(user.superUser) === Boolean(currentUser?.superUser);
    const isEditable = currentUser?.superUser || (!isSameUser && !managerRole);

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id }],
        isEditable,
        countProperties: listProperties?.length || 0,
        hasCorporate,
        sendPassword: isEditable || !managerRole,
        disableCheckbox: disableCheckbox,
        mutationParams: {
            id: id,
            name: fullName,
            email: email,
            ref: ref,
            roles: roles,
            properties: properties,
            assignedAll: assignedAll,
            brands: brands,
            corporateUser: hasCorporate,
        },
        info: [
            {
                cellContent: "text",
                value: fullName,
                specialWidth: "w-3/12",
                icon: hasCorporate ? "corporate-access" : null,
                iconAdjust: "text-lg",
                // adjust: "inline",
                iconTooltip: "corporate-user",
                tooltip: "",
                wrap: "truncate",
            },

            {
                cellContent: "text",
                value: email,
                adjust: "inline",
                specialWidth: "w-3/12",
                wrap: "truncate",
            },
            /*{
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
            },*/
            {
                cellContent: "labelsTruncate",
                value: listProperties,
                specialWidth: "w-5/12",
                cellAdjust: "labels",
            },
            {
                cellContent: "labelsTruncate",
                value: listRoles,
                specialWidth: "w-5/12",
                cellAdjust: "labels",
            },
            /*,
            {
                name: "room-number",
                cellContent: "text",
                value: `55`,
                specialWidth: "w-3/12",
            },
            {
                name: "pms-number",
                cellContent: "text",
                value: `666`,
                specialWidth: "w-2/12",
            },*/
        ],
    };
};

export default User;
