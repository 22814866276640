import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";
import Icon from "components/Icon";

/**
 * @description
 * @component
 * @param {string} id - ID for the input
 * @param {string} tooltip - Text for tooltip
 * @param {string} label - Label for the input
 * @param {string} labelPosition - Position of the label ([left,right] default: right)
 * @param {boolean} checked - Checked state of the input
 * @param {boolean} uncheck - Allow to uncheck the input
 * @param {boolean} disabled - Disable the input
 * @param {boolean} readOnly - Read only input
 * @param {function} onChange - Function to call when the input changes
 * @param {string} className - Additional classes
 * @param {(radio|checkbox|switch)} design - Design of the input
 * @returns {JSX.Element} - Toggle component
 * @example
 * <Toggle id="input-id" checked={false} onChange={(value) => console.log(value)} className="w-1/2" />
 */
const Toggle = forwardRef(
    (
        {
            id,
            label,
            labelPosition = "right",
            checked: extChecked,
            uncheck = true,
            disabled,
            readOnly,
            onChange,
            value,
            className,
            on: customON,
            off: customOFF,
            design = "checkbox",
            tooltip = null,
            icon,
        },
        ref
    ) => {
        const { highlightComponent } = useContext(GlobalContext);

        const [checked, setChecked] = useState(extChecked);

        const isRadio = design === "radio";
        const isSwitch = design === "switch";
        const isCheckbox = design === "checkbox" || (!isRadio && !isSwitch);

        const hasCustomState = (checked && customON) || (!checked && customOFF);

        const baseClasses = classNames({
            [`toggle-${design}`]: !hasCustomState,
            [`toggle-${design}-disabled`]: !hasCustomState && disabled,
            [`toggle-${design}-checked`]: !hasCustomState && checked,
        });

        const toggleCheck = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (checked && !uncheck) {
                // Can't be unchecked
                return;
            }
            setChecked(!checked);

            if (onChange) {
                onChange({ checked: !checked, value });
            }
        };

        useImperativeHandle(ref, () => ({
            toggle: () => {
                setChecked(!checked);
            },
            check: () => {
                setChecked(true);
            },
            uncheck: () => {
                setChecked(false);
            },
        }));

        useEffect(() => {
            setChecked(extChecked);
        }, [extChecked]);

        const globalClass = classNames({
            "flex items-center": true,
            "cursor-default": disabled || readOnly,
            "cursor-pointer": !disabled && !readOnly,
            "highlight-component": highlightComponent,
            [className]: className,
        });

        const labelClass = classNames({
            "pl-2": labelPosition === "right",
            "pr-2": labelPosition === "left",
            "text-gray-500": disabled,
            "hover:text-zafiro-400": !disabled && !readOnly,
            "flex items-center gap-2": icon,
        });

        const labelComponent = label ? (
            <div id={id + "-label"} className={labelClass}>
                {icon && <Icon type={icon?.type} size={icon?.size} className={icon?.className} />}
                <span>{label}</span>
            </div>
        ) : null;

        return (
            <div
                id={`checkbox-${id}`}
                className={globalClass}
                onClick={disabled || readOnly ? null : toggleCheck}
                data-tip={tooltip ? tooltip : null}
                data-for={"dangerous-html-tooltip"}
            >
                {labelPosition === "left" ? labelComponent : null}
                {hasCustomState ? (
                    <>{checked ? customON : customOFF}</>
                ) : (
                    <div className={baseClasses}>
                        <div className={isCheckbox ? "icon icon-tick-right" : ""} />
                    </div>
                )}
                {labelPosition === "right" ? labelComponent : null}
            </div>
        );
    }
);
Toggle.displayName = "Toggle";

export default Toggle;
