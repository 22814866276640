import React from "react";

const TableContext = React.createContext({
    // Table configuration
    cols: null, // The columns (Eg: ["name", "surname", "age", "city"])
    rows: null, // The rows (Eg: [{ name: "John", surname: "Doe", age: 25, city: "New York" }])
    header: null, // The header of the table (Eg: { name: { title: "Name", sortable: true }, age: { title: "Age", width: 15, align: "right" } })

    // Table state
    visibleRows: null, // The rows that are visible after sorting, searching and pagination
    total: 0, // The total number of rows without pagination

    // Pagination
    paginate: false, // Check if the table should be paginated
    page: 1, // The current page
    perPage: 6, // The number of elements per page
    setPage: () => {}, // The function to set the page
    prevPage: () => {}, // The function to go to the previous page
    nextPage: () => {}, // The function to go to the next page

    // Searching
    search: "", // The search configuration (Eg: "John")
    setSearch: () => {}, // The function to set the search

    // Filtering
    activeFilters: [], // The active filters (Eg: [{ field: "city", value: "New York" }])
    setActiveFilters: () => {}, // The function to set the active filters

    // Sorting
    sort: null, // The sorting configuration (Eg: { field: "age", criteria: "asc" })
    setSort: () => {}, // The function to set the sorting

    // Expandable rows
    isExpanded: false, // Check if the row is expanded
    toggleExpand: (e, rowID) => {}, // The function to toggle the row expansion

    // Texts
    texts: {
        noTableData: "No data available",
        noSearchResults: "No results found",
        countResults: (count) => `Showing ${count} rows`,
    },
});

export default TableContext;
