import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useRoomGroups } from "hooks/GraphqlCalls/Hotel/Rooms/useRoomGroups";
import Loading from "components/Loading";
import Modal, { useModal } from "components/Modal";
import { AddGroup } from "./AddGroup";

const RoomGroups = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const { open, close } = useModal();
    const { roomData, ready, loadingRefresh, refetch, setLoadingRefresh, executeQuery } = useRoomGroups();

    useEffect(() => {
        executeQuery({
            variables: {
                search,
            },
        });
    }, [search]);

    return (
        <>
            <UseSectionHeader title={["roomGroups"]} value={"demo"} />
            <div className="main-container">
                {!loadingRefresh ? (
                    <>
                        <Button
                            id="add-group"
                            className="absolute right-8 btn-blue z-50"
                            onClick={() =>
                                open(
                                    <Modal
                                        id="add-room-group"
                                        title={<h1 className="text-2xl font-bold">{t("new-group-of-rooms")}</h1>}
                                        minWidth="30rem"
                                        className="w-8/12 p-10"
                                        onKeyPress={{ Enter: close }}
                                    >
                                        <AddGroup refetch={refetch} setLoadingRefresh={setLoadingRefresh} type="room" />
                                    </Modal>
                                )
                            }
                        >
                            {t("add-group")}
                        </Button>
                        <Table
                            id="roomGroups"
                            customSearch={{ value: search, onChange: setSearch }}
                            paginate={true}
                            search={true}
                            header={{
                                "group-name": { title: t("group-name"), sortable: true, width: "30%" },
                                "number-of-rooms": { title: t("number-of-rooms"), sortable: true, width: "40%" },
                                edit: { title: "", width: "10%" },
                                delete: { title: "", width: "10%" },
                            }}
                            cols={["group-name", "number-of-rooms", "edit", "delete"]}
                            rows={roomData}
                        />
                    </>
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default RoomGroups;
