import React, { useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { UseListSignages } from "./graphql/useSignages";
import { UseModalChangeName } from "./modals/useModalChangeName";
import { UseModalDuplicateSequence } from "./modals/useModalDuplicateSequence";
import { UseModalDeleteSequence } from "./modals/useModalDeleteSequence";
import { UseModalChangeAvailability } from "./modals/useModalChangeAvailability";
import { UseModalAddSequence } from "./modals/useModalAddSequence";
import { UseModalMakeDefaultSequence } from "./modals/useModalMakeDefaultSequence";
import { UseModalFilters } from "./modals/useModalFilters";
import { useLoading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import { ScheduledDateColumn } from "./utils";

const DefaultColumn = (props) => {
    return props.isDefault ? (
        <p className=" bg-zafiro-500 rounded-md px-2 py-1 text-white">{props.t("default")}</p>
    ) : (
        <Button
            design={"link"}
            id={"make-as-default"}
            onClick={() => {
                props.openModal({ name: props.name, id: props.id, getSignages: props.getSignages });
            }}
        >
            {props.t("make-default")}
        </Button>
    );
};

const StatusColumn = (props) => {
    const statusStyles = {
        AVAILABLE: "text-green-100",
        UNAVAILABLE: "text-red-100",
    };
    const classname = props.available ? statusStyles.AVAILABLE : statusStyles.UNAVAILABLE;
    const status = props.available ? props.t("available") : props.t("unavailable");
    return (
        <div className=" flex flex-col">
            <p className={classname}>{status}</p>
            {props?.available && props?.onGoing && <p className=" text-gray-500">{props.t("on-going")}</p>}
            {props?.available && !props?.onGoing && props?.expired && (
                <p className=" text-gray-500">{props.t("expired")}</p>
            )}
        </div>
    );
};

const FiltersColumn = (props) => {
    const { numberOfFilters = 0, isDefault, id, name, t, filters } = props;
    return !isDefault && numberOfFilters > 0 ? (
        <Button
            design={"link"}
            id={"filters-button"}
            onClick={() => {
                props.openModalFilters({ id, name, filters });
            }}
        >
            {numberOfFilters > 1 ? t("filters-x", { count: numberOfFilters }) : `1 ${t("filter")}`}
        </Button>
    ) : !isDefault && numberOfFilters == 0 ? (
        <p>{t("filters-x", { count: numberOfFilters })}</p>
    ) : (
        "-"
    );
};

const NameColumn = (props) => {
    return (
        <Button
            design={"link"}
            id={`button-signage-name-${props.id}`}
            onClick={() => {
                props.navigate(`/design/advertising/signage/details/${props.id}`);
            }}
        >
            {props.name}
        </Button>
    );
};

const MapRows = (props) => {
    const t = props.t;
    const {
        name,
        isDefault,
        destinationInfo,
        filtersNum,
        scheduleStart,
        scheduleEnd,
        id,
        contents,
        scheduleDailyType,
        available,
        ongoing,
        timeRanges,
    } = props.data;
    const {
        openModalChangeAvailabity,
        openModalChangeName,
        openModalMakeDefault,
        openModalDuplicateSequence,
        openModalDeleteSequence,
        openModalFilters,
    } = props.modals;

    const lastShowRange = timeRanges?.sort(function (a, b) {
        return b?.startTime?.localeCompare(a.startTime);
    })?.[0]?.endTime;
    const lastDate = timeRanges
        ? new window.ZDate(`${scheduleEnd}T${lastShowRange}`)
        : new window.ZDate(`${scheduleEnd}`);
    const expired = scheduleEnd && new window.ZDate() > lastDate;
    const numberOfFilters = filtersNum;
    const couldBeAvailable = contents?.length > 0 && scheduleDailyType && numberOfFilters > 0;

    return {
        id: id,
        data: props.data,
        expired,
        onGoing: ongoing,
        name: NameColumn({ t, name, id, navigate: props.navigate }),
        schedule_end: ScheduledDateColumn({ scheduleStart, scheduleEnd }),
        filters_num: FiltersColumn({
            t,
            name,
            filters: destinationInfo,
            numberOfFilters,
            isDefault,
            openModalFilters,
            id,
        }),
        available: StatusColumn({ t, available, onGoing: ongoing, expired }),
        default: DefaultColumn({
            id,
            isDefault,
            t,
            name,
            openModal: openModalMakeDefault,
            getSignages: props.getSignages,
        }),
        rowConfig: {
            actions: [
                {
                    id: "edit-name",
                    label: t("edit-name"),
                    onClick: () => {
                        openModalChangeName({ name, id, getSignages: props.getSignages });
                    },
                },
                {
                    id: "edit-sequence",
                    label: t("edit-sequence"),
                    onClick: () => {
                        props.navigate(`/design/advertising/signage/details/${id}`);
                    },
                },
                ...(!isDefault && available
                    ? [
                          {
                              id: "set-as-unavailable",
                              label: t("set-as-unavailable"),
                              onClick: () => {
                                  openModalChangeAvailabity({ name, available, id, getSignages: props.getSignages });
                              },
                          },
                      ]
                    : !isDefault && !available
                    ? [
                          {
                              id: "set-as-available",
                              label: t("set-as-available"),
                              disabled: !couldBeAvailable,
                              tooltip: !couldBeAvailable ? t("the-sequence-will-be-able-to-set-as-available") : null,
                              onClick: () => {
                                  openModalChangeAvailabity({ name, available, id, getSignages: props.getSignages });
                              },
                          },
                      ]
                    : []),

                {
                    id: "duplicate",
                    label: t("duplicate"),
                    onClick: () => {
                        openModalDuplicateSequence({ name, id, getSignages: props.getSignages });
                    },
                },
                {
                    id: "delete",
                    disabled: isDefault,
                    tooltip: isDefault ? t("default-sequence-cannot-be-deleted") : null,
                    label: t("delete"),
                    onClick: () => {
                        openModalDeleteSequence({ name, id, getSignages: props.getSignages, onGoing: ongoing });
                    },
                },
            ],
        },
    };
};

const Signage = () => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const { getSignages: listSignages, signages, loading } = UseListSignages();
    const [customSort, setCustomSort] = useState({ field: "is_default", criteria: "desc" });
    const [stateFilter, setStateFilter] = useState({
        available: null,
        onlyExpired: null,
        onlyOnGoing: null,
    });
    const [customSearch, setCustomSearch] = useState(null);
    const [customDateRangeFilter, setCustomDateRangeFilter] = useState({ from: null, to: null });
    const [batchActions, setBatchActions] = useState({
        duplicate: { label: t("duplicate"), value: "duplicate", disabled: false, id: "duplicate-batch-id" },
        available: {
            label: t("set-as-available"),
            value: "set-as-available",
            disabled: false,
            id: "set-as-available-batch-id",
        },
        unavailable: {
            label: t("set-as-unavailable"),
            value: "set-as-unavailable",
            disabled: false,
            id: "set-as-unavailable-batch-id",
        },
        delete: { label: t("delete"), value: "delete", disabled: false, id: "delete-batch-id" },
    });

    const { open: openModalDuplicateSequence } = UseModalDuplicateSequence();
    const { open: openModalDeleteSequence } = UseModalDeleteSequence();
    const { open: openModalChangeAvailabity } = UseModalChangeAvailability();
    const { open: openModalAddSequence } = UseModalAddSequence();
    const { open: openModalChangeName } = UseModalChangeName();
    const { open: openModalMakeDefault } = UseModalMakeDefaultSequence();
    const { open: openModalFilters } = UseModalFilters();

    const FILTER_VALUES = {
        AVAILABLE: "available",
        UNAVAILABLE: "unavailable",
        ONLY_EXPIRED: "onlyExpired",
        ONLY_ON_GOING: "onlyOnGoing",
    };

    const stateFilterOptions = [
        {
            id: "color-filter-available-ongoing-option-id",
            value: FILTER_VALUES.ONLY_ON_GOING,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("on-going")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-available-option-id",
            value: FILTER_VALUES.AVAILABLE,
            label: t("available"),
        },
        {
            id: "color-filter-available-expired-option-id",
            value: FILTER_VALUES.ONLY_EXPIRED,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("expired")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-unavailable-option-id",
            value: FILTER_VALUES.UNAVAILABLE,
            label: t("unavailable"),
        },
    ];

    function getSignages() {
        const time = Math.abs(new window.ZDate().getTimezoneOffset());
        const hours = parseInt(time / 60);
        const minutes = time % 60;
        let date = new Date();
        date.setHours(date.getHours() + hours);
        date.setMinutes(date.getMinutes() + minutes);

        listSignages({
            variables: {
                currentTime: date,
                orderBy: customSort,
                state: stateFilter?.state,
                available: stateFilter?.available,
                onlyOnGoing: stateFilter?.onlyOnGoing,
                onlyExpired: stateFilter?.onlyExpired,
                search: customSearch,
                scheduleFrom: customDateRangeFilter.from ? customDateRangeFilter.from.split("T")?.[0] : null,
                scheduleTo: customDateRangeFilter.to ? customDateRangeFilter.to.split("T")?.[0] : null,
            },
        });
    }

    useEffect(() => {
        getSignages();
    }, []);

    useEffect(() => {
        if (!customSort) {
            setCustomSort({ field: "is_default", criteria: "desc" });
        }
        getSignages();
    }, [customSort, stateFilter, customSearch, customDateRangeFilter]);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <>
            <UseSectionHeader title={["signage"]} value={"demo"} />
            <Table
                id={"signage-table"}
                cols={["name", "schedule_end", "filters_num", "available", "default"]}
                search={true}
                paginate={true}
                showCount={true}
                perPage={8}
                className={"main-container"}
                batchActions={{
                    options: Object.values(batchActions),
                    onChange: (action, rows) => {
                        if (action == batchActions?.duplicate?.value) {
                            openModalDuplicateSequence({
                                batch: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                                getSignages,
                            });
                        }
                        if (action == batchActions?.delete?.value) {
                            openModalDeleteSequence({
                                getSignages,
                                batch: true,
                                onGoing: rows.map((val) => val.ongoing).includes(true),
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions?.available?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                getSignages,
                                available: false,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions?.unavailable?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                getSignages,
                                available: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                    },
                }}
                topRightCorner={
                    <Button
                        design={"blue"}
                        id={"add-sequence-button"}
                        onClick={() => {
                            openModalAddSequence({ getSignages, firstSequence: signages.length == 0 });
                        }}
                    >
                        {t("add-sequence")}
                    </Button>
                }
                header={{
                    name: { title: t("name"), sortable: true },
                    schedule_end: { title: t("scheduled-date"), sortable: true },
                    filters_num: { title: t("filters"), sortable: true },
                    available: { title: t("status"), sortable: true },
                }}
                rows={
                    signages?.map((val) => {
                        return MapRows({
                            t,
                            data: val,
                            modals: {
                                openModalChangeAvailabity,
                                openModalDeleteSequence,
                                openModalDuplicateSequence,
                                openModalChangeName,
                                openModalMakeDefault,
                                openModalFilters,
                            },
                            navigate,
                            getSignages,
                        });
                    }) || []
                }
                filters={[
                    {
                        id: "filter-by-state",
                        title: t("state"),
                        multiple: true,
                        options: stateFilterOptions,
                        onChange: (val) => {
                            const filters = {
                                available: null,
                                onlyExpired: val?.includes(FILTER_VALUES.ONLY_EXPIRED) || null,
                                onlyOnGoing: val?.includes(FILTER_VALUES.ONLY_ON_GOING) || null,
                            };

                            const includesAvailable =
                                val?.includes(FILTER_VALUES.AVAILABLE) ||
                                val?.includes(FILTER_VALUES.ONLY_ON_GOING) ||
                                val?.includes(FILTER_VALUES.ONLY_EXPIRED);
                            const includesUnavailable = val?.includes(FILTER_VALUES.UNAVAILABLE);

                            if (includesAvailable || includesUnavailable) {
                                filters.available = [];
                                if (includesAvailable) filters.available.push(true);
                                if (includesUnavailable) filters.available.push(false);
                            }

                            setStateFilter(filters);
                        },
                    },
                ]}
                onChangeSelectedRows={(rows) => {
                    const disabledFilters = rows?.reduce(
                        (acc, curr) => {
                            if (curr?.data?.isDefault) {
                                acc.isDefault = true;
                            }
                            if (!curr?.data?.contents?.length > 0) {
                                acc.hasNotContent = true;
                            }
                            if (
                                !curr?.data?.destinationInfo?.roomGroupsTpCommonZone?.length > 0 ||
                                !curr?.data?.destinationInfo?.roomTvsTpCommonZone?.length > 0 ||
                                !curr?.data?.destinationInfo?.roomsTpCommonZone?.length > 0
                            ) {
                                acc.hasNotFilters = true;
                            }
                            return acc;
                        },
                        { isDefault: false, hasNotContent: false, hasNotFilters: false }
                    );
                    setBatchActions((val) => {
                        return {
                            duplicate: { ...val.duplicate },
                            available: {
                                ...val.available,
                                disabled: disabledFilters?.hasNotContent || disabledFilters?.hasNotFilters,
                            },
                            unavailable: {
                                ...val.unavailable,
                                disabled: disabledFilters?.isDefault,
                            },
                            delete: { ...val.delete, disabled: disabledFilters?.isDefault },
                        };
                    });
                }}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customRangeSelector={{
                    value: customDateRangeFilter,
                    onChange: setCustomDateRangeFilter,
                    hideTimePicker: true,
                }}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            ></Table>
        </>
    );
};

export default Signage;
