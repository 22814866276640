import React, { useState, useRef, forwardRef, useLayoutEffect, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Components
import UseSectionHeader from "../../useSectionHeader";
import Table from "components/ZafiroTable";
import Icon from "components/Icon";
import Button from "components/Button";

// Hooks
import { useListPropertyGroups } from "hooks/GraphqlCalls/Settings/GroupsProperties/useListPropertyGroups";
import useModalAddEditGroup from "hooks/GraphqlCalls/Settings/modals/useModalAddEditGroup";
import useModalDeletePropertyGroups from "hooks/GraphqlCalls/Settings/modals/useModalDeletePropertyGroups";
import useModalDuplicatePropertyGroup from "hooks/GraphqlCalls/Settings/modals/useModalDuplicatePropertyGroup";

export const ProjectBadge = forwardRef(({ project, className }, ref) => {
    const { t } = useTranslation();

    return (
        <div ref={ref} className={className}>
            {project?.hasChainModule && (
                <Icon
                    type="corporate-access"
                    tooltip={t("This property has access to Global Management content")}
                    className="mr-2"
                />
            )}
            <span className="whitespace-nowrap">{project.name}</span>
        </div>
    );
});

const PropertiesRow = ({ projects }) => {
    const containerRef = useRef(null);
    const badgesRef = useRef([]);
    const [visibleCount, setVisibleCount] = useState(projects.length);

    const calculateVisibleProjects = () => {
        if (containerRef.current && badgesRef.current.length > 0) {
            const containerWidth = containerRef.current.offsetWidth;
            let totalWidth = 0;
            let count = 0;

            for (const badge of badgesRef.current) {
                if (badge) {
                    totalWidth += badge.offsetWidth;
                    if (totalWidth > containerWidth) {
                        break;
                    }
                    count++;
                }
            }
            setVisibleCount(count);
        }
    };

    useLayoutEffect(() => {
        calculateVisibleProjects();
    }, [projects]);

    const visibleProjects = projects.slice(0, visibleCount);
    const hiddenCount = projects.length - visibleCount;

    const generateTooltipContent = () => {
        return `<div class="mt-4">${projects
            .map((project) => {
                return `<div class="flex items-center mb-2">
                            <i class="icon icon-corporate-access mr-2"></i>
                            <div>${project.name}</div>
                        </div>`;
            })
            .join("")}</div>`;
    };

    return (
        <div ref={containerRef} style={{ maxWidth: "90%" }} className="w-full flex items-center gap-2 overflow-visible">
            {visibleProjects.map((project, index) => (
                <ProjectBadge
                    key={project.id}
                    ref={(el) => (badgesRef.current[index] = el)}
                    project={project}
                    className="bg-teal-500 text-white inline-flex items-center space-x-2 px-4 py-1 rounded"
                />
            ))}
            {hiddenCount > 0 && (
                <span
                    data-for="dangerous-html-tooltip"
                    data-tip={generateTooltipContent()}
                    className="text-zafiro-600 flex-shrink-0 whitespace-nowrap"
                >
                    +{hiddenCount}
                </span>
            )}
        </div>
    );
};

const PropertyGroups = () => {
    const { t } = useTranslation();
    const { brands, properties, refetch } = useListPropertyGroups();

    const { open: openModalAddEditGroup } = useModalAddEditGroup();
    const { open: openModalDeleteGroup } = useModalDeletePropertyGroups();
    const { open: openModalDuplicateGroup } = useModalDuplicatePropertyGroup();

    const tableRef = useRef(null);

    const columns = ["group-name", "properties"];
    const header = {
        "group-name": { title: t("group-name"), sortable: true, width: "10%" },
        properties: { title: t("properties"), sortable: true, width: "90%" },
    };

    const handleResetSelectedRows = () => {
        if (tableRef.current) {
            tableRef.current.resetSelectedRows();
        }
    };

    const propertyOptions = properties.map((property) => ({
        id: `property-filter-option-${property.id}`,
        value: property.id,
        label: property.name,
    }));

    const filters = [
        {
            id: "properties-filter",
            title: t("properties"),
            multiple: true,
            options: propertyOptions,
            onFilter: (values, row) => {
                return row.optionsProperties.some((id) => values.includes(id)) ? row : null;
            },
        },
    ];

    const rows = brands.map((brand) => ({
        id: brand.id,
        "group-name": brand.name,
        properties: <PropertiesRow alt={brand?.projects?.length || 0} projects={brand.projects} key={brand.projects} />,
        optionsProperties: brand.projects.map((project) => project.id),
        idGroup: brand.id,
        ref: brand.ref,
        rowConfig: {
            actions: [
                {
                    id: "edit-group",
                    label: t("edit-group"),
                    onClick: () => {
                        openModalAddEditGroup({
                            name: brand.name,
                            properties,
                            refetch,
                            isEdit: true,
                            id: brand.id,
                            idsPropertiesSelected: brand.projects.map((project) => project.id),
                            refs: [brand.ref],
                        });
                    },
                },
                {
                    id: "duplicate-group",
                    label: t("duplicate-group"),
                    onClick: () => {
                        openModalDuplicateGroup({
                            name: brand.name,
                            refetch,
                            id: brand.id,
                        });
                    },
                },
                {
                    id: "delete-group",
                    label: t("delete-group"),
                    onClick: () => {
                        openModalDeleteGroup({
                            names: [brand.name],
                            refetch,
                            ids: [brand.id],
                            refs: [brand.ref],
                            handleResetSelectedRows,
                        });
                    },
                },
            ],
        },
    }));

    return (
        <>
            <UseSectionHeader title={t("manager-propertyGroups")} />
            <main className="main-container">
                <Table
                    ref={tableRef}
                    id="properties-groups-table"
                    header={header}
                    search
                    cols={columns}
                    showCount
                    rows={rows}
                    filters={filters}
                    topRightCorner={
                        <Button
                            id="add-new-group"
                            className="btn btn-blue"
                            onClick={() => {
                                openModalAddEditGroup({ properties, refetch });
                            }}
                        >
                            {t("add-group")}
                        </Button>
                    }
                    paginate
                    batchActions={{
                        options: [
                            {
                                id: "delete",
                                value: "delete",
                                label: t("delete"),
                            },
                        ],
                        onChange: (action, selectedRows) => {
                            if (action === "delete") {
                                openModalDeleteGroup({
                                    names: selectedRows.map((row) => row["group-name"]),
                                    refetch,
                                    isBatch: true,
                                    ids: selectedRows.map((row) => row.idGroup),
                                    refs: selectedRows.map((row) => row.ref),
                                    handleResetSelectedRows,
                                });
                            }
                        },
                    }}
                    onChangeSelectedRows={(selectedRows) => {
                        console.log(selectedRows);
                    }}
                />
            </main>
        </>
    );
};

export default PropertyGroups;
