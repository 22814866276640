import React from "react";

import { useTranslation } from "react-i18next";
import { Session } from "../../../../../hooks/Utils/Session";
import {
    LOGOS_TYPES,
    PROPERTY_LOGOS,
    CORPORATE_APPEARANCE_TYPE,
    PROPERTY_APPEARANCE_TYPE,
    modalsOff,
    THEME_TYPE_MOBILE,
    LOGOS_TYPES_MOBILE,
    CORPORATE_LOGOS,
} from "../../../../../hooks/Utils/AppearancesUtils";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../actions/sectionActions";
import LogoInfo from "./LogoInfo";
import { setActionName, setActionItemAffected, changeActionValues } from "../../../../../actions/globalActions";

import { useAuth } from "hooks/Session/auth";

export default function Logo({ logo, groupType, index, themeType }) {
    console.log("TEST render <Logo>");
    const { t } = useTranslation();
    const { isCorporate } = useAuth();
    const { id, imageRef, themeLogoCustoms, version } = logo;

    const { appearanceSelected } = useSelector((state) => state.sectionContent);

    const logoAsset = themeLogoCustoms && themeLogoCustoms.length > 0 ? themeLogoCustoms[0].imageRef : imageRef;

    const dispatch = useDispatch();

    const isGlobalTheme = appearanceSelected?.type === CORPORATE_LOGOS;
    const isPropertyTheme = appearanceSelected?.type === PROPERTY_LOGOS;
    const isPropertyLogo = groupType === PROPERTY_LOGOS;

    const editAction = (isCorporate && isGlobalTheme) || (!isCorporate && isPropertyTheme && isPropertyLogo);
    const deleteAction = editAction;
    const replaceAction = !deleteAction && !isCorporate && isGlobalTheme && isPropertyLogo;

    const selectLogo = () => {
        dispatch(
            setSectionContent({
                appearanceSelected: { ...appearanceSelected, logoSelected: logoAsset, logoSelectedId: id },
            })
        );
    };

    const openEditModal = () => {
        dispatch(
            setSectionContent({
                addLogo: {
                    id: id,
                    type: groupType,
                    version: version,
                    asset: logoAsset,
                },
                modals: { ...modalsOff, edit: true },
            })
        );
    };

    const openDeleteModal = () => {
        dispatch(setActionName("appearance-delete-logo"));
        dispatch(setActionItemAffected([id]));
        dispatch(changeActionValues({ version: version }));
        dispatch(setSectionContent({ modals: { ...modalsOff, delete: true } }));
    };

    const openReplaceModal = (upload = false) => {
        dispatch(
            setSectionContent({
                addLogo: {
                    id: id,
                    type: groupType,
                    version: version,
                    asset: logoAsset,
                    themeLogoCustoms: themeLogoCustoms,
                },
                modals: { ...modalsOff, replace: upload ? false : true, upload: upload ? true : false },
            })
        );
    };

    return (
        <div id="logo" className="">
            <div
                className={`relative rounded-lg  w-48 h-28 mx-auto items-center align-middle ${
                    appearanceSelected?.logoSelectedId === id ? "border-2 border-blue-100" : ""
                }`}
            >
                <div
                    className={`flex h-full w-full text-gray-600  mx-auto items-center text-center align-bottom justify-center rounded-lg  ${
                        logoAsset ? "cursor-pointer bg-gray-200" : "border border-dashed"
                    }`}
                    onClick={selectLogo}
                >
                    {logoAsset ? (
                        <img
                            alt={t("logo")}
                            src={logoAsset.includes("http") ? logoAsset : Session.getDasUrl(logoAsset + "?w=124&h=76")}
                            className="flex"
                            style={{ maxWidth: "124px", maxHeight: "76px" }}
                        />
                    ) : (
                        t("{{capitalize, capitalize}}", { capitalize: t("unspecified") })
                    )}
                </div>
                <div
                    className="absolute rounded-b-md  bottom-0 h-10 w-full"
                    style={{ backgroundColor: "rgba(46,56,67,0.5)" }}
                >
                    <div
                        className="grid grid-flow-col grid-cols-3  text-1xl font-black text-white  h-full items-center"
                        data=""
                    >
                        <div id={`logo-${groupType}-${index}-button-edit`} className="ml-2">
                            {editAction ? (
                                <span className="icon-edit z-100 cursor-pointer" onClick={() => openEditModal()}></span>
                            ) : null}
                        </div>
                        <div
                            id={`logo-${groupType}-${index}-button-delete`}
                            className="text-center items-center align-middle"
                        >
                            {deleteAction ? (
                                <span
                                    className="icon-delete-editor block z-100 cursor-pointer"
                                    style={{ fontSize: "2rem", paddingTop: "0.1rem" }}
                                    onClick={() => {
                                        openDeleteModal();
                                    }}
                                ></span>
                            ) : null}

                            {replaceAction ? (
                                <>
                                    {logoAsset || (themeLogoCustoms && themeLogoCustoms.length > 0) ? (
                                        <span
                                            className="icon-replace text-center z-100 cursor-pointer"
                                            onClick={() => {
                                                openReplaceModal(false);
                                            }}
                                        ></span>
                                    ) : (
                                        <span
                                            className="icon-upload
                     text-center z-100 cursor-pointer"
                                            onClick={() => {
                                                openReplaceModal(true);
                                            }}
                                        ></span>
                                    )}
                                </>
                            ) : null}
                        </div>
                        <div id={`logo-${groupType}-${index}-button-info`} className="mr-2 text-right items-end">
                            {logoAsset ? <LogoInfo imageRef={logoAsset} /> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mb-2">
                {t(themeType === THEME_TYPE_MOBILE ? LOGOS_TYPES_MOBILE[version] : LOGOS_TYPES[version])}
            </div>
        </div>
    );
}
