import React from "react";
import classNames from "classnames";
import { useNavigate as useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Icon from "components/Icon";
import { Session } from "hooks/Utils/Session";

const MapItem = ({ data, active }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { name, number, isRoom, status, warnings, isCommonZone } = data || {};

    const redirectToRoomDetail = () => {
        if (!Object.keys(data).includes("floors") && !Object.keys(data).includes("rooms")) {
            if (isRoom) {
                history(`/hotel/rooms/room/detail/${data.id}`);
            } else {
                history(`/hotel/zones/zone/detail/${data.id}`);
            }
        }
    };

    const hasNumber = isRoom || isCommonZone;

    const hasWarnings = warnings === true || warnings?.length;
    const warningTooltip = warnings?.length
        ? warnings
              .map((w) => {
                  if (w?.level >= 2) {
                      return null;
                  }
                  if (w?.info === "low-signal-level") {
                      return t("low-signal-level_");
                  }
                  return w?.info;
              })
              .filter((warning) => warning)
              .join("\n")
        : "";

    return (
        <Button
            id={`map-item-${number}`}
            onClick={redirectToRoomDetail}
            className={classNames({
                "flex items-center justify-between": true,
                "w-44 rounded text-left px-4 mr-4 my-2": true,
                "bg-gray-200 text-gray-900": !active,
                "bg-blue-200 text-white": active,
                "hover:text-blue-200": !active,
                "leading-4 py-2": hasNumber,
                "py-3": !hasNumber,
            })}
        >
            <div className="truncate" data-tip={name} data-for="default-tooltip">
                <div id={`name-map-item-${name.replace(/\s/g, "")}`} className="truncate">
                    {name}
                </div>
                {hasNumber ? (
                    <div
                        id={`name-map-item-${name.replace(/\s/g, "")}`}
                        className="text-sm inline-block w-full truncate"
                    >
                        {number}
                    </div>
                ) : null}
            </div>
            <div className="flex items-center space-x-1 justify-self-end">
                {status ? (
                    <Icon
                        type="guest"
                        size={1.4}
                        className={classNames({
                            "text-blue-200": !active,
                            " text-white": active,
                        })}
                    />
                ) : null}
                {hasWarnings ? (
                    <Icon
                        type="warning"
                        size="2xl"
                        className={classNames({
                            "text-orange-100": !active,
                            " text-white": active,
                        })}
                        tooltip={warningTooltip}
                    />
                ) : null}
            </div>
        </Button>
    );
};

export default MapItem;
