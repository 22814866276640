import React from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import NewGuestData from "./NewGuest/NewGuestData";
import NewStayData from "./NewGuest/NewStayData";
import NewDigitalKey from "./NewGuest/NewDigitalKey";
import UseButton from "../../../useButton";
//Actions
import AddNewGuest from "../../../../hooks/GraphqlCalls/Customers/AddNewGuest";
import { changeActionValues, cleanActionValues } from "../../../../actions/globalActions";

const AddGuest = ({ backToRoomDetail, breadcrumbsLimit, roomId }) => {
    const history = useHistory();
    const { permissions } = useSelector((state) => state.ui);

    //Constantes
    const hasDigitalKeys = permissions?.guests?.digitalKey;

    //state
    const dispatch = useDispatch();
    return (
        <>
            <AddNewGuest backToRoomDetail={backToRoomDetail} />
            <UseSectionHeader
                title={"check-in"}
                navToPreviousPage={roomId ? true : false}
                actionOnBack={
                    roomId
                        ? function () {
                              backToRoomDetail();
                          }
                        : null
                }
                breadcrumbsLimit={breadcrumbsLimit || 0}
            />
            <div className="grid grid-cols-3 gap-8 overflow-y-scroll">
                <div className="bg-white row-span-2 rounded pb-8">
                    <NewGuestData />
                </div>
                <div className={`bg-white col-span-2 rounded ${!hasDigitalKeys ? "row-span-2" : ""}`}>
                    <NewStayData roomId={roomId} />
                </div>
                {hasDigitalKeys ? (
                    <div className="bg-white col-span-2 rounded min-h-20">
                        <NewDigitalKey />
                    </div>
                ) : null}
            </div>
            <div className="flex items-center justify-end py-5 px-1 space-x-2">
                <UseButton
                    id={"new-guest-form-cancel-button"}
                    buttonName="cancel"
                    buttonColor="btn-white"
                    action={() => {
                        dispatch(cleanActionValues());
                        if (roomId) {
                            backToRoomDetail();
                        } else {
                            history(-1);
                        }
                    }}
                />
                <UseButton
                    buttonName="check-in"
                    buttonColor="btn-blue"
                    id={"new-guest-form-check-in-button"}
                    action={() => {
                        dispatch(changeActionValues({ save: true }));
                    }}
                />
            </div>
        </>
    );
};

export default AddGuest;
