import { gql } from "apollo-boost";
import Icon from "components/Icon";
import React, { useEffect, useState } from "react";
import { useMSQuery } from "../useMSQuery";
import { useTranslation } from "react-i18next";
import { EditGroup } from "components/Section/Hotel/Rooms/EditGroup";
import { DeleteGroup } from "components/Section/Hotel/Rooms/DeleteRoom";

export const useListGroups = () => {
    const [groupData, setGroupData] = useState([]);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const { t } = useTranslation();

    const GET_GUESTGROUPS_INFO = gql`
        query GuestGroups($search: String) {
            guestGroups: guestGroups(
                size: 1000
                orderBy: { field: "ID", criteria: "desc" }
                filter: { search: $search, type: GUESTROOM }
            ) {
                info {
                    count
                }
                results {
                    id
                    guestNumber
                    name
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, called, refetch, networkStatus }] = useMSQuery(GET_GUESTGROUPS_INFO, {
        pollInterval: 300000,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!loading && !error && data?.guestGroups && networkStatus === 7) {
            const groupItems = arrangeData(data, t);
            setGroupData(groupItems);
        }
    }, [data]);

    const arrangeData = (data, t) => {
        const groupsData = [];
        // eslint-disable-next-line
        data.guestGroups.results.map((user, index) => {
            groupsData.push(GuestGroup(user, t, refetch, setLoadingRefresh, index));
        });
        return groupsData;
    };

    return {
        groupData,
        ready: !loading && called,
        refetch,
        loadingRefresh,
        setLoadingRefresh,
        getGuestGroup: executeQuery,
    };
};

const GuestGroup = (user, t, refetch, setLoadingRefresh, index) => {
    const guestGroup = {
        "group-name": user?.name,
        "number-of-guests":
            user?.guestNumber === 1 ? (
                <span alt={user?.guestNumber}>
                    {user?.guestNumber} {t("guest")}
                </span>
            ) : (
                <span alt={user?.guestNumber}>
                    {user?.guestNumber} {t("guests")}
                </span>
            ),
        edit: (
            <EditGroup
                group={user}
                refetch={refetch}
                setLoadingRefresh={setLoadingRefresh}
                position={index}
                type="guest"
            />
        ),
        delete: (
            <DeleteGroup
                group={user}
                refetch={refetch}
                setLoadingRefresh={setLoadingRefresh}
                type="guest"
                position={index}
            />
        ),
    };
    return guestGroup;
};
