import { gql } from "apollo-boost";

export const GET_ROOMS_INFO = gql`
    {
        rooms: rooms(size: 999, orderBy: { field: "number", criteria: "asc" }) {
            info {
                count
            }
            results {
                floorID
                floorName
                id
                name
                number
                pmsNumber
                roomTVs {
                    id
                    name
                    cec
                }
                wingID
                wingName
            }
        }
        locations: locations {
            results
        }
        floors: installationMap {
            results {
                id
                name
                floors {
                    id
                    name
                    rooms {
                        id
                        name
                        recentGuests
                    }
                }
            }
        }
    }
`;

export const DELETE_ROOM = gql`
    mutation DeleteRoom($id: Int64!) {
        deleteRoom(id: $id) {
            error
            ok
        }
    }
`;

export const CREATE_ROOM = gql`
    mutation CreateRoom(
        $type: RoomType!
        $floorID: Int64!
        $name: String!
        $number: Int!
        $pmsNumber: String
        $locations: [Location]!
        $tvsPerRoom: Int
    ) {
        createRoom(
            floorID: $floorID
            name: $name
            number: $number
            pmsNumber: $pmsNumber
            Locations: $locations
            TVsPerRoom: $tvsPerRoom
            type: $type
        ) {
            error
            ok
        }
    }
`;

export const GET_HOTEL_MAP = gql`
    {
        installationMap {
            info {
                count
            }
            results {
                id
                name
                pos
                defaultPmsFormat
                floors {
                    id
                    name
                    order
                    rooms {
                        id
                        name
                        numRoomTV
                        number
                        residents
                        status
                        recentGuests
                        type
                        warnings {
                            roomID
                            date
                            info
                            level
                            model
                            function
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_FLOOR = gql`
    mutation floorSetup($wingID: Int64!, $floors: [NewFloor]) {
        floorSetup(wingID: $wingID, floors: $floors) {
            error
            ok
        }
    }
`;

export const UPDATE_FLOOR = gql`
    mutation updateFloor($id: Int64!, $name: String) {
        updateFloor(id: $id, name: $name) {
            error
            ok
        }
    }
`;
export const DELETE_FLOOR = gql`
    mutation deleteFloor($id: Int64!) {
        deleteFloor(id: $id) {
            error
            ok
        }
    }
`;

export const UPDATE_WING = gql`
    mutation updateWing($id: Int64!, $name: String, $pmsNumber: String) {
        updateWing(id: $id, name: $name, pmsNumber: $pmsNumber) {
            error
            ok
        }
    }
`;
export const DELETE_WING = gql`
    mutation deleteWing($id: Int64!) {
        deleteWing(id: $id) {
            error
            ok
        }
    }
`;

export const MOVE_FLOOR = gql`
    mutation moveFloor($id: Int64!, $pos: Int!) {
        moveFloor(id: $id, pos: $pos) {
            error
            ok
        }
    }
`;

export const MOVE_CARD = gql`
    mutation moveWing($id: Int64!, $pos: Int!) {
        moveWing(id: $id, pos: $pos) {
            error
            ok
        }
    }
`;

export const CREATE_WING = gql`
    mutation createWing($projectRef: String!, $order: Int!, $name: String!, $pmsNumber: String, $floors: [NewFloor]) {
        wingSetup(ref: $projectRef, order: $order, name: $name, pmsNumber: $pmsNumber, floors: $floors) {
            error
            ok
        }
    }
`;
export const CREATE_ROOM_MAP = gql`
    mutation roomSetup(
        $floorID: Int64!
        $numberRooms: Int!
        $starterRoom: Int!
        $locations: [Location]!
        $name: String
        $type: RoomType!
        $tvsPerRoom: Int!
    ) {
        roomSetup(
            Locations: $locations
            floorID: $floorID
            numberRooms: $numberRooms
            starterRoom: $starterRoom
            name: $name
            TVsPerRoom: $tvsPerRoom
            type: $type
        ) {
            error
            ok
        }
    }
`;
