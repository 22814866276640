import { gql } from "apollo-boost";

export const GET_ACCESS_PROFILES = gql`
    query getAccessProfiles($search: String) {
        allWifiAccessProfiles: wifiAccessProfiles {
            results {
                id
                name
                accesstypes {
                    id
                    accesstypes_name
                }
            }
        }
        wifiAccessProfiles: wifiAccessProfiles(
            size: 999
            orderBy: { field: "name", criteria: "ASC" }
            filter: { search: $search }
        ) {
            info {
                count
                page
                size
            }
            results {
                id
                name
                downloadbwmax
                downloadbwmin
                uploadbwmax
                uploadbwmin
                accesstypes {
                    id
                    accesstypes_name
                }
            }
        }
        wifiAccessTypes: wifiAccessTypes {
            results {
                id
                accesstypes_name
            }
        }
        wifiAccessCodes(page: 1, size: 999, filter: { active: true }) {
            results {
                ID
                accessprofileID
                code
                creationDate
                duration
                expirationDate
                init
                renovation
                usersnumber
                maxusers
            }
        }
    }
`;

export const UPDATE_ACCESS_PROFILE = gql`
    mutation updateWifiAccessProfile($id: Int!, $oldIDs: [Int64], $newIDs: [Int64]) {
        updateWifiAccessProfile(id: $id, oldIDs: $oldIDs, newIDs: $newIDs) {
            ok
            error
            id
        }
    }
`;

export const CREATE_ACCESS_TYPE = gql`
    mutation createWifiAccessProfile(
        $name: String!
        $downloadBWMin: Int64
        $uploadBWMax: Int64
        $uploadBWMin: Int64
        $downloadBWMax: Int64
    ) {
        createWifiAccessProfile(
            name: $name
            downloadBWMin: $downloadBWMin
            uploadBWMax: $uploadBWMax
            uploadBWMin: $uploadBWMin
            downloadBWMax: $downloadBWMax
        ) {
            error
            ok
        }
    }
`;

export const EDIT_ACCESS_TYPE = gql`
    mutation updateWifiAccessProfile(
        $id: Int64!
        $name: String!
        $downloadBWMin: Int64
        $uploadBWMax: Int64
        $uploadBWMin: Int64
        $downloadBWMax: Int64
    ) {
        updateWifiAccessProfile(
            id: $id
            name: $name
            downloadBWMin: $downloadBWMin
            uploadBWMax: $uploadBWMax
            uploadBWMin: $uploadBWMin
            downloadBWMax: $downloadBWMax
        ) {
            error
            ok
        }
    }
`;

export const UPDATE_ACCESS_PROFILES = gql`
    mutation updateWifiAccessProfile($id: Int64!, $oldIDs: [Int64], $newIDs: [Int64]) {
        updateWifiAccessProfile(id: $id, oldIDs: $oldIDs, newIDs: $newIDs) {
            ok
            error
            id
        }
    }
`;

export const DELETE_ACCESS_PROFILE = gql`
    mutation deleteWifiAccessProfile($id: Int64!) {
        deleteWifiAccessProfile(id: $id) {
            error
            ok
        }
    }
`;
