import { useEffect, useContext } from "react";
import { usePermissions } from "hooks/map";
import { UseSectionMap, getSidebarIDs } from "../../components/Sidebar/utils";
import Sitemap from "../../sitemap";
import { Session } from "./Session";
import { GlobalContext } from "contexts/Global";
import { Observable } from "apollo-boost";

const Userguiding = () => {
    const { permissions, paths, isProjectLogged } = useContext(GlobalContext);
    const { checkPath } = usePermissions();
    const { getItems } = UseSectionMap(checkPath);

    useEffect(() => {
        if (!isProjectLogged) {
            // Before login
            Session.userGuiding(null, null);
        } else if (paths !== undefined && permissions?.product) {
            // After login
            const products = Object.keys(permissions.product).filter((v) => permissions.product[v] === true);
            const sections = [];
            Object.keys(Sitemap).forEach((sectionID) => {
                const items = getItems(sectionID, Sitemap[sectionID], paths);
                const ids = getSidebarIDs(items);
                if (ids && ids.length > 0) {
                    sections.push(sectionID);
                    ids.forEach((item) => {
                        sections.push(item);
                    });
                }
            });
            Session.userGuiding(products, sections);
        }
    }, [permissions, isProjectLogged, paths]);

    const checkUGButton = () => {
        // When userguidingButton found on page, set outline to 1px solid red and make it draggable
        const userguidingButton = document.querySelector("#userguiding>.userguiding-assistant-container>div");
        if (userguidingButton) {
            userguidingButton.setAttribute("draggable", "true");
            userguidingButton.style.cursor = "move";
            userguidingButton.addEventListener("dragstart", (event) => {
                if (event?.dataTransfer) {
                    event.dataTransfer.effectAllowed = "move";
                }
            });
            userguidingButton.addEventListener("drag", (event) => {
                if (event?.target === userguidingButton) {
                    const rect = event.target.getBoundingClientRect();
                    if (rect) {
                        event.target.style.position = "absolute";
                        event.target.style.left = `${event.clientX - rect.width / 2}px`;
                        event.target.style.top = `${event.clientY - rect.height / 2}px`;
                        event.target.style.right = "auto";
                        event.target.style.bottom = "auto";
                    }
                }
            });
            userguidingButton.addEventListener("dragover", (event) => {
                event.preventDefault();
            });
        }
    };

    useEffect(() => {
        checkUGButton();
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                    checkUGButton();
                }
            }
        });
        observer.observe(document.body, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, []);

    return null;
};

export default Userguiding;
