import React from "react";
import { capitalizeFirst } from "hooks/Utils/Utils";
import i18n from "i18n";

const NewTaxZone = (taxZoneData, taxRates, readOnly) => {
    const taxZone = taxZoneData ? taxZoneData : {};
    const isNew = !taxZone.id;
    const title = isNew ? "add-zone" : `${taxZone.name ? taxZone.name : ""} ${"-"} ${capitalizeFirst(i18n.t("edit"))}`;
    return {
        title: readOnly ? taxZone?.name : title,
        text: readOnly ? null : "add-tax-zone-description",
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        id: taxZone.id,
        noTranslateTitle: true,
        inputs: [
            ...(readOnly
                ? []
                : [
                      {
                          text: ["zone-name"],
                          name: "name",
                          value: taxZone?.name,
                          type: "text",
                          focus: true,
                          style: "mb-10 w-8/12 pr-5",
                      },
                  ]),
            {
                name: "taxes",
                type: "tax-zone-associated-vat",
                readOnly,
                style: "",
                data: { taxRates: taxRates },
            },
        ],
        buttons: [
            { name: readOnly ? "close" : "cancel", style: "white", action: "close" },
            ...(readOnly
                ? []
                : [
                      {
                          name: "save",
                          style: "blue",
                          action: isNew ? "add-tax-zone" : "edit-tax-zone",
                      },
                  ]),
        ],
    };
};

export default NewTaxZone;
