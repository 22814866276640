export const ChargeType = {
    SHOP: "SHOP",
    MOVIE: "MOVIE",
};

export const ClientType = {
    GUEST: "GUEST",
    OTHER: "OTHER",
};

export const PMSStatus = {
    PMS_PENDING: "PMS_PENDING",
    PMS_CHARGED: "PMS_CHARGED",
    PMS_REJECTED: "PMS_REJECTED",
    ERROR: "ERROR",
    NO_PMS: "NO_PMS",
};

// Custom status
export const PMSUnknown = "UNKNOWN";
export const PMSCancelled = "CANCELLED";

/**
 * Get PMS status display text
 * @param {string} status - PMS status
 * @returns {string} - Translation key
 */
export const getStatusText = (status) => {
    if (!status || status === PMSUnknown) {
        return "-";
    }
    switch (status) {
        case PMSStatus.PMS_CHARGED:
            return "pms-charged"; // t("pms-charged")
        case PMSStatus.PMS_PENDING:
            return "pms-pending"; // t("pms-pending")
        case PMSStatus.PMS_REJECTED:
            return "pms-denied"; // t("pms-denied")
        case PMSCancelled:
            return "cancelled"; // t("cancelled")
        case PMSStatus.NO_PMS:
            return "pms-disabled"; // t("pms-disabled")
        case PMSStatus.ERROR:
            return "error"; // t("error")
        default:
            return `${status}??`;
    }
};

/**
 * Get PMS status information for tooltip
 * @param {string} status - PMS status
 * @returns {string} - Translation key
 */
export const getStatusInfo = (status) => {
    if (!status || status === PMSUnknown) {
        return null;
    }
    switch (status) {
        case PMSStatus.PMS_CHARGED:
            return "pms-charged-info";
        case PMSStatus.PMS_PENDING:
            return "pms-pending-info";
        case PMSStatus.PMS_REJECTED:
            return "pms-denied-info";
        case PMSStatus.NO_PMS:
            return "pms-disabled-info";
        case PMSCancelled:
            return null;
        case PMSStatus.ERROR:
            return null;
        default:
            return `${status}??`;
    }
};
