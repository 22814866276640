export const DeviceFunction = {
    CAST: "CAST",
    TV: "TV",
    TVCAST: "TVCAST",
    UNSPECIFIED: "UNSPECIFIED",
};

export const DeviceKind = {
    CHROMECAST: "CHROMECAST",
    TV: "TV",
    STB: "STB",
};

export const TvActionsEnum = {
    POWEROFF: "POWEROFF",
    RELOAD: "RELOAD",
    POWERON: "POWERON",
};

export const TVInputDeviceType = {
    STB: "STB",
    CHROMECAST: "CHROMECAST",
};

export const TVInputType = {
    USB: "USB",
    BLUETOOTH: "BLUETOOTH",
    HDMI1: "HDMI1",
    HDMI2: "HDMI2",
    HDMI3: "HDMI3",
    HDMI4: "HDMI4",
    isHDMI: (input) => (input || "").includes("HDMI"),
    isUSB: (input) => (input || "").includes("USB"),
};
