import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";

import {
    cleanActiveFilters,
    setCount,
    setCountPosition,
    setCustomConfig,
    setError,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setTableType,
    setRefreshData,
} from "actions/tableActions";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import GetTableHeaders from "hooks/Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "hooks/Utils/Table/GetTableHeaderButton";
import TaxZone from "hooks/GraphqlCalls/Settings/models/TaxZone";
import { capitalizeFirst } from "hooks/Utils/Utils";

import { useAuth } from "hooks/Session/auth";

const ListTaxZones = () => {
    const { t } = useTranslation();

    const { isCorporate, isMonoProject } = useAuth();

    const readOnly = !isCorporate && !isMonoProject;

    //Store data
    const { currentPage, perPage, refreshData, activeFilters } = useSelector((state) => state.table);

    const { adminApi } = useVendureAPI();

    //Data
    const sectionName = `list-tax-zones`;

    //States
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [projectNamesMovies, setProjectNamesMovies] = useState([]);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TAX_ZONES = queryTax;

    const [executeQuery, { refetch: refetchZafiroData }] = useLazyQuery(GET_ZAFIRO_SHOPS, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: "true",
        onCompleted: (data) => {
            const shopsData = data?.shops?.results ? data.shops.results : [];
            const projectNamesMovies = data?.movieTaxZonesInUse?.results ? data?.movieTaxZonesInUse?.results : [];
            console.log("Data", data);
            refetch(shopsData, projectNamesMovies);
        },
        onError(err) {
            console.error(err);
            toast.error(capitalizeFirst("mutation-error"));
        },
    });

    const [{ data: moviesData }] = useLazyQuery(GET_MOVIES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: "true",
        onError(err) {
            console.error(err);
            toast.error(capitalizeFirst("mutation-error"));
        },
    });

    useEffect(() => {
        if (moviesData) {
            const projectNamesList = moviesData.movieTaxZonesInUse.results.map((result) => result.project.name);
            setProjectNamesMovies(projectNamesList);
            executeQuery();
        }
        console.log("Movies data", moviesData);
    }, [moviesData]);

    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetchZafiroData();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (items && items.length > 0) {
            let itemsClone = [...items];
            if (activeFilters && activeFilters.search && activeFilters.search !== "") {
                let searchValue = decodeURIComponent(activeFilters.search.toUpperCase());
                itemsClone = itemsClone.filter((itemClone) => {
                    let add = itemClone && itemClone.name && itemClone.name.toUpperCase().includes(searchValue);
                    if (!add) {
                        if (itemClone && itemClone.taxRates && itemClone.taxRates.length > 0) {
                            add =
                                itemClone.taxRates.filter((taxRate) => {
                                    return taxRate.name && taxRate.name.toUpperCase().includes(searchValue);
                                }).length > 0;
                        }
                    }
                    return add;
                });
            }
            setItemsFiltered(itemsClone);
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    useEffect(() => {
        let results = null;
        if (itemsFiltered) {
            const pageStart = Math.min((currentPage - 1) * perPage, itemsFiltered.length);
            const pageEnd = Math.min(pageStart + perPage, itemsFiltered.length);
            results = itemsFiltered.slice(pageStart, pageEnd);
        }
        dispatch(setTableResults(results));
        dispatch(setLoading(false));
        // eslint-disable-next-line
    }, [itemsFiltered, perPage, currentPage]);

    const refetch = async (shopsData, moviesData) => {
        dispatch(setLoading(true));
        dispatch(setError(null));

        const response = await executeVendureQuery(adminApi, { queryBody: GET_TAX_ZONES });

        let dataItems = [];

        if (response?.data) {
            const taxZonesFromMovies = moviesData
                .map((movie) => {
                    const configItem = movie.config.find((c) => c.value);
                    if (configItem) {
                        try {
                            const configValue = JSON.parse(configItem.value);
                            return configValue.taxZone || null;
                        } catch (e) {
                            console.error("Error parsing config value:", e);
                            return null;
                        }
                    }
                    return null;
                })
                .filter((zone) => zone !== null);

            dataItems = arrangeData(response.data.zones, response.data.taxRates, shopsData, moviesData);
            dispatch(setSearchable(true));
            dispatch(setHeaders(GetTableHeaders(sectionName, isCorporate)));
            dispatch(setTableType(sectionName));
            dispatch(setHeaderButtons(readOnly ? null : GetTableHeaderButton(sectionName)));
            dispatch(setCount(response.data.zones ? response.data.zones.length : 0));
            dispatch(setCountPosition("table-header"));
            dispatch(setCustomConfig({ pagination: true }));
            dispatch(cleanActiveFilters());
        } else {
            dispatch(setError(response?.error ? response.error : "error"));
        }

        dispatch(setLoading(false));
        setItems(dataItems);
        setItemsFiltered(dataItems);
    };

    //Arrange&Functions
    const arrangeData = (zonesList, taxRatesList, shopsData, moviesData) => {
        const response = [];
        const taxRates = taxRatesList ? taxRatesList.items : [];

        if (zonesList && zonesList.length > 0) {
            zonesList.forEach((zone) => {
                // Filtrar tiendas por la zona de impuestos y obtener la referencia de los proyectos
                const projectShops = shopsData
                    .filter((shop) => shop.defaultTaxZone.id === zone.id)
                    .map((shop) => shop.projectRef);

                const projectMovies = moviesData
                    .filter((movie) => {
                        const configItem = movie.config.find((c) => c.value);
                        if (configItem) {
                            try {
                                const configValue = JSON.parse(configItem.value);
                                return configValue.taxZone === zone.id;
                            } catch (e) {
                                console.error("Error parsing config value:", e);
                                return false;
                            }
                        }
                        return false;
                    })
                    .map((movie) => movie.project.ref);

                const uniqueProjects = new Set([...projectShops, ...projectMovies]);
                const projectCount = uniqueProjects.size;

                response.push(
                    TaxZone(
                        zone,
                        taxRates.filter((taxRate) => taxRate.zone.id === zone.id && taxRate.enabled),
                        t,
                        shopsData,
                        moviesData,
                        projectCount,
                        readOnly,
                        isCorporate
                    )
                );
            });
        }

        return response;
    };
};

export default ListTaxZones;

const queryTax = `{
   zones: zones{
       id
       name
   }
   taxRates: taxRates{
       items{
           id
           name
           value
           enabled
           category{
               id
           }
           zone{
               id
           }
       }
   }
}`;

const GET_ZAFIRO_SHOPS = gql`
    query {
        shops {
            results {
                id
                name: nameTranslations {
                    text
                    lang
                }
                projectRef
                defaultTaxZone {
                    id
                }
            }
        }
        movieTaxZonesInUse {
            info {
                count
                page
                size
            }
            results {
                config {
                    concept
                    value
                }
                project {
                    name
                    ref
                }
            }
        }
    }
`;

const GET_MOVIES = gql`
    {
        movieTaxZonesInUse {
            info {
                count
                page
                size
            }
            results {
                config {
                    concept
                    value
                }
                project {
                    name
                }
            }
        }
    }
`;
