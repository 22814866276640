import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Inputs/Checkbox";
import Icon from "components/Icon";

const DeviceContentSelector = ({ roomTVs, availableContents, selectedOptions, setSelectedOptions }) => {
    const { t } = useTranslation();

    const handleCheckboxChange = (roomtv, option) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [roomtv]: {
                ...prev[roomtv],
                [option]: !prev[roomtv]?.[option],
            },
        }));
    };

    const hasUnselectedRoomTV = () => {
        return roomTVs.some((roomTV) => {
            const options = selectedOptions[roomTV.id] || { signage: false, tvChannels: false, castScreen: false };
            return !options.signage && !options.tvChannels && !options.castScreen;
        });
    };

    return (
        <div>
            {roomTVs.length > 0 ? (
                roomTVs.map((roomTV) => (
                    <div key={roomTV.id} className="mt-4">
                        <div className="text-lg bg-gray-200 mb-4 pr-4">
                            <div className="ml-2 mb">{roomTV.name || ""}</div>
                        </div>
                        <div className="flex flex-col mt-2">
                            {availableContents.includes("SIGNAGESEQUENCES") && (
                                <label className="mb-2">
                                    <Checkbox
                                        id={`${roomTV.id}-checkbox-signage`}
                                        checked={selectedOptions[roomTV.id]?.signage || false}
                                        label={t("Signage")}
                                        onChange={() => handleCheckboxChange(roomTV.id, "signage")}
                                    />
                                </label>
                            )}
                            {availableContents.includes("TVCHANNELS") && (
                                <label className="mb-2">
                                    <Checkbox
                                        id={`${roomTV.id}-checkbox-tvChannels`}
                                        checked={selectedOptions[roomTV.id]?.tvChannels || false}
                                        label={t("tv-channels")}
                                        onChange={() => handleCheckboxChange(roomTV.id, "tvChannels")}
                                    />
                                </label>
                            )}
                            {availableContents.includes("CASTINSTRUCTIONSCREEN") && (
                                <label className="mb-2">
                                    <Checkbox
                                        id={`${roomTV.id}-checkbox-castScreen`}
                                        checked={selectedOptions[roomTV.id]?.castScreen || false}
                                        label={t("ccinstruction-screen")}
                                        onChange={() => handleCheckboxChange(roomTV.id, "castScreen")}
                                    />
                                </label>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="mt-6 text-center">{t("No room tv are available in this common zone.")}</div>
            )}

            {hasUnselectedRoomTV() && (
                <div className="mt-6 flex items-center">
                    <Icon type="warning" size="2xl" className="mr-4" />
                    <span>{t("its-necessary-select-one-option-display-content")}</span>
                </div>
            )}
        </div>
    );
};

export default DeviceContentSelector;
