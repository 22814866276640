import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Modal from "components/Modal";
import Button from "components/Button";

import { useAuth } from "hooks/Session/auth";
import useThemes from "hooks/Data/useThemes";
import { setExecuteQuery } from "actions/sectionActions";

import { THEME_TYPE } from "constants/design";
import { PROPERTY_APPEARANCE_TYPE, CORPORATE_APPEARANCE_TYPE } from "hooks/Utils/AppearancesUtils";

import Select from "components/Select";

const ModalChooseAppearance = ({ id, name, themeID, close }) => {
    const selectRef = useRef(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isCorporate, hasChainModule } = useAuth();
    const themes = useThemes();

    const showGlobalAppearances = isCorporate || hasChainModule;
    const groupByType = showGlobalAppearances;
    const themesOptions = themes.data || [];
    const groupsNames = {
        [PROPERTY_APPEARANCE_TYPE]: t("property-appearances"),
        [CORPORATE_APPEARANCE_TYPE]: t("general-appearances"),
    };

    const themeOption = (theme) => ({ value: theme.id, label: theme.name });
    const themesGroups = (groups, theme) => {
        const ensureGroup = (id, groups) => {
            const matchGroup = (id) => (g) => g.value === id;
            if (!groups.some(matchGroup(id))) {
                groups.push({ value: id, label: groupsNames[id], options: [] });
            }
            return groups.find(matchGroup(id));
        };
        const group = ensureGroup(theme.type, groups);
        if (group) {
            group.options.push(themeOption(theme));
        }
        return groups;
    };

    useEffect(() => {
        themes.load({
            filter: {
                themeType: THEME_TYPE.MOBILE,
                type: showGlobalAppearances ? undefined : PROPERTY_APPEARANCE_TYPE,
            },
        });
    }, []);

    return (
        <Modal
            id="choose-appearance"
            title={`${name} - ${t("choose-appearance")}`}
            loading={!themes?.ready && !themes?.error}
            error={themes?.error}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-save"
                        onClick={() => {
                            if (selectRef.current && selectRef.current.isValid()) {
                                //TODO refactor this to avoid using redux dispatch
                                dispatch(
                                    setExecuteQuery({
                                        action: "update-mobile-appearance",
                                        params: { themeID: selectRef.current.getValue(), mobileAppID: id },
                                    })
                                );
                                close();
                            }
                        }}
                    >
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-4/12 p-10"}
        >
            <div className="flex flex-col gap-2">
                <p className="font-bold">{t("appearance")}*</p>
                <div>{t("select-appearance-description")}</div>
                <Select
                    ref={selectRef}
                    id="select-appearance"
                    options={groupByType ? themesOptions.reduce(themesGroups, []) : themesOptions.map(themeOption)}
                    required={true}
                    value={themeID}
                />
            </div>
        </Modal>
    );
};

export default ModalChooseAppearance;
