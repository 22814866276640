import DateAgo from "../../../../Utils/DataCalc/DateAgo";
import { DEVICE_FUNCTIONS, DEVICE_STATUS, DEVICE_TYPES } from "../../../../Utils/Hotel/Monitor/ListDevicesUtils";
import InputGroupDetail from "../../modals/InputGroupDetail";
import { TRANSLATE_SCREEN_TYPE_TITLE } from "hooks/Utils/Design/EditorUtils";
import { DeviceFunction } from "constants/devices";

const Device = (props, t, permissions) => {
    const { device, inputGroupData, tvInputs, categoryConfig, sectionName, guest, castEnabled } = props;
    const {
        name,
        ref,
        type,
        function: deviceFunction,
        roomTVName,
        roomNumber,
        warning,
        lastActivity,
        lastActivityDate,
        model,
        commercialName,
    } = device;

    const dataType = "device";
    let deviceFunctionData = null;
    let deviceFunctionLabels = [];
    let deviceStatusData = null;
    let deviceTypeData = null;
    let lastActivityText = "";
    let status = "";
    let lastActivityDateDisplayText = t("never");
    let typeText = "";
    let categoryData = {};

    Object.keys(DEVICE_TYPES).map((actionKey) => {
        if (type === DEVICE_TYPES[actionKey].value) {
            deviceTypeData = DEVICE_TYPES[actionKey];
            typeText = DEVICE_TYPES[actionKey].label;
        }
    });
    if (lastActivity) {
        if (lastActivity.includes("sending-content")) {
            lastActivityText = t(lastActivityText);
        } else if (lastActivity.includes("instructions page")) {
            lastActivityText = t(TRANSLATE_SCREEN_TYPE_TITLE["CCINSTRUCTION"]);
        } else if (lastActivity.includes("unauthorized")) {
            lastActivityText = t("unauthorized");
        } else if (lastActivity.includes("Zafiro: unassigned")) {
            lastActivityText = t("unassigned");
        } else if (lastActivity !== undefined && lastActivity !== "undefined") {
            lastActivityText = lastActivity;
        }
    }
    if (lastActivityDate && lastActivityDate !== "" && !Number.isNaN(new Date(lastActivityDate).getTime())) {
        const _lastDisplay = DateAgo(new window.ZDate(lastActivityDate));
        lastActivityDateDisplayText =
            _lastDisplay[Object.keys(_lastDisplay)[0]] === "NaN"
                ? t("never")
                : t("last-activity-date", {
                      val: _lastDisplay[Object.keys(_lastDisplay)[0]],
                      measure: t(Object.keys(_lastDisplay)[0]),
                  });
    }
    lastActivityText += ` (${lastActivityDateDisplayText})`;
    Object.keys(DEVICE_FUNCTIONS).forEach((actionKey) => {
        if (DEVICE_FUNCTIONS[actionKey].value === deviceFunction) {
            deviceFunctionData = DEVICE_FUNCTIONS[actionKey];
        }
    });
    if (warning && warning.info) {
        status = warning.info;
        Object.keys(DEVICE_STATUS).forEach((actionKey) => {
            if (DEVICE_STATUS[actionKey].value === warning.info) {
                deviceStatusData = DEVICE_STATUS[actionKey];
            }
        });
    }
    if (deviceFunctionData) {
        deviceFunctionData.icons.forEach((icon, index) => {
            deviceFunctionLabels.push([
                index,
                deviceFunctionData.value,
                `${icon.color.class} pl-2 pr-2 pb-1`,
                null,
                null,
                `icon-${icon.name} text-2xl font-bold`,
            ]);
        });
    }
    if (deviceStatusData) {
        deviceStatusData.icon.color.class = getIconColorClassByLevel({ level: device?.warning?.level });
        if (
            deviceStatusData.value === DEVICE_STATUS.offline.value &&
            device.warning &&
            device.warning.level &&
            device.warning.level >= 2
        ) {
            deviceStatusData.icon.color.class = "warning";
        }
    }

    if (categoryConfig && categoryConfig.useCategoryGroup) {
        let categoryPath = categoryConfig ? categoryConfig.categoryName : "roomTVName";
        let categoryValue = device[categoryPath];
        if (categoryValue) {
            let existInPrevCategories = categoryConfig.categoriesPrevAdded
                ? categoryConfig.categoriesPrevAdded.includes(categoryValue)
                : false;
            categoryData = {
                category: !existInPrevCategories,
                categoryName: categoryValue,
            };
        }
    }

    return {
        ...device,
        id: ref,
        idValue: device.id,
        dataType: dataType,
        status: status,
        castEnabled: castEnabled,
        name: name,
        roomNumber,
        roomTVName,
        commercialName,
        model,
        params: { ...device, deviceFunctionData: deviceFunctionData },
        executeSectionQuery: true,
        actionComponentStyle: { left: "-160px", width: "13.5rem" },
        deviceFunctionData,
        deviceTypeData,
        tvInputs: tvInputs,
        ...categoryData,
        uniqueRowValue: `${JSON.stringify(device)}`,
        actions: [
            {
                type: dataType,
                value: ref,
            },
        ],
        info: info(
            {
                device: device,
                sectionName: sectionName,
                deviceFunctionData: deviceFunctionData,
                deviceStatusData: deviceStatusData,
                deviceFunctionLabels: deviceFunctionLabels,
                lastActivityText: lastActivityText,
                guest: guest,
            },
            t,
            permissions
        ),
        extendedInfo: extendedInfo(
            {
                ...device,
                typeText,
            },
            t,
            inputGroupData
        ),
    };
};

const info = (props, t, permissions) => {
    const { device, sectionName, deviceStatusData, deviceFunctionData, deviceFunctionLabels, lastActivityText, guest } =
        props;

    const { name, mac, roomID, roomTVName, roomNumber, roomTVID, instructions, kind, ref, commercialName } = device;
    const response = [];

    let deviceFunctionCell = {
        name: "function",
        specialWidth: "w-2/12",
    };
    let lastDisplayCell = {
        name: "last-display",
        value: lastActivityText,
        cellContent: "text",
        specialWidth: "w-2/12 truncate",
    };
    let deviceStatusCell = {
        name: "status",
        cellContent: "text",
        specialWidth: "w-2/12",
    };
    let previewCell = {
        name: "preview",
        value: t("{{value, capitalize}}", { value: t("preview") }),
        translate: false,
        link: `/hotel/rooms/room/preview/${roomTVID}/${guest ? guest.guestLang : ""}`,
        target: "_blank",
        external: true,
        cellContent: "link",
        specialWidth: "w-2/12",
    };
    let tvPreviewCell = {
        name: "preview",
        value: t("{{value, capitalize}}", { value: t("preview") }),
        translate: false,
        link: `/hotel/rooms/room/preview/tv/${roomTVID}/${ref}`,
        target: "_blank",
        external: true,
        noWidthFullCell: true,
        cellContent: "link",
        specialWidth: "w-2/12",
    };
    let blankCell = {
        value: "void",
        specialWidth: "w-2/12",
    };
    //x
    let tvLocationCell = {
        name: "tvLocation",
        cellContent: "list",
        specialWidth: "w-2/12",
        listValues: [
            {
                name: "roomNumber",
                value: `${roomNumber && roomNumber > 0 ? roomNumber : ""}`,
                noShowName: true,
                valueAdjust: permissions?.hotel?.roomList && "t-link cursor-pointer",
                link: permissions?.hotel?.roomList && `/hotel/rooms/room/detail/${roomID}`,
            },
            {
                name: "roomTVName",
                value: roomTVName,
                noShowName: true,
                valueAdjust: "text-gray-600",
            },
        ],
    };
    let nameAndmacCell = {
        name: "nameAndmac",
        cellContent: "list",
        specialWidth: "w-3/12",
        listValues: [
            {
                name: "name",
                value: name,
                noShowName: true,
            },
            {
                name: "mac",
                value: mac ? mac.toUpperCase() : "",
                valueAdjust: "text-gray-600",
                nameAdjust: "text-gray-600",
            },
        ],
    };

    if (deviceFunctionLabels.length > 0) {
        deviceFunctionCell = {
            ...deviceFunctionCell,
            cellContent: "labelsTruncate",
            value: deviceFunctionLabels,
            cellAdjust: "truncate labels",
        };
    } else {
        deviceFunctionCell = {
            ...deviceFunctionCell,
            value: deviceFunctionData ? deviceFunctionData.label : "",
            cellContent: "text",
            translate: true,
        };
    }

    if (deviceStatusData) {
        if (deviceStatusData.spinner) {
            deviceStatusCell = {
                ...deviceStatusCell,
                spinner: deviceStatusData.spinner ? deviceStatusData.spinner : false,
                spinnerAdjust: deviceStatusData.spinnerAdjust ? deviceStatusData.spinnerAdjust : "",
                spinnerStyle: deviceStatusData.spinnerStyle ? deviceStatusData.spinnerStyle : "",
            };
        } else {
            deviceStatusCell = {
                ...deviceStatusCell,
                icon: deviceStatusData.icon ? deviceStatusData.icon.name : "",
                iconAdjust:
                    deviceStatusData.icon && deviceStatusData.icon.color ? deviceStatusData.icon.color.class : "",
            };
        }
        deviceStatusCell = {
            ...deviceStatusCell,
            value: deviceStatusData.compressedLabel ? deviceStatusData.compressedLabel : deviceStatusData.label,
            translate: true,
            subvalue: deviceStatusData.subLabel ? t(deviceStatusData.subLabel) : "",
            adjust: deviceStatusData.labelAdjust,
        };
    }

    if (sectionName === "list-room-devices") {
        nameAndmacCell.specialWidth = "w-3/12";
        deviceFunctionCell.specialWidth = "w-2/12";
        deviceStatusCell.specialWidth = "w-2/12";
        lastDisplayCell.specialWidth = "w-2/12 truncate";
        response.push(
            nameAndmacCell,
            deviceFunctionCell,
            deviceStatusCell,
            lastDisplayCell,
            instructions || (commercialName === "4K" && deviceFunctionData?.enumValue === DeviceFunction.CAST)
                ? previewCell
                : deviceFunctionData?.enumValue === DeviceFunction.TV ||
                  deviceFunctionData?.enumValue === DeviceFunction.TVCAST
                ? tvPreviewCell
                : blankCell
        );
    } else {
        response.push(nameAndmacCell, deviceFunctionCell, tvLocationCell, deviceStatusCell, lastDisplayCell);
    }

    return response;
};

const extendedInfo = (device, t, inputGroupData) => {
    const response = [];
    const {
        function: deviceFunction,
        ref,
        type,
        wifiSignal,
        firmwareVersion,
        model,
        commercialName,
        tvInputID,
        typeText,
        ip,
    } = device;
    let firstColumn = null;
    let inputGroupColumn = getInputGroupColumn(inputGroupData, t, type, tvInputID);
    switch (deviceFunction) {
        case DEVICE_FUNCTIONS.tv.value:
            firstColumn = {
                name: "tv-firmware",
                value: ["tv-firmware", ":", `${firmwareVersion ? firmwareVersion : ""} `],
                cellContent: "list",
                specialWidth: "w-3/12",
                translate: true,
                listValues: [
                    {
                        name: "tv-firmware",
                        value: firmwareVersion,
                        nameAdjust: "text-gray-700",
                        valueAdjust: "text-gray-700",
                    },
                    {
                        name: "ip",
                        value: ip,
                        nameAdjust: "text-gray-700",
                        valueAdjust: "text-gray-700",
                    },
                ],
                adjust: "text-gray-700",
            };
            if (type === DEVICE_TYPES.googleTV.value) {
                firstColumn = {
                    name: "firmwareAndRef",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "ref",
                            value: `${ref} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "tv-firmware",
                            value: `${firmwareVersion ? firmwareVersion : ""} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                            hidden: !firmwareVersion,
                        },
                    ],
                };
            }
            response.push(
                firstColumn,
                {
                    name: "typeAndModel",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "type",
                            value: `${t(typeText)} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "model",
                            value: `${commercialName} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                    ],
                },
                inputGroupColumn
            );
            break;
        case DEVICE_FUNCTIONS.cast.value:
            firstColumn = {
                prefix: "ip",
                translatePrefix: true,
                twoDots: true,
                name: "ip",
                value: ip,
                cellContent: "text",
                specialWidth: "w-3/12",
                adjust: "text-gray-700",
            };
            if (type === DEVICE_TYPES.googleTV.value) {
                firstColumn = {
                    name: "refAndIp",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "ref",
                            value: `${ref} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "ip",
                            value: `${ip ? ip : ""} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                    ],
                };
            }
            response.push(
                firstColumn,
                {
                    name: "typeAndModel",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "type",
                            value: `${t(typeText)} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "model",
                            value: `${commercialName} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                    ],
                },
                wifiSignal !== 0
                    ? {
                          name: "wifi-signal",
                          value: ["wifi-signal", ": ", wifiSignal],
                          translateValue: true,
                          cellContent: "value-extended",
                          specialWidth: "w-3/12 text-gray-700",
                          icon: {
                              name: "wifi-signal",
                              values: wifiSignal,
                              dataSet: type,
                          },
                          sufix: "dBm",
                      }
                    : {
                          value: "void",
                          specialWidth: "w-3/12",
                      }
            );
            break;
        case DEVICE_FUNCTIONS.tvAndcast.value:
        case DEVICE_FUNCTIONS.unspecified.value:
        case "":
            response.push(
                {
                    name: "refAndIp",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "ref",
                            value: `${ref} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "ip",
                            value: `${ip ? ip : ""} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                    ],
                },
                {
                    name: "typeAndModel",
                    cellContent: "list",
                    specialWidth: "w-3/12",
                    listValues: [
                        {
                            name: "type",
                            value: `${t(typeText)} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                        {
                            name: "model",
                            value: `${commercialName} `,
                            nameAdjust: "text-gray-700",
                            valueAdjust: "text-gray-700",
                        },
                    ],
                },
                inputGroupColumn,
                wifiSignal !== 0
                    ? {
                          name: "wifi-signal",
                          value: ["wifi-signal", ": ", wifiSignal],
                          translateValue: true,
                          cellContent: "value-extended",
                          specialWidth: "w-3/12 text-gray-700",
                          icon: {
                              name: "wifi-signal",
                              values: wifiSignal,
                              dataSet: type,
                          },
                          sufix: "dBm",
                      }
                    : {
                          value: "void",
                          specialWidth: "w-3/12",
                      }
            );
            break;
    }
    return response;
};

const getIconColorClassByLevel = (props) => {
    const { level } = props;
    let response = "";
    if (level && level > 0) {
        switch (level) {
            case 1:
                response = "fail";
                break;
            case 2:
                response = "warning";
                break;
            case 3:
                response = "disabled";
                break;
            case 4:
                response = "success";
                break;
        }
    }
    return response;
};

const getInputGroupColumn = (inputGroupData, t, type, tvInputID) => {
    let inputGroupColumn = null;
    if (type !== DEVICE_TYPES.googleTV.value && type !== DEVICE_TYPES.chromecast.value && inputGroupData?.name) {
        inputGroupColumn = {
            name: "input-group",
            value: [
                "input-group",
                ": ",
                `<span class="t-link cursor-pointer font-bold">${inputGroupData?.name || ``} </span>`,
            ],
            translate: true,
            cellContent: inputGroupData?.name ? "edit-modal" : "text",
            specialWidth: "w-3/12",
            isAction: true,
            contentAdjust: "text-gray-700",
            modal: InputGroupDetail(t, tvInputID, inputGroupData),
        };
    }
    return inputGroupColumn;
};

export default Device;
