export const AlarmType = {
    BEEP: "BEEP",
    TVCHANNEL: "TVCHANNEL",
};

export const AlarmStateType = {
    PENDING: "PENDING",
    ATTENDED: "ATTENDED",
    IGNORED: "IGNORED",
    EXPIRED: "EXPIRED",
};
