import { Session } from "../../../Utils/Session";
import DeletePredefinedChannel from "../modals/DeletePredefinedChannel";
import channelUsedInProperties from "../modals/ChannelUsedInProperties";

const PredefinedChannel = (data, t) => {
    const { name, id, countryRef, isRadio, languageRef, logoRef, usedInProjectsNum, usedInProjects } = data;
    const dataType = "predefinedChannel";

    return {
        dataType: dataType,
        id: id,
        executeSectionQuery: true,
        adjust: "h-auto",
        actions: [
            {
                type: dataType,
                value: id,
            },
        ],
        name: name,
        countryRef: countryRef,
        languageRef: languageRef,
        logoRef: logoRef,
        isRadio: isRadio,
        usedInProjectsNum: usedInProjectsNum,
        t: t,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                cellContent: "image",
                isFromUrl: true,
                urlPath: logoRef ? Session.getDasUrl(logoRef + "?lang=" + "es" + "&w=70&h=70") : null,
                path: "icons/",
                type: "svg",
                name: "image",
                // linkText: name,
                noImgText: true,
                specialWidth: "w-1/12 pl-1 ",
                adjust: "w-18 h-12 object-contain bg-gray-100 rounded mr-4",
                notImgFontSize: "0.65rem",
                adjustNotImageIcon: "icon icon-channel text-gray-400 text-xl",
                iconError: "icon-channel text-gray-400 text-xl",
                adjustNotImage:
                    "border border-transparent flex items-center justify-center bg-gray-100 w-18 h-12 rounded mr-4",
                adjustText: " text-zafiro-600 cursor-pointer",
                value: name,
                redirectTo: `/services/tv-channels/corporate-channels/${id}`,
            },
            {
                cellContent: "text",
                value: `${name}`,
                specialWidth: "w-3/12 pl-2",
                wrap: "truncate",
                adjust: "font-bold",
                id: `predefined-channel-name-${id}`,
            },
            {
                cellContent: "text",
                value: `${isRadio ? t("radio") : t("tv")}`,
                specialWidth: "w-1/12 pl-2",
                wrap: "truncate",
                adjust: " bg-zafiro-600 text-white rounded px-1 w-fit-content ",
                id: `predefined-channel-type-${id}`,
            },
            {
                cellContent: "text",
                value: `${countryRef ? t("country:" + countryRef.toLowerCase()) : ""}`,
                specialWidth: "w-2/12 pl-2",
                wrap: "truncate",
                adjust: " text-gray-900 ",
                id: `predefined-channel-country-${id}`,
            },
            {
                cellContent: "text",
                value: `${languageRef ? t("language:" + languageRef.toLowerCase()) : ""}`,
                specialWidth: "w-2/12 p-2",
                wrap: "truncate",
                adjust: " text-gray-900 ",
                id: `predefined-channel-language-${id}`,
            },
            {
                cellContent: "button",
                modal:
                    usedInProjectsNum > 0
                        ? channelUsedInProperties({ name, usedInProjects, predefindeChannel: true })
                        : null,
                adjust: usedInProjectsNum > 0 ? " font-bold text-zafiro-600 px-0 cursor-pointer" : " px-0",
                name: t("used in x projects", { count: usedInProjectsNum }),
                specialWidth: "w-2/12 pl-3",
                wrap: "truncate",
            },
            {
                cellContent: "icon",
                value: id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                icon: {
                    name: "edit",
                    id: id,
                    values: id,
                    itemType: dataType,
                    action: "preset-channel-edit",
                },
            },
            {
                name: "predefined-channel",
                cellContent: "icon",
                specialWidth: "w-0.5/12",
                isAction: true,
                disabledIcon: usedInProjectsNum > 0,
                modal: [DeletePredefinedChannel(name, id)],
                tooltip: usedInProjectsNum > 0 ? t("the-channel-is-in-use") : null,
                icon: {
                    name: "delete",
                    id: id,
                    values: id,
                },
            },
        ],
    };
};

export default PredefinedChannel;
