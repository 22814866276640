import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Table from "components/ZafiroTable";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { satisfactionLevelValues } from "../utils";
import RoomChangeInfo from "components/RoomChangeInfo";
import Loading from "components/Loading";
import { parseISO, format } from "date-fns";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

const GET_FEEDBACKS = gql`
    query GetFeedbacksAndSettings(
        $page: Int
        $size: Int
        $orderBy: OrderBy
        $filter: StayFeedbacksFilter
        $projectID: Int64!
        $lang: LanguageCode!
    ) {
        stayFeedbacks(page: $page, size: $size, orderBy: $orderBy, filter: $filter) {
            results {
                id
                originRoom {
                    id
                    name
                }
                stayGuestRoom {
                    id
                    checkedout
                    room {
                        id
                        name
                    }
                    stayGuest {
                        id
                        name
                        surame
                    }
                }
                satisfactionLevel
                createdAt
            }
        }
        getFeedbackSetting(projectID: $projectID, lang: $lang) {
            satisfactionQuestion
        }
    }
`;

const useGetInfoFeedbackHistory = ({ guestID, roomID }) => {
    const [fetchFeedbacks, { data, loading, error }] = useLazyQuery(GET_FEEDBACKS, { fetchPolicy: "network-only" });

    const { languages: projectLangs } = useAuth();

    const projectID = Session.getProject()?.id || null;
    const defaultLang = projectLangs ? projectLangs.find((lang) => lang.isDefault === true)?.languageRef : "en";

    useEffect(() => {
        if (guestID || roomID) {
            fetchFeedbacks({
                variables: {
                    page: 1,
                    size: 9999,
                    orderBy: null,
                    filter: { guestID, roomID },
                    projectID,
                    lang: defaultLang,
                },
            });
        }
    }, [guestID, roomID, fetchFeedbacks]);

    return { data, loading, error };
};

const FeedbackRow = ({ feedback, roomID }) => {
    const formattedDate = format(parseISO(feedback.createdAt), "dd/MM/yyyy HH:mm");

    const isCheckedOut = feedback?.stayGuestRoom?.checkedout;
    const roomChanged = feedback?.originRoom?.id !== feedback?.stayGuestRoom?.room?.id;

    return {
        feedback: (
            <div className="flex item justify-center w-full">
                <Icon
                    type={satisfactionLevelValues[feedback.satisfactionLevel + 1]?.icon}
                    style={satisfactionLevelValues[feedback.satisfactionLevel + 1]?.style}
                />
            </div>
        ),
        date: <p>{formattedDate}</p>,
        room: !roomID ? (
            <div className=" flex space-x-2">
                <p>{feedback.stayGuestRoom?.room?.name || ""}</p>
                {roomChanged ? (
                    <RoomChangeInfo
                        current={feedback.stayGuestRoom?.room?.name}
                        previous={feedback?.originRoom?.name}
                    />
                ) : null}
                {isCheckedOut ? <p className="text-gray-600">{"Check-out"}</p> : null}
            </div>
        ) : null,
    };
};

const ModalContentFeedbackHistory = ({ guestID, roomID }) => {
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const { data, loading } = useGetInfoFeedbackHistory({ guestID, roomID });

    console.log(data);

    useEffect(() => {
        if (data?.stayFeedbacks?.results) {
            setItems(
                data.stayFeedbacks.results
                    .map((feedback) => {
                        const feedbackRow = FeedbackRow({ feedback, roomID });
                        return {
                            feedback: feedbackRow.feedback,
                            date: feedbackRow.date,
                            room: feedbackRow.room,
                        };
                    })
                    .reverse()
            );
        }
        if (data?.getFeedbackSetting?.satisfactionQuestion) {
            setCurrentQuestion(data?.getFeedbackSetting?.satisfactionQuestion?.replace(/"/g, ""));
        }
    }, [data]);

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="w-full bg-gray-200 mb-4 text-gray-800 px-2 py-3 font-bold">{currentQuestion}</div>
                    <Table
                        id="table-emails"
                        cols={["feedback", "date", "room"]}
                        key={items}
                        header={{
                            feedback: { title: capitalizeFirst(t("feedback")), width: "25%" },
                            date: { title: capitalizeFirst(t("date")), width: "37.5%" },
                            room: { title: roomID ? "" : capitalizeFirst(t("room")), width: "37.5%" },
                        }}
                        customTexts={{
                            noTableData: t("no-feedback-yet"),
                        }}
                        rows={items}
                        batchActions={false}
                        maxHeight="19rem"
                    />
                </>
            )}
        </div>
    );
};

export const useModalFeedbackHistory = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const openModal = (props) => {
        open(
            <Modal
                title={t("feedback-history")}
                footer={
                    <Button design="blue" id="modal-button-close" onClick={close}>
                        {t("close")}
                    </Button>
                }
                minWidth="30vw"
            >
                <ModalContentFeedbackHistory {...props} />
            </Modal>
        );
    };

    return {
        open: openModal,
        close,
    };
};

export default useModalFeedbackHistory;
