import React, { useContext, useEffect, useState } from "react";
import { POLLINTERVAL_15S, POLLINTERVAL_30S } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { GET_CARDS_INFO, GET_DATA, GET_FILTERS_DATA } from "../utils/devicesQueries";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { arrangeData, arrangeFilterData } from "../utils/monitorUtils";
import { useModal } from "components/Modal";
import { toast } from "react-toastify";
import InfoToast from "components/Section/Hotel/Monitor/InfoToast";
import { DevicesCards } from "./models/DevicesCards";
import { addToastInfoItem, removeToastInfoItem } from "actions/tableActions";
import FilterCCsContext from "components/Section/Hotel/context/FilterCCsContext";

export const useListDevices = (serverError) => {
    const { open, close } = useModal();
    const [devices, setDevices] = useState(null);
    const [filters, setFilters] = useState(null);
    const [dashboardCards, setDashboardCards] = useState(null);
    const { permissions } = useSelector((state) => state.ui);
    const { toastInfoItems } = useSelector((state) => state.table);
    const wifiEnabled = permissions?.product?.wifi ? permissions?.product?.wifi : null;
    const castEnabled = permissions?.product?.cast ? true : false;

    const { page, search, sort, deviceModel, deviceFunction, deviceStatus, deviceLocation, size, roomType } =
        useContext(FilterCCsContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const orderBy = sort ? `orderBy: { field: "${sort?.field}", criteria: "${sort?.criteria?.toUpperCase()}" }` : "";

    const [executeDevicesQuery, { data, loading, error, refetch }] = useMSQuery(GET_DATA(wifiEnabled, orderBy), {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    const [getWarnings, { data: warningData, loading: warningLoading }] = useLazyQuery(GET_CARDS_INFO(wifiEnabled), {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_30S,
    });

    const [executeFiltersQuery, filterData] = useLazyQuery(GET_FILTERS_DATA);

    const variables = {
        page,
        search,
        ...(sort ? { field: sort?.field } : {}),
        ...(sort ? { criteria: sort?.criteria.toUpperCase() } : {}),
        model: deviceModel,
        deviceFunction,
        deviceStatus,
        deviceLocation,
        size,
        roomType,
    };

    useEffect(() => {
        getWarnings();
        executeFiltersQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.devices && filterData?.data && !filterData?.loading) {
            if (data?.devices?.results) {
                data.devices.results = updateDeviceFunction(data.devices.results);
            }
            const tableDevices = arrangeData(data, t, castEnabled, permissions, open, close, refetch, serverError);
            const filters = arrangeFilterData(filterData?.data, t);
            setDevices(tableDevices);
            setFilters(filters);
            checkWarnings(data?.devices?.results);
        }
        if (warningData && !warningLoading) {
            const dataWifiLicenses = warningData?.wifiLicenses ? warningData?.wifiLicenses : null;
            const dataCards = DevicesCards(warningData?.warnings?.results, permissions, dataWifiLicenses, open);
            setDashboardCards(dataCards);
        }
    }, [data, filterData, warningData]);

    const checkWarnings = (items) => {
        items.forEach((device) => {
            if (device?.warning?.info === "running-task" && !toastInfoItems.includes(device.mac)) {
                dispatch(addToastInfoItem(device.mac));
                const message = `${device.name} ${t(`task-${device.warning.actionInfo}`)}`;
                toast.info(<InfoToast msg={message} />, {
                    autoClose: 10000,
                });
                setTimeout(() => {
                    dispatch(removeToastInfoItem(device.mac));
                }, 60000);
            }
        });
    };

    return {
        devices,
        filters,
        dashboardCards,
        count: data?.devices?.info?.count,
        getDevices: (deviceNumber) => executeDevicesQuery({ variables: { ...variables, size: deviceNumber || size } }),
        vanillaDevices: data,
    };
};

// Verificar que function de cada device, si es TV+CAST, sea TVCAST.
export const updateDeviceFunction = (devices) => {
    return devices.map((device) => {
        if (device.function === "TV+CAST") {
            return { ...device, function: "TVCAST" };
        }
        return device;
    });
};
