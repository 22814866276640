import React from "react";
import { useTranslation } from "react-i18next";
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListTags from "../../../hooks/GraphqlCalls/Design/ListTags";
import UpdateTag from "../../../hooks/GraphqlCalls/Design/UpdateTag";

const Tags = () => {
    const { t } = useTranslation();

    ListTags();

    return (
        <>
            <UpdateTag />
            <UseSectionHeader title={t("List of tags")} />
            <UseTable />
        </>
    );
};

export default Tags;
