import React from "react";
import Modal, { useModal } from "components/Modal";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Button from "components/Button";
import { DeleteGroupModal } from "./DeleteGroupModal";

export const DeleteGroup = ({ group, refetch, setLoadingRefresh, type, position }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const handleDelete = () => {
        open(
            <Modal
                id="delete-room-group"
                title={
                    <h1 className="text-2xl font-bold">
                        {capitalizeFirst(t("delete"))} {group?.id && group?.name}
                    </h1>
                }
                className="w-120 p-10"
                onKeyPress={{ Enter: close }}
            >
                <DeleteGroupModal group={group} refetch={refetch} setLoadingRefresh={setLoadingRefresh} type={type} />
            </Modal>
        );
    };

    return (
        <Button id={`delete-group-${position}`} onClick={handleDelete}>
            <Icon id={`delete-${group?.id}`} type="delete" size="xl" />
        </Button>
    );
};
