import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setColorPickerReference } from "../../../../../actions/designActions";
//components
import UseInputPercent from "../../../../Inputs/useInputPercent";
import UseInputText from "../../../../Inputs/useInputText";
import CustomColorPicker from "../../Widgets/WidgetToolbars/OptionsDetails/CustomColorPicker";
import { hexToRgbA } from "../../../../../hooks/Utils/DesignUtils";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { toast } from "react-toastify";
import ReactTooltip from "components/ReactTooltip";

// import "rc-color-picker/assets/index.css";
// import ColorPicker from "rc-color-picker";

function ColourSet({ set, colours, avoidForeground = false, hideName, tooltip }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const [focusBorderSize, setFocusBorderSize] = useState(0);
    const [inputsColorsDisabled, setInputsColorsDisabled] = useState(true);

    const isMainColour = set === "mainColour";

    const isANullAppearance = appearanceSelected?.id === null ? true : false;

    useEffect(() => {
        if (colours && document.querySelector(`#${set}-bg`)) {
            document.querySelector(`#${set}-bg`).value = colours ? colours.bg : null;
            if (!isMainColour) {
                document.querySelector(`#${set}-bgAlpha`).value = Math.round(colours ? colours.bgAlpha : 100) + ` %`;
                if (!avoidForeground) {
                    document.querySelector(`#${set}-fg`).value = colours ? colours.fg : null;
                    document.querySelector(`#${set}-fgAlpha`).value =
                        Math.round(colours ? colours.fgAlpha : 100) + ` %`;
                }
            }
        }
        // eslint-disable-next-line
    }, [colours]);

    useEffect(() => {
        setFocusBorderSize(
            appearanceSelected && appearanceSelected.focusBorderSize ? appearanceSelected.focusBorderSize : 0
        );
        setInputsColorsDisabled(
            appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.COLOUR_TOOLBAR
        );
    }, [appearanceSelected]);

    const handleChangeAlpha = (alpha, id) => {
        const _ground = id.split("-")[1];
        let colorData;
        if (_ground.includes("bg")) {
            colorData = hexToRgbA(colours && colours.bg ? colours.bg : "#FFFFFF", alpha);
        } else {
            colorData = hexToRgbA(colours && colours.fg ? colours.fg : "#FFFFFF", alpha);
        }
        dispatch(
            setExecuteQuery({
                action: "update-appearance-colors",
                params: {
                    property: id,
                    value: colorData,
                },
            })
        );
    };
    const handleChangeHex = (hex, id) => {
        const _ground = id.split("-")[1];
        let colorData = null;
        try {
            if (hex && _ground.includes("bg")) {
                colorData = hexToRgbA(hex, colours && colours.bgAlpha ? colours.bgAlpha : 100);
            } else if (hex && _ground.includes("fg")) {
                colorData = hexToRgbA(hex, colours && colours.fgAlpha ? colours.fgAlpha : 100);
            }
        } catch {
            toast.error(`bad HEX`);
        }
        dispatch(
            setExecuteQuery({
                action: "update-appearance-colors",
                params: {
                    property: id,
                    value: colorData,
                },
            })
        );
    };
    const handleChangeSize = (size, id) => {
        dispatch(
            setExecuteQuery({
                action: "update-appearance-colors",
                params: {
                    property: id,
                    value: size,
                },
            })
        );
    };

    return (
        <div>
            <CustomColorPicker />
            {!isMainColour ? (
                <div className="col-span-4 font-bold text-gray-900">
                    {!hideName ? t("{{capitalize, capitalize}}", { capitalize: t(set) }) : null}
                </div>
            ) : null}

            {/* BACKGROUND */}
            <div className=" flex">
                <div className="my-auto flex w-2/6 ">
                    <div className=" whitespace-no-wrap mr-2">
                        {t("{{capitalize, capitalize}}", {
                            capitalize: isMainColour ? t("mainColour") : t("background"),
                        })}
                    </div>
                    {tooltip ? (
                        <i
                            data-tip={tooltip}
                            onMouseOver={ReactTooltip.rebuild}
                            className=" icon icon-info text-gray-700 text-2xl "
                        ></i>
                    ) : null}
                </div>
                <div className=" flex items-center w-4/6 mb-4 mt-4  ">
                    <div className=" mr-2 h-8">
                        <div
                            id={`${set}-background-picker`}
                            className={`border w-8 h-8 rounded-lg ${
                                (!colours || !colours.bg) && "icon-no-color text-35xl p-px text-red-100"
                            }`}
                            style={{ background: colours ? colours.rgbaBG : null }}
                            onClick={
                                appearanceSelected
                                    ? (e) => {
                                          if (
                                              !appearanceSelected?.locks?.COLOUR_TOOLBAR &&
                                              isANullAppearance !== true
                                          ) {
                                              dispatch(
                                                  setColorPickerReference({
                                                      id: `${set}-background-picker`,
                                                      property: `${set}-bgColor`,
                                                      color: colours ? colours.rgbaBG : null,
                                                      disabled: !appearanceSelected,
                                                  })
                                              );
                                              e.stopPropagation();
                                          }
                                      }
                                    : null
                            }
                        ></div>
                    </div>
                    <div className=" mr-2 flex items-center">
                        <UseInputText
                            id={`${set}-bg`}
                            name={"background-code"}
                            ph={"#"}
                            key={colours?.bg}
                            adjust={"w-full h-8"}
                            disabled={!appearanceSelected || inputsColorsDisabled || isANullAppearance}
                            defaultValue={colours ? colours.bg : null}
                            action={handleChangeHex}
                        />
                    </div>

                    <div className={`mr-2 flex items-center ${isMainColour ? "opacity-0" : ""} `}>
                        <UseInputPercent
                            id={`${set}-bgAlpha`}
                            adjust={"w-full h-8 text-center "}
                            value={colours ? colours.bgAlpha : null}
                            disabled={
                                !appearanceSelected ||
                                isMainColour ||
                                inputsColorsDisabled === true ||
                                !(colours && colours.bg) ||
                                isANullAppearance
                            }
                            action={handleChangeAlpha}
                        />
                    </div>
                </div>
            </div>
            {/* FOREGROUND */}
            {!avoidForeground ? (
                <div className=" flex">
                    <div className="my-auto flex w-2/6">
                        {t("{{capitalize, capitalize}}", { capitalize: t("foreground") })}
                    </div>
                    <div className=" flex items-center w-4/6">
                        <div className="m-auto mr-2 h-8">
                            <div
                                id={`${set}-foreground-picker`}
                                className={`border w-8 h-8 rounded-lg ${
                                    (!colours || !colours.fg) && "icon-no-color text-35xl p-1px text-red-100"
                                }`}
                                style={{ background: colours ? colours.rgbaFG : null }}
                                onClick={(e) => {
                                    if (appearanceSelected && !appearanceSelected.locks.COLOUR_TOOLBAR) {
                                        dispatch(
                                            setColorPickerReference({
                                                id: `${set}-foreground-picker`,
                                                property: `${set}-fgColor`,
                                                color: colours ? colours.rgbaFG : null,
                                                disabled: !appearanceSelected,
                                            })
                                        );
                                        e.stopPropagation();
                                    }
                                }}
                            ></div>
                        </div>
                        <div className="mr-2 flex items-center">
                            <UseInputText
                                id={`${set}-fg`}
                                name={"background-code"}
                                ph={"#"}
                                key={colours?.fg}
                                adjust={"w-full h-8"}
                                defaultValue={colours ? colours.fg : null}
                                disabled={!appearanceSelected || inputsColorsDisabled}
                                action={handleChangeHex}
                            />
                        </div>
                        <div className="mr-2 flex items-center">
                            <UseInputPercent
                                id={`${set}-fgAlpha`}
                                adjust={"w-full h-8 text-center"}
                                value={colours ? colours.fgAlpha : 100}
                                disabled={!appearanceSelected || inputsColorsDisabled || !colours?.fg}
                                action={handleChangeAlpha}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
            {set === "focus" && (
                <div className=" flex mt-4">
                    {/* FOCUS BORDER SIZE */}
                    <div className="my-auto text-left">
                        {t("{{capitalize, capitalize}}", { capitalize: t("border-size") })}
                    </div>
                    <div className="col-span-3">
                        <div className="ml-6 w-1/3 pl-1">
                            <UseInputPercent
                                id={`${set}-border-size`}
                                key={focusBorderSize}
                                adjust={" h-8 text-center"}
                                value={focusBorderSize}
                                percentDisplay={"px"}
                                disabled={
                                    !appearanceSelected ||
                                    (appearanceSelected &&
                                        appearanceSelected.locks &&
                                        appearanceSelected.locks.COLOUR_TOOLBAR)
                                        ? true
                                        : false
                                }
                                action={handleChangeSize}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ColourSet;
