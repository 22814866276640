import React from "react";
import { gql } from "apollo-boost";
import { capitalizeFirst, POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { secondsToDate, tooltipInfoAP } from "./utils/passcodesUtils";
import Icon from "components/Icon";
import Button from "components/Button";
import { useDeletePasscodes } from "./useDeletePasscodes";
import Modal, { useModal } from "components/Modal";
import { toast } from "react-toastify";

export const useListPassCodes = (page = 1, search, unlimitedDuration, accessProfilesId, init, active, sort) => {
    const [passCodes, setPassCodes] = useState([]);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const { t } = useTranslation();

    const GET_PASSCODES_AND_ACCESS_PROFILES = gql`
        query wifiAccessCodes(
            $active: Boolean
            $init: Boolean
            $search: String
            $unlimitedDuration: Boolean
            $accessProfilesId: [Int64]
            $page: Int
            $orderBy: OrderBy
        ) {
            wifiAccessCodes(
                page: $page
                size: 6
                orderBy: $orderBy
                filter: {
                    active: $active
                    init: $init
                    search: $search
                    unlimitedDuration: $unlimitedDuration
                    accessProfilesId: $accessProfilesId
                }
            ) {
                info {
                    count
                    size
                    page
                }
                results {
                    ID
                    accessprofileID
                    code
                    creationDate
                    duration
                    expirationDate
                    init
                    renovation
                    usersnumber
                    maxusers
                }
            }
            wifiAccessProfiles {
                results {
                    id
                    name
                    downloadbwmax
                    downloadbwmin
                    uploadbwmin
                    uploadbwmax
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useLazyQuery(
        GET_PASSCODES_AND_ACCESS_PROFILES,
        {
            pollInterval: POLLINTERVAL_15S,
        }
    );

    useEffect(() => {
        executeQuery({
            variables: {
                active,
                search,
                page,
                unlimitedDuration,
                accessProfilesId,
                init,
                orderBy: sort,
            },
        });
    }, [search, page, unlimitedDuration, accessProfilesId, init, active, sort]);

    useEffect(() => {
        if (!loading && !error && data?.wifiAccessCodes && networkStatus === 7) {
            const dataItems = arrangeData(data);
            setPassCodes(dataItems);
        }
    }, [data]);

    useEffect(() => {
        if (loadingRefresh) {
            toast.success(t("operation-successful"));
            setTimeout(() => {
                refetch().then(() => {
                    setLoadingRefresh(false);
                });
            }, 1200);
        }
    }, [loadingRefresh]);

    const arrangeData = (data) => {
        const accessCodes = [];
        const accesProfilesData = {};

        if (data?.wifiAccessProfiles?.results) {
            data.wifiAccessProfiles.results.map((item) => {
                if (item.name && item.id && !accesProfilesData[item.id]) {
                    accesProfilesData[item.id] = {
                        name: item.name,
                        dbwmax: item.downloadbwmax ? Number(item.downloadbwmax) : "0",
                        dbwmin: item.downloadbwmin ? Number(item.downloadbwmin) : "0",
                        ubwmax: item.uploadbwmax ? Number(item.uploadbwmax) : "0",
                        ubwmin: item.uploadbwmin ? Number(item.uploadbwmin) : "0",
                    };
                }
            });
        }

        if (data.wifiAccessCodes && data.wifiAccessCodes.results) {
            data.wifiAccessCodes.results.map((itemData, index) => {
                accessCodes.push(Passcode(itemData, accesProfilesData, t, setLoadingRefresh, index));
            });
        }

        return accessCodes;
    };

    const arrangeIDsAccessProfiles = () => {
        const IDsAccessProfiles = [];
        let noRepValues = {};

        // eslint-disable-next-line
        data?.wifiAccessProfiles?.results?.map((item) => {
            if (item.id && item.name && !noRepValues[item.id]) {
                IDsAccessProfiles.push({ id: item.id, label: item.name, value: item.id });
                noRepValues[item.id] = true;
            }
        });

        return IDsAccessProfiles;
    };

    return {
        passCodes,
        filter: arrangeIDsAccessProfiles(),
        refetch,
        count: data?.wifiAccessCodes?.info?.count,
        loadingRefresh,
        setLoadingRefresh,
    };
};

const Passcode = (passcode, accessProfiles, t, setLoadingRefresh, index) => {
    const { ID: id, code, accessprofileID, maxusers, usersnumber, renovation, duration, init } = passcode;

    const passCodeModel = {
        id,
        code,
        renovation: (
            <div
                className="px-3 py-1 bg-zafiro-500 text-white rounded"
                data-tip={tooltipInfoAP(accessProfiles, accessprofileID, t)}
                data-for="dangerous-html-tooltip"
                alt={accessProfiles[accessprofileID].name}
            >
                {accessProfiles[accessprofileID] ? capitalizeFirst(accessProfiles[accessprofileID].name) : ""}
            </div>
        ),
        max_users: (
            <div alt={maxusers}>
                <p>{maxusers ? maxusers : capitalizeFirst(t("unlimited"))}</p>
                <p className="text-gray-500">
                    {usersnumber
                        ? capitalizeFirst(
                              t("used-n-times", {
                                  usersnumber,
                              })
                          )
                        : null}
                </p>
            </div>
        ),
        duration: (
            <div className="flex gap-2 items-center" alt={duration || "unlimited"}>
                <p>{duration ? secondsToDate(duration, t) : capitalizeFirst(t("unlimited"))}</p>
                {!duration && (
                    <Icon
                        type="info"
                        size="2xl"
                        tooltip={capitalizeFirst(t("connection-time")) + " " + secondsToDate(renovation, t, true)}
                    />
                )}
            </div>
        ),
        init: init ? (
            <p alt={init && "started"}>{capitalizeFirst(t("started"))}</p>
        ) : (
            <p className="text-gray-500" alt={!init && "not-began"}>
                {capitalizeFirst(t("not-started"))}
            </p>
        ),
        preview: (
            <a id={`preview-passcode-${id}`} target="_blank" href={`#/customers/passcode-configuration/preview/${id}`}>
                <Icon id={`icon-preview-cell-${index}`} type="externalink-" size="2xl" className="text-gray-800" />
            </a>
        ),
        delete: <DeletePassCode passcode={passcode} setLoadingRefresh={setLoadingRefresh} pos={index} />,
    };

    return passCodeModel;
};

const DeletePassCode = ({ passcode, setLoadingRefresh, pos }) => {
    const { t } = useTranslation();
    const { code, ID: id } = passcode;

    const { open, close } = useModal();
    const { refetch } = useListPassCodes();
    const { deletePasscodes } = useDeletePasscodes({ id });

    const deletePass = () => {
        deletePasscodes();
        close();
        setLoadingRefresh(true);
    };

    return (
        <Button
            id={`icon-cell-button-${pos}`}
            onClick={() =>
                open(
                    <Modal
                        id="delete-passcode"
                        title={`${capitalizeFirst(t("delete"))} ${code}`}
                        className="w-1/3 p-10"
                        onKeyPress={{ Enter: close }}
                    >
                        <div>
                            <p className="text-md">{t("delete-passcode")}</p>
                            <div className="flex justify-end gap-4 mt-8">
                                <Button id="cancel" onClick={close} className="btn btn-white">
                                    {t("cancel")}
                                </Button>
                                <Button id="delete" onClick={deletePass} className="btn btn-red">
                                    {capitalizeFirst(t("delete"))}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )
            }
            className="cursor-pointer"
        >
            <Icon type="delete" size="xl" />
        </Button>
    );
};
