import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import UseButton from "../../../../useButton";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S, capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import BrowserMediaLibraryModal from "../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import { changeGlobalAction } from "../../../../../actions/globalActions";
import { setModalContent, openModal } from "../../../../../actions/uiActions";
import { Session } from "../../../../../hooks/Utils/Session";
import UseItemActions from "../../../../useItemActions";
import { checkShowTooltip } from "../../../../../hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { setRefreshContentData } from "../../../../../actions/sectionActions";
import PreviewCorporateChannel from "../../../../../hooks/GraphqlCalls/Services/modals/PreviewCorporateChannel";
import UseLoading from "../../../../Table/useLoading";

import { GlobalContext } from "contexts/Global";

const CorporateChannelsVideos = ({ calculateHeight = true, id, internalLoading = false, nameChannel }) => {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const mainContainerRef = useRef(null);
    const dispatch = useDispatch();
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [queryVideosDetails, setQueryVideosDetails] = useState(`query{
        fake{
            error
            ok
        }
    }`);
    const [heightContainerVideos, setHeightContainerVideos] = useState(null);

    const GET_VIDEOS_FROM_CORP_CHANNEL = gql`
        query {
            corporateTvChannelVideos(corpChannelID: ${id}) {
                corporateTvChannelID
                id
                pos
                videoRef
            }
        }
    `;

    const GET_VIDEOS_DETAILS = gql`
        ${queryVideosDetails}
    `;

    const [getVideosFromCorpChannel, { data, refetch }] = useLazyQuery(GET_VIDEOS_FROM_CORP_CHANNEL, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    const [getVideosDetails, { data: videosDetails, loading: loadingVideosDetails, refetch: refetchVideosDetails }] =
        useLazyQuery(GET_VIDEOS_DETAILS, {
            fetchPolicy: "network-only",
        });

    useLayoutEffect(() => {
        if (internalLoading) {
            setLoading(true);
        }
    }, []);

    useEffect(() => {
        getVideosFromCorpChannel();

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        } else if (refreshData && refetch) {
            refetch();
            refetchVideosDetails();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.corporateTvChannelVideos.length > 0) {
            setQueryVideosDetails(buildQueryGetDetailsVideos(data.corporateTvChannelVideos));
            getVideosDetails();
        } else {
            setVideos([]);
        }
        if (data) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (videosDetails && data?.corporateTvChannelVideos.length > 0) {
            const objVideosDetails = transformObject(videosDetails);
            const videos = data.corporateTvChannelVideos.map((v) => {
                return {
                    ...v,
                    name: objVideosDetails[v.videoRef]?.name ? objVideosDetails[v.videoRef].name : "",
                    defLang: objVideosDetails[v.videoRef]?.defaultLanguage
                        ? objVideosDetails[v.videoRef].defaultLanguage
                        : "en",
                };
            });
            setVideos(videos);
        }
        //eslint-disable-next-line
    }, [videosDetails]);

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            title: "select-a-video",
            bodyAdjust: "w-10/12",
            text: "videos-of-media-library",
            textAdjust: "",
            buttonSelectedAssetName: "choose",
            executeSectionQuery: true,
            dataMediaLibrary: {
                select_multiple: false,
                selected_ref: null,
                type: "video",
                lang: lang,
                search_input: true,
            },
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: "add-video-to-corporate-channel",
                })
            );
        }, 1000);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            setVideos((prevVideos) => {
                const movedVideo = prevVideos[oldIndex];
                const updateVideos = arrayMove(prevVideos, oldIndex, newIndex);
                dispatch(
                    setExecuteQuery({
                        action: "move-video-position-corporate-channel",
                        params: { newIndex, tuneGroupID: id, tuneID: movedVideo.id },
                    })
                );
                return updateVideos;
            });
        }
    };

    useEffect(() => {
        const mainContainer = document.getElementById("corporate-channel-videos-container");
        const sectionContent = document.getElementById("sectionContent");

        if (mainContainer && sectionContent && calculateHeight) {
            const mainRect = mainContainer.getBoundingClientRect();
            const sectionRect = sectionContent.getBoundingClientRect();
            const paddingTop = parseFloat(getComputedStyle(sectionContent).paddingTop);
            const paddingBottom = parseFloat(getComputedStyle(sectionContent).paddingBottom);
            const spaceBelow = sectionRect.bottom - mainRect.top - paddingTop - paddingBottom;

            setHeightContainerVideos(spaceBelow - 30);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div ref={mainContainerRef} className=" relative bg-white rounded shadow-sm w-full px-8 py-6">
            <div className=" flex">
                <div className=" flex flex-col w-full">
                    <div className=" flex items-center">
                        <span className=" mr-6 text-lg font-bold">{t("channel-content")}</span>
                        <button
                            onClick={() => {
                                if (videos?.length > 0) {
                                    const content = PreviewCorporateChannel(t, {
                                        videosRef: videos.map((v) => v.videoRef),
                                        dataChannel: { name: nameChannel },
                                    });
                                    dispatch(setModalContent(content));
                                    dispatch(openModal());
                                }
                            }}
                            id="corporate-channel-videos-preview-button"
                            className=" font-bold focus:outline-none text-zafiro-600 text-base "
                        >
                            {capitalizeFirst(t("preview"))}
                        </button>
                    </div>
                    <span className=" mt-2">
                        {t("select-and-sort-the-videos-you-want-to-be-played-on-the-channel")}
                    </span>
                </div>
                <div className=" flex items-end">
                    <UseButton
                        id={"corporate-channel-videos-add-video"}
                        buttonColor={"btn-blue"}
                        buttonName={"add-video"}
                        action={() => {
                            openModalLibrary();
                        }}
                    />
                </div>
            </div>
            <div
                id="corporate-channel-videos-container"
                className=" mt-2 border-t border-gray-200 pt-2"
                style={{ height: heightContainerVideos ? `${heightContainerVideos}px` : "auto", overflowY: "scroll" }}
            >
                {videos.length > 0 ? (
                    <SortableContainer onSortEnd={onSortEnd} distance={30} helperClass="dragging" axis="xy">
                        {videos.map((v, i) => {
                            return <SortableItem key={v.id} index={i} info={{ ...v, lang: lang }} idCorporate={id} />;
                        })}
                    </SortableContainer>
                ) : (
                    <div className=" text-center w-full">{t("no-videos-yet")}</div>
                )}
            </div>
            {loading ? (
                <div className=" flex items-center z-205 bg-white justify-center absolute h-full w-full top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default CorporateChannelsVideos;

const SortableItem = sortableElement(({ info, idCorporate }) => {
    const posterSrc = Session.getDasUrl(`thumb/${info.videoRef}-${info.defLang}?w=500&h=500`);
    const { t } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className=" w-full relative bg-gray-900 rounded "
            id={`corporate-channel-card-video-${info.id}`}
            style={{ height: "10.2rem", userSelect: "none", paddingTop: "1.8rem", paddingBottom: "1.8rem" }}
        >
            <div
                className=" w-full rounded-t absolute pl-1 top-0 right-0 z-10 bg-gray-900 flex justify-between items-center"
                style={{ height: "1.8rem" }}
            >
                <i className=" text-white text-xl icon icon-drag-and-drop"></i>
                <span className=" mt-1 " style={{ maxWidth: "30px" }}>
                    <UseItemActions
                        elementReference={"corporate-channel-videos-container"}
                        id={`corporate-channel-card-video-actions-${info.id}`}
                        dataType="corporateChannel"
                        executeSectionQuery={true}
                        actions={[
                            {
                                name: t("replace"),
                                id: "replace",
                                adjust: "text-gray-900",
                                modal_title: "replace",
                                modal_value: info?.name ? info.name : "",
                                modal_btn_name: "replace",
                                noModal: true,
                                mutation: "replace-corporate-channel-video-select-video",
                                hideGlobalLoading: true,
                                mutationParams: { ...info, idCorporate },
                                // channel_info: { ...info, idGroup },
                                t,
                            },
                            {
                                name: t("video-details"),
                                id: "video-details",
                                adjust: "text-gray-900",
                                action: "corporate-channel-video-details",
                                modal_title: "view-details",
                                modal_btn_name: "close",
                                modal_confirm: true,
                                modal_confirm_name_button: "close",
                                modal_value: info?.name ? info.name : "",
                                channel_info: { ...info, idCorporate },
                                modal_bodyAdjust: "w-full",
                                data: { videoRef: info.videoRef ? info.videoRef : "" },
                                t,
                            },
                            {
                                name: t("delete"),
                                id: "delete",
                                action: "delete-video-from-corporate-channel",
                                adjust: "text-gray-900",
                                modal_title: "delete",
                                modal_btn_name: "delete",
                                modal_value: info?.name ? info.name : "test",
                                channel_info: { ...info, idCorporate },
                                t,
                            },
                        ]}
                        adjustFontSize={"text-white text-lg -mt-1"}
                        activeStyle={"bg-gray-200 rounded text-gray-900 mt-2 py-1"}
                    />
                </span>
            </div>
            <div style={{ height: "100%" }}>
                <img
                    id={`corporate-channel-card-video-image-${info.id}`}
                    className=" pointer-events-none object-cover h-full w-full"
                    src={posterSrc}
                ></img>
            </div>
            <div
                style={{ height: "1.8rem" }}
                onMouseOver={(e) => checkShowTooltip(e.currentTarget, setShowTooltip)}
                data-tip={showTooltip ? info.name : ""}
                id={`corporate-channel-card-video-name-${info.id}`}
                className=" absolute w-full left-0 bottom-0 px-2 truncate text-white "
            >
                {info.name ? info.name : ""}
            </div>

            <ReactTooltip
                type={"light"}
                borderColor={"#D3DAE1"}
                border={true}
                place="bottom"
                multiline={true}
            ></ReactTooltip>
        </div>
    );
});

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <div
            id={`corporate-channel-cards-container`}
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(clamp(12.2rem, 100%, 12.2rem), 1fr))`,
                gridGap: "1.7rem",
                alignItems: "center",
                paddingBottom: "1rem",
                overflow: "hidden",
            }}
        >
            {children}
        </div>
    );
});

const buildQueryGetDetailsVideos = (dataVideos) => {
    let query = `query{`;
    dataVideos.forEach((v, index) => {
        query += `libraryVideo_${index}:
            libraryVideo(
                       ref: "${v.videoRef}"
                    ) {
                      error
                      ok
                      response {
                        defaultLanguage
                        name
                        ref
                       }
                     }
            `;
    });
    query += `}`;
    return query;
};

const transformObject = (inputObj) => {
    const outputObject = {};

    for (const key in inputObj) {
        if (inputObj.hasOwnProperty(key)) {
            if (inputObj[key].ok) {
                const response = inputObj[key].response;
                const videoRef = response.ref;
                outputObject[videoRef] = response;
            }
        }
    }

    return outputObject;
};
