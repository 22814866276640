import React from "react";
import i18next from "i18next";
import ReactTooltip from "components/ReactTooltip";
import { AlarmStateType } from "constants/alarms";

export const QUERY_ALARMS_PART = `alarms {
    groupingID
    startTime
    isFromGuest
    lastUpdate
    roomIds
    rooms
    state
    type
    tvChannelId
    stayguestName
    stayguestSurname
  }`;

export const ACTIVE_ALARM = { name: "active", value: AlarmStateType.PENDING, key: "alarm-state" };
export const EXPIRED_ALARM = {
    name: "expired",
    value: AlarmStateType.EXPIRED,
    key: "alarm-state",
    defaultFilterExpired: `,filter:{state : ${AlarmStateType.EXPIRED}}`,
};

export const ALARM_RECIPIENTS_TYPE = {
    ID: "recipient-type",
    ROOM: "stayGuestRoom",
    GUEST: "guest",
};

export const ALARM_TYPE = {
    ID: "alarm-type",
    BEEP: "BEEP",
    TVCHANNEL: "TVCHANNEL",
};

export const printAlarm = (alarm, roomname, tvChannelName) => {
    const t = i18next.getFixedT();
    const _starTime = new window.ZDate(alarm.startTime);
    let _alarmType =
        alarm.type === ALARM_TYPE.TVCHANNEL ? `${t("tv-channel")} (${tvChannelName || alarm.tvChannelId})` : t("Beep");
    const locationAlarms = JSON.parse(alarm.rooms);

    return locationAlarms[roomname]
        ? locationAlarms[roomname].map((lAlarm) => (
              <div
                  key={`${alarm.id} ${lAlarm.location}`}
                  className="grid grid-cols-6 text-sm mb-4"
                  id="alarm-guest-full"
              >
                  <div className="icon-alarm row-span-3 text-2xl"></div>
                  <div className="col-span-5 font-bold  text-gray-800">{`TV - ${lAlarm.location}`} </div>
                  <div className="col-span-5 font-normal text-gray-700">
                      {`${_starTime.toLocaleTimeString("es-ES", {
                          hour: "2-digit",
                          minute: "2-digit",
                      })} ${_starTime.toLocaleTimeString([], {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                      })}`}
                      {alarm.isFromGuest ? (
                          <>
                              <span
                                  data-tip
                                  data-for={`guestAlarm`}
                                  className="icon icon-user-regular cursor-help ml-4 text-xl text-gray-700"
                              ></span>
                              <ReactTooltip id={`guestAlarm`}>
                                  {t("{{capitalize, capitalize}}", { capitalize: t("edited-by-guest") })}
                              </ReactTooltip>
                          </>
                      ) : null}
                  </div>
                  <div className="col-span-5 font-normal text-gray-700">{_alarmType}</div>
              </div>
          ))
        : null;
};
export const roomHasAlarm = (alarms, roomname) => {
    let hasAlarm = false;
    alarms.forEach((alarm) => {
        const locationAlarms = JSON.parse(alarm.rooms);
        if (locationAlarms[roomname] && locationAlarms[roomname].length > 0) {
            hasAlarm = true;
        }
    });

    return hasAlarm;
};

export const getLastModifiedAlarm = (infoAlarms) => {
    if (!infoAlarms || infoAlarms.length === 0) {
        return null;
    }
    const _alarmsSorted = infoAlarms.sort(function (a, b) {
        if (new Date(a.lastUpdate).getTime() < new Date(b.lastUpdate).getTime()) {
            return 1;
        }
        if (new Date(a.lastUpdate).getTime() > new Date(b.lastUpdate).getTime()) {
            return -1;
        }
        return 0;
    });
    // last alarm modified
    return _alarmsSorted[0];
};

export const getTVChannelName = (channels, channelId) => {
    if (!channelId) {
        return null;
    }
    if (!channels) {
        return channelId;
    }
    const channel = channels.find((ch) => ch.id === channelId);
    return channel ? channel.name : channelId;
};
