import React, { useRef, useEffect, useState, cloneElement, forwardRef, useImperativeHandle, useCallback } from "react";
import ReactDOM from "react-dom";

import Button from "components/Button";
import classNames from "classnames";

/**
 * @param id {string} The id of the dropdown.
 * @param disabled {boolean} Whether the dropdown is disabled.
 * @param disabledArrow {boolean} Whether to disable the arrow only
 * @param open {boolean} Open state of the dropdown.
 * @param setOpen {function} The function to set the open state of the dropdown.
 * @param handler {string | object | JSX.Element} The handler of the dropdown.
 * @param body {JSX.Element} The body of the dropdown.
 * @param children {Button[]} The body of the dropdown if not using body.
 * @param designClass {object} The design classes of the dropdown.
 * @param onClick {function} The function to handle the click event.
 * @param showArrow {boolean} Whether to show the arrow.
 * @param arrowPosition {string} The position of the arrow (default: right).
 * @param autoClose {boolean} Whether to close the dropdown when clicking outside.
 * @param tooltip {string} The tooltip of the dropdown.
 * @param tooltipType {string} The type of the tooltip.
 * @param width {string} The width of the dropdown.
 * @param onOpenChange {function} The function to handle the open state change.
 * @returns {JSX.Element} The rendered button component.
 */
const Dropdown = forwardRef(
    (
        {
            id,
            disabled,
            initialOpen,
            open: customOpen,
            setOpen: customSetOpen,
            handler,
            body,
            children,
            designClass: customDesignClass,
            designStyle: customDesignStyle,
            minWidth,
            maxWidth,
            maxHeight,
            tooltip,
            tooltipType,
            width,
            onOpenChange,
            dropdownDirection, // up, down
            autoClose = true,
            float = false,
            showArrow = true,
            disabledArrow = false,
            arrowPosition = "right",
            overflowX,
        },
        ref
    ) => {
        const containerRef = useRef(document.createElement("div"));
        const dropdownRef = useRef(null);
        const dropdownElementRef = useRef(null);

        const [opened, setOpen] = useState((initialOpen !== undefined ? initialOpen : customOpen) || false);
        const [handlerElement, setHandlerElement] = useState(null);

        const { open: openFloat, close: closeFloat } = useFloatDropdown({
            id,
            dropdown: dropdownRef.current,
            container: containerRef.current,
            enabled: float,
            minWidth,
            maxWidth,
            maxHeight,
            dropdownDirection,
            overflowX,
        });

        const isOpen = customOpen === undefined ? opened : customOpen;

        const open = () => {
            if (customSetOpen) {
                customSetOpen(true);
            }
            setOpen(true);
        };

        const close = () => {
            if (customSetOpen) {
                customSetOpen(false);
            }
            setOpen(false);
        };

        const toggle = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (customSetOpen) {
                customSetOpen(!isOpen);
            }
            setOpen(!isOpen);
        };

        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                close();
            }
        };

        useEffect(() => {
            if (isOpen) {
                openFloat();
            } else {
                closeFloat();
            }
        }, [isOpen]);

        useEffect(() => {
            if (autoClose) {
                document.addEventListener("mousedown", handleClickOutside);
            }
            return () => {
                if (autoClose) {
                    document.removeEventListener("mousedown", handleClickOutside);
                }
            };
        }, [autoClose]);

        useEffect(() => {
            if (handler && !React.isValidElement(handler)) {
                if (typeof handler === "object") {
                    setHandlerElement(
                        <div id={handler?.id} onClick={handler?.onClick}>
                            {handler?.label}
                        </div>
                    );
                } else if (typeof handler === "string" || typeof handler === "number") {
                    setHandlerElement(<div>{handler}</div>);
                }
            } else {
                setHandlerElement(handler);
            }
        }, [handler]);

        useEffect(() => {
            if (onOpenChange) {
                onOpenChange(isOpen);
            }
        }, [isOpen]);

        useEffect(() => {
            if (!id) {
                console.error("Dropdown component must have an id.");
            }
        }, [id]);

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (dropdownElementRef?.current) {
                    dropdownElementRef.current.focus();
                    return true;
                }
                return false;
            },
            activeFocus: () => {
                if (dropdownElementRef?.current) {
                    return document.activeElement === dropdownElementRef.current;
                }
                return false;
            },
            open,
            close,
            toggle,
        }));

        const currentBody = body || children;

        const designClass = {
            dropdown: null,
            handler: null,
            handlerIcon: null,
            ...customDesignClass,
        };

        const designStyle = {
            dropdown: null,
            handler: null,
            handlerIcon: null,
            ...customDesignStyle,
        };

        const arrowElement = (
            <Button
                id={`${id}-dropdown-arrow`}
                disabled={disabled || disabledArrow}
                onClick={toggle}
                className={`${isOpen ? "icon-chevron-up" : "icon-chevron"} ${designClass?.handlerIcon} ${
                    disabledArrow ? "opacity-50" : ""
                }`}
                style={designStyle?.handlerIcon}
            ></Button>
        );

        const dropdownClass = classNames({
            "flex items-center justify-between": true,
            "cursor-pointer": !disabled,
            "cursor-default": disabled,
            [designClass?.dropdown]: designClass?.dropdown,
            [designClass?.validation]: designClass?.validation,
            [designClass?.selectPages]: designClass?.selectPages,
        });

        const dropdownStyle = {
            ...designStyle?.dropdown,
            ...customDesignStyle?.dropdown,
        };

        return (
            <div
                id={`${id}-dropdown-handler`}
                className="relative w-full "
                ref={dropdownRef}
                style={
                    width
                        ? {
                              width: width,
                          }
                        : null
                }
                onClick={(e) => {
                    if (e) {
                        e.stopPropagation();
                    }
                }}
            >
                <div
                    ref={dropdownElementRef}
                    className={dropdownClass}
                    style={dropdownStyle}
                    id={`${id}-dropdown`}
                    onClick={!disabled && !disabledArrow ? toggle : null}
                    tabIndex={-1}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (!disabled && !disabledArrow) {
                                open();
                            }
                        } else if (e.key === "Escape") {
                            close();
                        }
                    }}
                    data-tip={tooltip}
                    data-for={tooltipType || "default-tooltip"}
                >
                    {showArrow && arrowPosition === "left" ? arrowElement : null}
                    {React.isValidElement(handlerElement) ? (
                        cloneElement(handlerElement, {
                            className: classNames({
                                [handlerElement.props.className]: !!handlerElement.props.className,
                                [designClass?.handler]: designClass?.handler,
                            }),
                            style: {
                                ...handlerElement.props.style,
                                ...designStyle?.handler,
                            },
                            disabled,
                        })
                    ) : (
                        <div className={designClass?.handler} style={designStyle?.handler}>
                            &nbsp;
                        </div>
                    )}
                    {showArrow && arrowPosition === "right" ? arrowElement : null}
                </div>
                {currentBody && isOpen && !float ? currentBody : null}
                {float && containerRef.current ? ReactDOM.createPortal(currentBody, containerRef.current) : null}
            </div>
        );
    }
);
Dropdown.displayName = "Dropdown";

export const useFloatDropdown = (props) => {
    const { id, dropdown, container, enabled, minWidth, maxWidth, zIndex, maxHeight, dropdownDirection, overflowX } =
        props || {};
    const [open, setOpen] = useState(false);

    const scrollContainer = document.getElementById("sectionContent");
    const viewportBody = document.getElementById("root");

    const config = {
        minWidth,
        maxWidth,
        maxHeight,
        dropdownDirection,
        scrollContainer,
        viewportBody,
        zIndex,
        overflowX,
    };

    const onScroll = () => {
        if (enabled && open) {
            updateDropdownPosition(dropdown, container, config);
        }
    };

    useEffect(() => {
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", onScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", onScroll);
            }
        };
    }, [scrollContainer, onScroll]);

    useEffect(() => {
        if (enabled) {
            if (open) {
                if (container) {
                    container.id = `${id}-dropdown-container`;
                }
                displayDropdown(dropdown, container, config);
            } else {
                removeDropdown(container, config);
            }
        }
        return () => {
            removeDropdown(container, config);
        };
    }, [container, dropdown, open, enabled]);

    return {
        open: () => setOpen(true),
        close: () => setOpen(false),
    };
};

const displayDropdown = (dropdown, container, config) => {
    const { viewportBody } = config || {};
    // Ensure the dropdown is removed before placing it again
    removeDropdown(container, config);
    if (viewportBody && container) {
        // Place the dropdown in the viewport body
        placeDropdown(dropdown, container, config);
        viewportBody.appendChild(container);
    }
};

const placeDropdown = (dropdown, container, config) => {
    const { minWidth, maxWidth, maxHeight, dropdownDirection, scrollContainer, zIndex, overflowX } = config || {};
    if (dropdown && container) {
        const handlerRect = dropdown.getBoundingClientRect();
        if (handlerRect) {
            const handlerWidth = dropdown.offsetWidth;
            container.style.position = "absolute";
            container.style.zIndex = zIndex ?? 998;
            container.style.visibility = "hidden";
            container.style.width = "min-content";
            container.style.minWidth = minWidth === "handler" ? handlerWidth + "px" : minWidth || "0";
            container.style.maxWidth = maxWidth === "handler" ? handlerWidth : maxWidth || "100vw";
            if (overflowX) {
                container.style.overflowX = overflowX;
            } else if (!container.style.overflow && !container.style.overflowX) {
                container.style.overflowX = "auto";
            }

            if (maxHeight) {
                container.style.maxHeight = maxHeight;
                if (!container.style.overflow && !container.style.overflowY) {
                    container.style.overflowY = "auto";
                }
            }
            setTimeout(() => {
                const windowWidth = window.innerWidth;
                const handlerLeft = handlerRect.left;
                const handlerRight = handlerRect.right;
                const handlerTop = handlerRect.top;
                const handlerBottom = handlerRect.bottom;
                const menuWidth = container.offsetWidth;
                const menuHeight = container.offsetHeight;
                const windowMargin = 10;

                // Set x position
                if (handlerLeft + menuWidth + windowMargin > windowWidth) {
                    if (handlerRight - menuWidth - windowMargin < 0) {
                        // Match the window's right margin
                        container.style.right = `${windowMargin}px`;
                        container.style.left = undefined;
                    } else {
                        // Match the handler's right position
                        container.style.right = `${windowWidth - handlerRight}px`;
                        container.style.left = undefined;
                    }
                } else {
                    if (handlerLeft - windowMargin < 0) {
                        // Match the window's left margin
                        container.style.left = `${windowMargin}px`;
                        container.style.right = undefined;
                    } else {
                        // Match the handler's left position
                        container.style.left = `${handlerLeft}px`;
                        container.style.right = undefined;
                    }
                }

                // Set y position
                const bestDirection = handlerBottom + menuHeight + windowMargin > window.innerHeight ? "up" : "down";
                const direction = dropdownDirection || bestDirection;

                switch (direction) {
                    case "up":
                        if (handlerTop - menuHeight - windowMargin < 0) {
                            // Match the window's top margin
                            container.style.top = `${windowMargin}px`;
                            container.style.bottom = undefined;
                        } else {
                            // Match the handler's top position
                            container.style.top = `${handlerTop - menuHeight}px`;
                            container.style.bottom = undefined;
                        }
                        break;
                    case "down":
                    default:
                        const requiredHeight = handlerBottom + menuHeight + windowMargin;
                        const requiredScroll =
                            requiredHeight > window.innerHeight ? requiredHeight - window.innerHeight : 0;
                        container.style.top = `${handlerBottom}px`;
                        container.style.bottom = undefined;
                        requireExtraScroll(requiredScroll, scrollContainer);
                        break;
                }
                container.style.visibility = "visible";
            }, 100);
        }
    }
};

const updateDropdownPosition = (dropdown, container, config) => {
    const { dropdownDirection } = config || {};
    if (dropdown && container) {
        const handlerRect = dropdown.getBoundingClientRect();
        if (handlerRect) {
            const windowWidth = window.innerWidth;
            const handlerLeft = handlerRect.left;
            const handlerRight = handlerRect.right;
            const handlerTop = handlerRect.top;
            const handlerBottom = handlerRect.bottom;
            const menuWidth = container.offsetWidth;
            const menuHeight = container.offsetHeight;
            const windowMargin = 10;

            // Set x position
            if (handlerLeft + menuWidth + windowMargin > windowWidth) {
                if (handlerRight - menuWidth - windowMargin < 0) {
                    // Match the window's right margin
                    container.style.right = `${windowMargin}px`;
                    container.style.left = undefined;
                } else {
                    // Match the handler's right position
                    container.style.right = `${windowWidth - handlerRight}px`;
                    container.style.left = undefined;
                }
            } else {
                if (handlerLeft - windowMargin < 0) {
                    // Match the window's left margin
                    container.style.left = `${windowMargin}px`;
                    container.style.right = undefined;
                } else {
                    // Match the handler's left position
                    container.style.left = `${handlerLeft}px`;
                    container.style.right = undefined;
                }
            }

            // Set y position
            const bestDirection = handlerBottom + menuHeight + windowMargin > window.innerHeight ? "up" : "down";
            const direction = dropdownDirection || bestDirection;

            switch (direction) {
                case "up":
                    if (handlerTop - menuHeight - windowMargin < 0) {
                        // Match the window's top margin
                        container.style.top = `${windowMargin}px`;
                        container.style.bottom = undefined;
                    } else {
                        // Match the handler's top position
                        container.style.top = `${handlerTop - menuHeight}px`;
                        container.style.bottom = undefined;
                    }
                    break;
                case "down":
                default:
                    container.style.top = `${handlerBottom}px`;
                    container.style.bottom = undefined;
                    break;
            }
        }
    }
};

const removeDropdown = (container, config) => {
    const { scrollContainer } = config || {};
    if (container?.parentNode) {
        container.parentNode.removeChild(container);
    }
    restoreExtraScroll(scrollContainer);
};

const requireExtraScroll = (requiredScroll, scrollContainer) => {
    if (requiredScroll && scrollContainer) {
        scrollContainer.style.paddingBottom = `${requiredScroll}px`;
        scrollContainer.scrollBy({
            top: requiredScroll,
            behavior: "smooth",
        });
    }
};

const restoreExtraScroll = (scrollContainer) => {
    if (scrollContainer) {
        scrollContainer.style.paddingBottom = "0";
    }
};

export default Dropdown;
