import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useAuth } from "hooks/Session/auth";
import { Session } from "hooks/Utils/Session";
import { setActiveHotel } from "actions/hotelActions";
import { setDropdownMenu } from "actions/uiActions";
import { useLoginProject, useLoginCorporate } from "hooks/Data/useUser";

import Select from "components/Select";
import ProjectName from "components/ProjectName";

const MINIMUM_PROJECTS_TO_SEARCH = 6;

const HotelsDropdown = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loginProject: storeLoginProject, lang, chain, project, projects, isMonoProject, user } = useAuth();

    const someHasChain = projects?.some((project) => project?.hasChainModule);

    const loginParams = {
        chainID: chain?.id,
        chainRef: chain?.ref,
        lang,
        onSuccess: (data) => {
            if (data?.token) {
                storeLoginProject(data?.projectRef, data?.token);
            }
        },
        onError: (error) => {
            toast.error(error);
        },
    };
    const { login: loginProject } = useLoginProject(loginParams);
    const { login: loginCorporate } = useLoginCorporate(loginParams);

    useEffect(() => {
        dispatch(setActiveHotel(Select.value));
    }, [Select.value, dispatch]);

    useEffect(() => {
        if (Select.isOpen) {
            dispatch(setDropdownMenu("hotels"));
        } else {
            dispatch(setDropdownMenu(null));
        }
    }, [Select.isOpen]);

    const selectProject = (ref) => {
        if (ref === "CORPORATE") {
            loginCorporate({ userRef: user?.ref });
        } else {
            loginProject({ projectRef: ref, userRef: user?.ref });
        }
    };

    return (
        <div className="relative max-w-xs">
            {projects?.length > 1 ? (
                <Select
                    id="hotel-dropdown-selected"
                    value={project?.ref}
                    designClass={{
                        dropdown: "bg-transparent",
                    }}
                    allowUnselect={false}
                    searchPlaceholder={t("search-property") + "..."}
                    title={<div className="p-4 text-gray-800 border-b border-gray-300">{chain?.name}</div>}
                    onChange={selectProject}
                    search={projects?.length >= MINIMUM_PROJECTS_TO_SEARCH}
                    options={
                        projects?.length
                            ? projects.map((project) =>
                                  project
                                      ? {
                                            value: project.ref,
                                            label: (
                                                <ProjectItem
                                                    alt={project?.trName ? t(project?.trName) : project?.name}
                                                    project={project}
                                                    displayChain={someHasChain}
                                                />
                                            ),
                                            selectedLabel: <ProjectItem project={project} displayChain={false} />,
                                        }
                                      : null
                              )
                            : []
                    }
                />
            ) : (
                <ProjectItem alt={project?.name} project={project} displayChain={someHasChain} />
            )}
        </div>
    );
};

const ProjectItem = ({ project, displayChain }) => {
    const { t } = useTranslation();
    const name = project?.trName ? t(project?.trName) : project?.name;
    const location = project?.trLocation ? t(project?.trLocation) : project?.location;
    const hasChainModule = project?.hasChainModule;
    return (
        <div className="flex items-start w-full py-1" searchValue={`${name} ${location}`}>
            {displayChain ? (
                <div className="w-6 text-center mr-2">
                    <ProjectName hasChainModule={hasChainModule} />
                </div>
            ) : null}
            <div className="w-full whitespace-no-wrap">
                <div className="font-bold truncate">{name}</div>
                {location ? <div className="text-gray-700 truncate">{location}</div> : null}
            </div>
        </div>
    );
};

export default HotelsDropdown;
