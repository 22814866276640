import { useEffect, useState } from "react";
import { useApolloClient } from "react-apollo";

import { ManagerService } from "App";

export const getGQLClient = (options) => {
    const { client } = queryConfig({ ...options });
    return client;
};

const useGQLQuery = (query, options) => {
    const defaultClient = useApolloClient();

    const { onSuccess, onError, client } = queryConfig({ ...options, defaultClient });

    const [{ data, loading, error, called, variables }, setQueryState] = useState({});

    const ready = called && !loading && !error;

    const mutation = query?.definitions?.[0]?.operation === "mutation";

    const run = (variables) => {
        const props = { variables };
        setQueryState({
            data: null,
            loading: true,
            error: null,
            called: true,
            variables,
        });
        const callProps = {
            fetchPolicy: options?.fetchPolicy ?? "no-cache",
            errorPolicy: options?.errorPolicy ?? "all",
            ...props,
        };
        const success = (response) => {
            if (response?.errors) {
                console.error(response?.errors);
                setQueryState((prev) => ({ ...prev, loading: response?.loading, error: response?.errors }));
                return;
            }
            setQueryState((prev) => ({
                ...prev,
                data: response?.data,
                loading: response?.loading,
                error: response?.errors,
            }));
        };
        const error = (err) => {
            console.error(err);
            setQueryState((prev) => ({ ...prev, loading: false, error: err }));
        };

        if (mutation) {
            client
                .mutate({
                    ...callProps,
                    mutation: query,
                })
                .then(success)
                .catch(error);
        } else {
            client
                .query({
                    ...callProps,
                    query,
                })
                .then(success)
                .catch(error);
        }
    };

    useEffect(() => {
        if (ready && onSuccess) {
            onSuccess(data);
        }
    }, [ready]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        load: mutation ? undefined : run,
        post: mutation ? run : undefined,
        data,
        loading,
        error,
        called,
        variables,
        ready,
        refetch: () => {
            if (called) {
                run(variables);
            }
        },
    };
};

const queryConfig = (options) => {
    const {
        onSuccess,
        onError,
        token: customToken,
        serviceURL: customServiceURL,
        service: customService,
        defaultClient,
    } = options || {};
    const service = customService || ManagerService;
    const client = customToken ? service(customToken, customServiceURL) : defaultClient;
    return {
        onSuccess,
        onError,
        client,
    };
};

export default useGQLQuery;
