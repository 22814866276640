import React, { useContext, useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";

import { useChangeForgottenPassword, useChangePassword } from "hooks/Data/useUser";
import { toastValidations } from "hooks/Utils/Validations";

import Button from "components/Button";
import TextInput from "components/TextInput";
import Modal, { useModal } from "components/Modal";

import { GlobalContext } from "contexts/Global";

// Password validation rules
// At least 6 characters
const PASSWORD_VALIDATION_SIZE = /^.{6,}$/;
// At least one uppercase and one lowercase
const PASSWORD_VALIDATION_CASE = /(?=.*[a-z])(?=.*[A-Z])/;
// At least one number
const PASSWORD_VALIDATION_NUMBERS = /\d/;
// At least 6 characters, one uppercase, one lowercase and one number
const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

export const usePasswordChange = (props) => {
    const { loggedIn } = props || {};
    const { t } = useTranslation();
    const history = useHistory();

    const modalRef = useRef(null);
    const changeRef = useRef(null);
    const { open: openModal, close: closeModal } = useModal();

    const submit = () => {
        if (changeRef?.current) {
            changeRef.current.submit();
        }
    };

    const onSuccess = () => {
        closeModal();
        toast.success(t("operation-successful"));
        if (loggedIn) {
            history("/login");
        }
    };

    return {
        open: (props) => {
            const { email, code, hash } = props || {};
            openModal(
                <Modal
                    ref={modalRef}
                    id="modal-button-change-password"
                    title={loggedIn ? `${t("account")} - ${t("change-password")}` : t("change-password")}
                    footer={
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={closeModal}>
                                {t("cancel")}
                            </Button>
                            <Button design="blue" id="modal-button-continue" onClick={submit}>
                                {t("change-password")}
                            </Button>
                        </>
                    }
                    minWidth="30rem"
                >
                    <PasswordChange
                        ref={changeRef}
                        loggedIn={loggedIn}
                        email={email}
                        code={code}
                        hash={hash}
                        onSuccess={onSuccess}
                        onLoading={(loading) => {
                            if (modalRef.current) {
                                modalRef.current.setLoading(loading);
                            }
                        }}
                    />
                </Modal>
            );
        },
        close: closeModal,
    };
};

const PasswordChange = forwardRef(({ loggedIn, email, code, hash, onLoading, onSuccess }, ref) => {
    const { lang } = useContext(GlobalContext);
    const { t } = useTranslation();

    const oldPassRef = useRef(null);
    const passRef = useRef(null);

    const [currentPass, setCurrentPass] = useState("");

    const onMutationError = (error) => {
        if (error) {
            console.error(error);
            if (error?.message?.toLowerCase()?.includes("incorrect password")) {
                toast.error(t("invalid password"));
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    const { update: updateCurrentPass, loading: updatingCurrentPass } = useChangePassword({
        onSuccess,
        onError: onMutationError,
    });

    const { update: setNewPass, loading: updatingNewPass } = useChangeForgottenPassword({
        lang,
        onSuccess,
        onError: onMutationError,
    });

    const onEnterChange = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
            changePass();
        }
    };

    const changePass = () => {
        const oldPassword = oldPassRef.current?.getValue();
        const password = passRef.current?.getValue();

        if (
            !toastValidations(loggedIn ? { ref: oldPassRef, message: t("invalid-current-password") } : null, {
                ref: passRef,
                message: t("invalid password"),
            })
        ) {
            return;
        }

        if (loggedIn) {
            updateCurrentPass({ email: loggedIn, oldPassword, newPassword: password });
        } else {
            setNewPass({ email, code, hash, newPassword: password });
        }
    };

    useImperativeHandle(ref, () => ({
        submit: changePass,
    }));

    useEffect(() => {
        const loading = updatingNewPass || updatingCurrentPass;
        if (onLoading) {
            onLoading(loading);
        }
    }, [updatingNewPass, updatingCurrentPass]);

    const ruleClass = (rule) => {
        return rule?.test(currentPass) ? "line-through" : "text-red-100";
    };

    return (
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-col space-y-5">
                {loggedIn ? (
                    <div className="space-y-1">
                        <div>{`${t("current-password")} *`}</div>
                        <TextInput
                            ref={oldPassRef}
                            id="old-password-input"
                            type="password"
                            disabled={updatingNewPass}
                            placeholder={loggedIn ? t("current-password") : t("password")}
                            required={true}
                            autoComplete="old-pass"
                            validate={PASSWORD_VALIDATION}
                            onKeyPress={(e) => {
                                if (e?.key === "Enter") {
                                    e.preventDefault();
                                    if (passRef.current) {
                                        passRef.current.focus();
                                    }
                                }
                            }}
                        />
                    </div>
                ) : null}
                <div className={loggedIn ? "space-y-1" : "space-y-5"}>
                    <div>{loggedIn ? `${t("new-password")} *` : t("enter new password")}</div>
                    <TextInput
                        ref={passRef}
                        id="new-password-input"
                        type="password"
                        disabled={updatingNewPass}
                        placeholder={loggedIn ? t("new-password") : t("password")}
                        required={true}
                        autoComplete="new-pass"
                        validate={PASSWORD_VALIDATION}
                        onKeyPress={onEnterChange}
                        onChange={setCurrentPass}
                    />
                </div>
                <ul className="text-right">
                    <li className={ruleClass(PASSWORD_VALIDATION_SIZE)}>{t("password-six-chars")}</li>
                    <li className={ruleClass(PASSWORD_VALIDATION_CASE)}>{t("password-upper-and-lower")}</li>
                    <li className={ruleClass(PASSWORD_VALIDATION_NUMBERS)}>{t("password-numbers")}</li>
                </ul>
            </div>
        </form>
    );
});
