import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setCustomConfig,
} from "../../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import GetTableHeaderButton from "../../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";

//Model
import { useMSQuery } from "../../useMSQuery";
import InputGroup from "../models/InputGroup";

const ListInputGroups = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);

    //States
    const [sorting] = useState(`,orderBy:{field: "name", criteria: "asc"}`);

    //Data
    const sectionName = "list-input-groups";

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_INPUT_GROUPS = gql`{
    tvInputs(page: ${currentPage}, ${sortCriteria ? sortCriteria : sorting}${filterQuery} size: ${perPage}){
      info { count }
      results {  id name isDefault tvsAssigned inputs { name icon imageRef pos input deviceType} }
  }}`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_INPUT_GROUPS);

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        dispatch(setHeaderButtons(GetTableHeaderButton(sectionName, {}, null, t)));
        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data?.tvInputs && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data, t);
            const tableHeaders = GetTableHeaders(sectionName);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.tvInputs.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            dispatch(setActions(true));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }

            dispatch(setCustomError(t("no available(general)", { name: t("tv-input-groups") })));

            dispatch(setLoading(false));
        }

        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const arrangeData = (data, t) => {
    let inputGroupsData = [];
    // eslint-disable-next-line
    data.tvInputs.results.map((group) => {
        inputGroupsData.push(InputGroup(group, t));
    });
    return inputGroupsData;
};

export default ListInputGroups;
