export const Rooms = [
    "device",
    "name",
    "warning",
    "warnings",
    "notice",
    "last-activity",
    "hour-ago",
    "hours-ago",
    "day-ago",
    "days-ago",
    "now",
    "ip",
    "mac",
    "wifi-signal",
    "activity-time",
    "pairing-code",
    "version",
    "license-profile",
    "elements",
    "items",
    "previous",
    "showing-all-results",
    "next",
    "occupied",
    "empty",
    "last-stay",
    "room-group",
    "guest",
    "status",
    "stay",
    "preview",
    "detail",
    "room",
    "wing",
    "floor",
    "from",
    "device-status",
    "country",
    "lang",
    "guest-group",
    "view-stay",
    "companions",
    "assign",
    "assign-to-group",
    "group",
    "room-group-assign",
    "floor",
    "wing",
    "select-group",
    "checkout",
    "select-rooms-to-checkout",
    "add-guest",
    "guest-enter_data",
    "mobile-phone",
    "guest-groups",
    "room-number",
    "unspecified",
    "room-groups",
    "rooms-to-add-to-group",
    "rooms-to-add-to-group-advice",
    "add-to-group",
    "loyal-customer",
];
