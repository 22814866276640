import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useGetCommonZoneTVs, useGetGroupCommonZoneTVs, useListFilters } from "../graphql/useSignages";
import { CZ_TYPE, GROUP_CZ_TYPE, TV_LOCATION_TYPE } from "../constants";
import Loading from "components/Loading";

const ModalFilters = (props) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState();
    const { getCommonZoneTv, tvs, loading: loadingCZ } = useGetCommonZoneTVs();
    const { getGroupCommonZoneTv, tvs: groupCommonZonesTVs, loading: loadingGroupCZ } = useGetGroupCommonZoneTVs();
    const { getFilters, loading: loadingFilters, filters: listFilters } = useListFilters();
    const filters = props?.filters
        ? Object.entries(props?.filters).reduce((acc, curr) => {
              if (typeof curr[1] == "object" && curr[1]?.length > 0) {
                  acc = [
                      ...acc,
                      ...curr[1].map((val) => ({
                          name:
                              curr[0] != TV_LOCATION_TYPE
                                  ? val.name
                                  : `${
                                        listFilters?.commonZones?.find((cz) => {
                                            return cz.roomTVs.find((tv) => tv.id === val.id);
                                        })?.name
                                    } - ${val.name}`,
                          id: val.id,
                          type: curr[0],
                      })),
                  ];
              }
              return acc;
          }, [])
        : [];

    const tvsResults = {
        [CZ_TYPE]: tvs,
        [GROUP_CZ_TYPE]: groupCommonZonesTVs,
        [TV_LOCATION_TYPE]: [selected],
    };

    const loading = loadingCZ || loadingGroupCZ;

    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        if (filters.length > 0 && !selected && listFilters?.commonZones?.length > 0) {
            setSelected({ id: filters[0].id, type: filters[0].type, name: filters[0].name });
        }
    }, [listFilters]);

    useEffect(() => {
        if (selected?.id) {
            switch (selected?.type) {
                case CZ_TYPE:
                    getCommonZoneTv({ variables: { id: selected.id } });
                    break;
                case GROUP_CZ_TYPE:
                    getGroupCommonZoneTv({ variables: { id: selected.id } });
                    break;
            }
        }
    }, [selected]);

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("filters")}`}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-8/12 p-10"}
        >
            {loadingFilters ? (
                <Loading></Loading>
            ) : (
                <div className=" grid grid-cols-5 gap-2">
                    <div className=" col-span-3">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t("sequence-has-the-following-filters", {
                                    name: `<strong>${props.name}</strong>`,
                                }),
                            }}
                        ></p>
                        <p className="mt-4 font-bold">
                            {t("filters")}
                            <span className=" font-normal pl-2">({t("filters-x", { count: filters.length })})</span>
                        </p>
                        <div className=" mt-2 flex gap-2 flex-wrap">
                            {filters.map((val) => {
                                return (
                                    <p
                                        key={val.name + "-" + val.id}
                                        className={`${
                                            val?.id == selected?.id
                                                ? "bg-blue-400 text-white cursor-default"
                                                : "bg-gray-300 cursor-pointer"
                                        } py-1 px-2 w-fit-content rounded-md`}
                                        onClick={() => {
                                            if (val.id != selected?.id) {
                                                setSelected({ id: val.id, type: val.type, name: val.name });
                                            }
                                        }}
                                    >
                                        {val.name}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className=" col-span-2">
                        <div className=" rounded-md flex justify-between mb-2">
                            <span>{t("results-of-the-selected-filter")}:</span>
                            <span className="text-gray-500 ">
                                {t("x rows_other", { count: tvsResults?.[selected?.type]?.length })}
                            </span>
                        </div>
                        <div className="flex flex-col border p-2 rounded-md border-gray-500 min-h-40 max-h-40 overflow-y-auto">
                            {loading ? (
                                <div className="w-full h-full flex-1 flex justify-center items-center">
                                    <Loading></Loading>
                                </div>
                            ) : (
                                <>
                                    {tvsResults?.[selected?.type]?.length > 0 ? (
                                        tvsResults?.[selected?.type]?.map((val) => (
                                            <p className="px-2 py-3 mb-2 border-b border-gray-500">{val.name}</p>
                                        ))
                                    ) : (
                                        <div className=" flex flex-1 justify-center items-center">
                                            <p className=" text-gray-800">{t("no-tv-locations-with-this-filter")}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalFilters {...newProps} />);
        },
    };
};
