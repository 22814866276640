import React from "react";
import UseSectionHeader from "../../useSectionHeader";
import UseButton from "../../useButton";
import UseTable from "../../Table";
import ListFonts from "../../../hooks/GraphqlCalls/MediaLibrary/ListFonts";
import ListFontsBatchActions from "../../../hooks/GraphqlCalls/MediaLibrary/ListFontsBatchActions";
import NewFont from "../../../hooks/GraphqlCalls/MediaLibrary/modals/NewFont";
import { changeGlobalAction } from "../../../actions/globalActions";
import { useDispatch } from "react-redux";
import { setModalContent, openModal } from "../../../actions/uiActions";

const Fonts = () => {
    const dispatch = useDispatch();
    const customBC = [
        {
            name: "design",
            route: "/design",
            cookieRoute: null,
        },
        {
            name: "library",
            route: "/design/library/root/media",
            cookieRoute: null,
        },
    ];

    ListFonts();
    ListFontsBatchActions();

    const addFont = () => {
        const modal = NewFont();
        dispatch(
            changeGlobalAction({
                actionName: "add-library-font",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <>
            <UseSectionHeader title={"fonts"} customBreadCrumbs={customBC} />
            <div>
                <div className="inline-flex export-btn z-20">
                    <div onClick={() => addFont()} id="add-font">
                        <UseButton
                            icon="add"
                            buttonName={"add-font"}
                            buttonColor={"btn-blue"}
                            adjust="w-auto px-4 min-w-24 mt-1"
                        />
                    </div>
                </div>
                <UseTable useCollection={true} />
            </div>
        </>
    );
};

export default Fonts;
