import { useEffect } from "react";

import { PMSStatus, PMSCancelled } from "constants/charges";

import useGuestOrders from "hooks/Data/useGuestOrders";

const GetGuestOrders = ({
    setDataOrders,
    guestID,
    guestRoomID,
    setCount,
    setLoading,
    orderBy,
    orderDir,
    shopsTokens,
    pagination,
    search,
    onError,
}) => {
    const { load: getGuestOrders, loading, error, data } = useGuestOrders({ commonVars: { lang: "es" } });

    useEffect(() => {
        getGuestOrders({
            guestID,
            guestRoomID,
            search,
            page: pagination?.currentPage,
            size: pagination?.perPage,
            orderBy,
            orderDir,
            shopsTokens,
        });
    }, [guestID, guestRoomID, pagination, search, orderBy, orderDir, shopsTokens]);

    useEffect(() => {
        if (data) {
            if (setDataOrders) {
                const orders = data.orders?.results;
                const charges = data.charges?.results;
                setDataOrders(
                    orders
                        ? orders.map((order) => {
                              const charge = charges?.find((charge) => charge.extId === order.id);
                              const pmsStatus = charge?.canceledAt ? PMSCancelled : charge?.pmsStatus;
                              const dateObj = order.date ? new Date(order.date) : null;
                              return {
                                  ...order,
                                  pmsStatus,
                                  infoDateHour: dateObj
                                      ? {
                                            hour: dateObj?.toLocaleTimeString(undefined, {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }),
                                            date: dateObj?.toLocaleDateString(),
                                        }
                                      : null,
                              };
                          })
                        : null
                );
            }
            if (setCount) {
                setCount(data?.orders?.info?.count);
            }
        }
    }, [data, setDataOrders, setCount]);

    useEffect(() => {
        if (setLoading) {
            setLoading(loading);
        }
    }, [loading, setLoading]);

    useEffect(() => {
        if (error) {
            console.log("Error getting guest orders", error);
            if (onError) {
                onError(error);
            }
        }
    }, [error]);

    return null;
};

export default GetGuestOrders;
