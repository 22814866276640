import React, { useEffect, useState, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import ReactHlsPlayer from "react-hls-player";
import { useGetDetailContent } from "../graphql/useSignages";
import { buildAssetInformation, buildVideoInformation } from "../utils";
import { SIGNAGE_CONTENT_TYPES } from "../constants";
import { Session } from "hooks/Utils/Session";
import Loading from "components/Loading";
import Icon from "components/Icon";

const AssetDisplayer = (props) => {
    const { fileRef, lang } = props;
    return (
        <img
            alt=""
            src={
                fileRef
                    ? `${Session.getDasUrl(fileRef + "?lang=" + lang + "&w=633&h=336")}`
                    : require(`../../../../../assets/images/icons/image.svg`)
            }
            className={`${fileRef ? `max-h-full max-w-full` : `h-full w-full`} m-auto pl-5 pr-5 bg-gray-100`}
        ></img>
    );
};

const VideoDisplayer = (props) => {
    const playerRef = useRef();
    const { fileRef, couldPlay, videoSrc, posterSrc } = props;
    const [playing, setPlaying] = useState(false);

    function playVideo() {
        if (!playing) {
            if (playerRef.current) {
                playerRef.current.controls = true;
                playerRef.current.play();
                setPlaying(true);
            }
        }
    }
    useEffect(() => {
        if (playing && playerRef.current) {
            playerRef.current.pause();
        }
        setPlaying(false);
    }, [fileRef]);

    return (
        <div
            className="relative"
            onClick={() => {
                if (couldPlay) {
                    playVideo();
                }
            }}
        >
            <ReactHlsPlayer
                playerRef={playerRef}
                poster={posterSrc}
                src={videoSrc}
                controls={false}
                autoPlay={false}
                muted={false}
                loop={false}
                className={`w-full h-full bg-gray-200`}
            ></ReactHlsPlayer>
            {!playing && (
                <div
                    className={`absolute top-0 left-0 w-full bg-gray-300 bg-opacity-25 h-full table text-center hover:bg-opacity-0`}
                >
                    <div className="table-cell align-middle">
                        <div className={`icon icon-play2 text-white text-6xl`}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

const ScreenDisplayer = () => {
    return (
        <div className=" flex justify-center items-center min-h-20 bg-gray-200 ">
            <Icon size={4} type={"no-image"} className={"text-gray-400"}></Icon>
        </div>
    );
};

const ModalContentDetail = (props) => {
    const { t } = useTranslation();
    const [fileInformation, setFileInformation] = useState({});
    const { getDetailContent, data, loading } = useGetDetailContent({ type: props.type });

    const defaultFile = data?.languages?.find((val) => val.language == props?.contentDefaultLanguage);

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    useEffect(() => {
        getDetailContent({ variables: { ref: props.contentRef, designID: props.designID } });
    }, []);

    useEffect(() => {
        if (data) {
            if (props.type == SIGNAGE_CONTENT_TYPES[0]) {
                setFileInformation(
                    buildAssetInformation({
                        ...data,
                        defaultFileLanguage: data.defaultLanguage,
                        defaultLanguage: props.contentDefaultLanguage,
                    })
                );
            } else if (props.type == SIGNAGE_CONTENT_TYPES[1]) {
                setFileInformation(
                    buildVideoInformation({
                        ...data,
                        defaultFileLanguage: data.defaultLanguage,
                        defaultLanguage: props.contentDefaultLanguage,
                    })
                );
            } else if (props.type == SIGNAGE_CONTENT_TYPES[2]) {
                setFileInformation({ name: data.name, type: t("screen") });
            }
        }
    }, [data]);

    return (
        <Modal
            title={`${props?.name || data?.name} - ${t("show-details")}`}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-8/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" grid grid-cols-5 gap-4">
                    <div className=" col-span-3 ">
                        {props.type == SIGNAGE_CONTENT_TYPES[0] && (
                            <AssetDisplayer
                                fileRef={props.contentRef}
                                lang={defaultFile?.language || data?.defaultLanguage}
                            ></AssetDisplayer>
                        )}
                        {props.type == SIGNAGE_CONTENT_TYPES[1] && (
                            <VideoDisplayer
                                fileRef={props.contentRef}
                                videoSrc={props?.item?.videoSrc}
                                posterSrc={props?.item?.thumbnail}
                                lang={defaultFile?.language || data?.defaultLanguage}
                                couldPlay={data?.ready}
                            ></VideoDisplayer>
                        )}
                        {props.type == SIGNAGE_CONTENT_TYPES[2] && <ScreenDisplayer></ScreenDisplayer>}
                    </div>
                    <div className=" col-span-2 flex flex-col gap-y-2">
                        <p className=" text-1xl font-bold">{t("file-information")}</p>
                        {Object.keys(fileInformation).map((val) => {
                            return (
                                <div className="flex gap-1" key={`content-information-${val}`}>
                                    <p className=" font-bold">{t(val)}:</p>
                                    <p>{fileInformation[val]}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalContentDetail = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalContentDetail {...newProps} />);
        },
    };
};
