import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
//Utils
import useComponentVisible from "../../components/useComponentVisible";

import { Link } from "react-router-dom";
import { setDropdownMenu } from "../../actions/uiActions";
import { UseSettingsMenu } from "../../hooks/map";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";

import { GlobalContext } from "contexts/Global";

const prefix = `settings-menu`;

const IconSettings = () => {
    const { paths } = useContext(GlobalContext);
    //Store data
    const { ref, showing, hide, toggle } = useComponentVisible(false);
    const { dropdownOpened } = useSelector((state) => state.ui);
    const dispatch = useDispatch();

    const { isMonoProject, isCorporate } = useAuth();

    const { t } = useTranslation();
    const items = UseSettingsMenu({ paths });

    useEffect(() => {
        if (dropdownOpened !== "settings") {
            hide();
        }
        // eslint-disable-next-line
    }, [dropdownOpened]);

    useEffect(() => {
        dispatch(setDropdownMenu(showing ? "settings" : null));
    }, [showing]);

    const getName = (key) => {
        switch (key) {
            case "manager-users":
            case "manager-roles":
                return t("user-management");
            case "manager-properties":
            case "manager-propertyGroups":
                return t("property-management");
            case "tax-zones":
                return t("finance");
            case "content-languages":
                return t("language-management");
            case "policy-and-conditions-management":
                if (!isCorporate && !isMonoProject) {
                    return t("policy-and-conditions");
                }
            default:
                return t(key);
        }
    };

    const getPath = (key) => {
        let path = "";
        switch (key) {
            case "manager-users":
                path = "user-management";
                break;
            case "manager-roles":
                path = "user-management/roles";
                break;
            case "content-languages":
                path = "language-management";
                break;
            case "app-staff-users":
                path = "user-management/app-staff-users";
                break;
            case "manager-properties":
                path = "property-management/properties";
                break;
            case "manager-propertyGroups":
                path = "property-management/propertyGroup";
                break;
            case "policy-and-conditions-management":
                path = "policy-and-conditions-management/policy-and-conditions";
                break;
            case "tax-zones":
                path = "finance/tax-zones";
                break;
            default:
                path = key;
        }
        return "/general-settings/" + path;
    };

    return (
        <div className="relative" ref={ref}>
            {items?.length ? (
                <div
                    id={"topBar-settings"}
                    onClick={() => toggle()}
                    className={`icon icon-settings text-blue-1100 menu-icon p-2 cursor-pointer  ${
                        showing ? "text-blue-300 font-black" : ""
                    }`}
                ></div>
            ) : null}
            {showing ? (
                <div
                    onClick={() => hide()}
                    className="absolute mt-1 p-3 bg-white shadow-xl rounded-md"
                    style={{ left: "-12rem", width: `${"17rem"}` }}
                >
                    {items.map((value) => {
                        const id = `${prefix}-${value}`;
                        const name = getName(value);
                        const to = getPath(value);
                        return (
                            <Link key={id} id={id} to={to}>
                                <div className="text-gray-900 cursor-pointer py-2 first-capital pb-4 clickable">
                                    {name}
                                </div>
                            </Link>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default IconSettings;
