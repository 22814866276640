import React, { useState, useEffect, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import { ColorPicker } from "components/Inputs/ColorPicker";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import Radiobuttons from "components/Inputs/Radiobuttons";
import FileHandler from "components/FileHandler";
import UseInputPercent from "components/Inputs/useInputPercent";
import { getProjectLangs, categoriesPrefix, getAssetUrl } from "hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UseToast from "components/Notifications/useToast";
import uuid from "react-uuid";
import { executeVendureQuery, useVendureAPI, uploadVendureMutation } from "hooks/Utils/Integrations/useVendure";
import { hexToRgbA, arrangeToastMessagesUploadingFiles } from "hooks/Utils/DesignUtils";
import { rgba2hex } from "hooks/Utils/ColorUtils";
import Loading from "components/Loading";
import { useAuth } from "hooks/Session/auth";

const ModalAddCategory = (props) => {
    const { t } = useTranslation();

    const { adminApi } = useVendureAPI();

    const { languages: projectLangs } = useAuth();
    const disabledRadioSubcategorie = props.level === 2;
    const COLOR = "COLOR";
    const IMAGE = "IMAGE";
    const SUBCATEGORIES = "SUBCATEGORIES";
    const PRODUCTS = "PRODUCTS";
    const inputFileRef = useRef();
    const [name, setName] = useState("");
    const [contentType, setContentType] = useState(props.level === 2 ? PRODUCTS : SUBCATEGORIES);
    const [mediaType, setMediaType] = useState(COLOR);
    const [validations, setValidations] = useState({ name: true, mediaType: true, contentType: true });
    const [loading, setLoading] = useState(true);
    const [langDefault, setLangDefault] = useState("");
    const [tokenShop, setTokenShop] = useState("");
    const [categoryFacet, setCategoryFacet] = useState("");
    const [colorHex, setColorHex] = useState("");
    const [colorRgba, setColorRgba] = useState("");
    const [colorAlpha, setAlpha] = useState(100);
    const [imgSrc, setImageSrc] = useState("");

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        refetch();
        return () => {
            toast.dismiss();
        };
    }, []);

    useEffect(() => {
        if (tokenShop) {
            fetchFacets();
        }
        // eslint-disable-next-line
    }, [tokenShop]);

    useEffect(() => {
        setColorHex(rgba2hex(colorRgba));
    }, [colorRgba]);

    async function refetch() {
        const response = await executeVendureQuery(adminApi, {
            queryBody: ` query {
                channel(id: ${props.id}) {
                token
                }
            }`,
        });
        if (response && response.data && !response.errors) {
            if (response.data.channel && response.data.channel.token) {
                setTokenShop(response.data.channel.token);
            }
        } else {
            toast.error(t("mutation-error"));
        }
    }

    async function fetchFacets() {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: ` query GetFacetList {
                facets {
                items {
                    id
                    code
                }
                }
            }`,
            },
            { "vendure-token": tokenShop }
        );
        if (response && response.data && !response.errors) {
            if (response.data.facets.items) {
                const idCategoryFacet = response.data.facets.items.find((item) => {
                    return item.code === `${categoriesPrefix}_${tokenShop}`;
                })?.id;
                setCategoryFacet(idCategoryFacet);
                setLoading(false);
            }
        } else {
            toast.error(t("mutation-error"));
        }
    }

    async function createFacetCategorieValue() {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: `mutation {
                        createFacetValues(input: {
                          facetId: ${categoryFacet},
                          code: "${name.toLowerCase()}",
                          translations: [
                            { languageCode: ${langDefault}, name:  "${name}" }
                          ]
                        }) {
                          id
                          code
                          translations {
                            languageCode
                            name
                          }
                        }
            }`,
            },
            { "vendure-token": tokenShop }
        );
        if (response?.data?.errors) {
            toast.error(t("mutation-error"));
            setLoading(false);
        } else {
            await createCategorieAsColection(response?.data?.createFacetValues?.[0]?.id);
        }
    }

    async function createCategorieAsColection(id) {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: `mutation {
                        createCollection(
                          input: {
                            customFields:{
                                image_url: "${mediaType == COLOR ? colorRgba : imgSrc}"
                                can_have_children: ${contentType === "PRODUCTS" ? false : true}
                            }
                            translations: [
                              {
                                slug: "${uuid()}"
                                description: "\u200b"
                                languageCode: ${langDefault}
                                name: "${name}"
                              },
                              
                            ]
                            filters: [
                              {
                                code: "facet-value-filter"
                                arguments: [
                                  { name: "facetValueIds", value: "[${id}]" }
                                  { name: "containsAny", value: "false" }
                                  { name: "combineWithAnd", value: "true" }
                                ]
                              }
                            ]
                            ${props?.idCat ? `parentId: "${props?.idCat}"` : ""}
                            ${contentType === "PRODUCTS" ? "" : "isPrivate:false"}
                          }
                        ) {
                          id
                          name
                        }
                      }`,
            },
            { "vendure-token": tokenShop }
        );
        if (response?.data?.errors) {
            toast.error(t("mutation-error"));
        } else {
            props.navigate(
                `/services/sales/shop/categories/${props.id}/edit-category/${response.data["createCollection"]?.id}`
            );
            props.close();
        }
    }

    async function uploadCategoryImage(file) {
        if (!file.size || file.size / 1000 / 1000 > 11) {
            toast.error(`${file.name} ${t("error-exceeds-allowed-size")}`);
            return;
        }
        const files = [
            {
                name: file.name,
                error: false,
                textTooltip: file.name,
                status: 1,
                ref: "",
                size: file.size,
            },
        ];
        let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

        const toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: false,
            className: "use-toast",
            closeButton: false,
            closeOnClick: false,
            draggable: false,
            hideProgressBar: true,
            icon: false,
        });

        const response = await uploadVendureMutation(
            adminApi,
            {
                queryBody: `mutation Mutation($file: Upload!) {
                        createAssets(input: [{ file: $file }]) {
                          ... on Asset {
                            id
                            name
                            fileSize
                          }
                          ... on ErrorResult {
                            message
                          }
                        }
                      }`,
                file: file,
            },
            { "vendure-token": tokenShop }
        );

        if (!response.data.errors) {
            toast.update(toastId, {
                render: <UseToast title={title} msgs={msgs} minimize={false} />,
                className: "use-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                closeButton: true,
            });
            queryCategoryImage(response.data["createAssets"][0].id);
        }
    }

    async function queryCategoryImage(id) {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: `query{
                        asset(id: ${id}){
                          preview
                        }
                      }`,
            },
            { "vendure-token": tokenShop }
        );
        if (!response.data.errors) {
            setImageSrc(response.data.asset.preview);
        }
    }

    const validate = () => {
        let validation = true;
        if (name) {
            setValidations((val) => ({ ...val, name: true }));
        } else {
            setValidations((val) => ({ ...val, name: false }));
            validation = false;
        }
        return validation;
    };

    const handleSaveClick = () => {
        if (validate()) {
            setLoading(true);
            createFacetCategorieValue();
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={t(props.level <= 0 ? "add-category" : "add-subcategory")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" disabled={loading} id="modal-button-add" onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            minWidth="40rem"
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex flex-col gap-3">
                    <p>{t(props.level <= 0 ? "configure-new-category" : "configure-new-subcategory")}</p>
                    <p className=" font-bold"> {`${t("category-name")} *`} </p>
                    <div className=" grid grid-cols-2 col-gap-2" style={{ gridTemplateColumns: "auto 1fr" }}>
                        <div>{`${t("language:" + langDefault)} ( ${t("default-language")} )`}</div>
                        <TextInput
                            id={"name-category-shop"}
                            value={name}
                            className={`${!validations?.name ? "border border-red-100" : ""}`}
                            onChange={(values) => {
                                setName(values);
                            }}
                        />
                    </div>
                    {props.level === 0 && (
                        <div className="flex flex-col">
                            <div className=" flex gap-3">
                                <p className=" font-bold">{t("image")}</p>
                                <Icon type="info" size="xl" tooltip={t("recommend-resolution-minimum-77")} />
                            </div>

                            <div className=" flex gap-2 items-center">
                                <div className=" mt-4 relative mr-10 w-32 h-32 rounded border-gray-600 border">
                                    {imgSrc && mediaType === IMAGE ? (
                                        <img
                                            className=" w-full h-full rounded"
                                            alt=""
                                            style={{ objectFit: "cover" }}
                                            src={getAssetUrl(imgSrc)}
                                        />
                                    ) : colorRgba && mediaType === COLOR ? (
                                        <div
                                            className=" w-full h-full rounded"
                                            style={{ backgroundColor: colorRgba ? colorRgba : null }}
                                        ></div>
                                    ) : (
                                        <div className=" flex rounded items-center justify-center text-xs text-gray-700 h-full w-full bg-gray-100">
                                            {t("not-image-yet")}
                                        </div>
                                    )}
                                </div>
                                <div className=" flex flex-col gap-4">
                                    <Radiobuttons
                                        checked={mediaType}
                                        options={[
                                            {
                                                id: "color-picker",
                                                value: COLOR,
                                                label: (
                                                    <div
                                                        className=" w-full pt-2 flex items-center"
                                                        style={{ marginTop: "-0.65rem" }}
                                                    >
                                                        <ColorPicker
                                                            onChange={(color) => {
                                                                setColorHex(color.hex);
                                                                setColorRgba(
                                                                    `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                                                                );
                                                            }}
                                                            defaultColor={colorRgba}
                                                            disabled={mediaType === IMAGE}
                                                        >
                                                            <div
                                                                id={`new-category-color-picker-modal`}
                                                                className={`rounded h-10 w-10 border bg-gray-200 border-gray-600 ${
                                                                    mediaType !== COLOR || !colorRgba
                                                                        ? "icon-no-color font-bold text-45xl text-red-100 p-px "
                                                                        : ""
                                                                } `}
                                                                style={{
                                                                    backgroundColor:
                                                                        mediaType === COLOR
                                                                            ? colorHex
                                                                                ? colorHex
                                                                                : "#fffff"
                                                                            : "#ffffff",
                                                                    fontSize: "2.3rem",
                                                                }}
                                                            ></div>
                                                        </ColorPicker>

                                                        <div className=" w-20 ml-2 ">
                                                            <TextInput
                                                                id={"shop-bg"}
                                                                key={colorHex}
                                                                placeholder={"#"}
                                                                adjust={"w-full h-10"}
                                                                value={colorHex ? colorHex : null}
                                                                disabled={mediaType !== COLOR}
                                                                onBlur={(hex) => {
                                                                    try {
                                                                        setColorHex(hex);
                                                                        setColorRgba(hexToRgbA(hex, colorAlpha));
                                                                    } catch {
                                                                        setColorRgba("rgba(255,255,255,1)");
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className=" w-24 ml-2 flex ">
                                                            <UseInputPercent
                                                                id={"shop-settings-image-color-picker"}
                                                                adjust={"w-full h-10 text-center"}
                                                                adjustArrowTop={"text-sm pb-2"}
                                                                adjustArrowBottom={"text-sm"}
                                                                key={colorAlpha}
                                                                value={colorAlpha ? colorAlpha : 100}
                                                                disabled={mediaType !== COLOR || colorRgba === null}
                                                                action={(alpha) => {
                                                                    setAlpha(alpha);
                                                                    setColorRgba(hexToRgbA(colorHex, alpha));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                            {
                                                id: "image-picker",
                                                value: IMAGE,
                                                label: (
                                                    <FileHandler
                                                        ref={inputFileRef}
                                                        id="select-file"
                                                        options={{
                                                            multiple: false,
                                                            contentTypes: ["image"],
                                                        }}
                                                        onSuccess={(v) => {
                                                            if (v?.[0]) {
                                                                uploadCategoryImage(v[0]?.file);
                                                            }
                                                        }}
                                                        onError={(error) => {
                                                            alert(error);
                                                        }}
                                                    >
                                                        <Button
                                                            id="getImageShopNewCategoryButton"
                                                            design="dark-blue"
                                                            disabled={mediaType == COLOR}
                                                            onClick={() => {
                                                                if (inputFileRef.current) {
                                                                    inputFileRef.current.click();
                                                                }
                                                            }}
                                                        >
                                                            {t("choose-an-image")}
                                                        </Button>
                                                    </FileHandler>
                                                ),
                                            },
                                        ]}
                                        onChange={setMediaType}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <p className=" font-bold"> {`${t("type-of-content")} *`} </p>
                    <p>{t("select-the-type-of-content-subcategory")}</p>
                    <Radiobuttons
                        checked={contentType}
                        options={[
                            {
                                label: t("subcategories"),
                                value: SUBCATEGORIES,
                                disabled: disabledRadioSubcategorie,
                                id: "subcategories",
                            },
                            { label: t("products"), value: PRODUCTS, id: "products" },
                        ]}
                        onChange={setContentType}
                        className={"pr-5"}
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalAddCategory = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddCategory {...newProps} />);
        },
    };
};
