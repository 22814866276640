import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { ScheduledDateColumn } from "components/Section/Design/Advertising/utils";
import Table from "components/ZafiroTable";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_LANGUAGES = gql`
    query GetLanguages {
        languages(size: 9999, byCustomer: true) {
            results {
                ref
                nativeName
            }
        }
    }
`;

// Helpers
const getDaysNamesByValues = (values, t) => {
    const daysOfWeek = ["day-1", "day-2", "day-3", "day-4", "day-5", "day-6", "day-7"];
    const translatedDays = values.map((value) => t(daysOfWeek[(value - 1) % 7]));
    return translatedDays.join(", ");
};

const getLanguagesFormatted = (languages, languageMap) => {
    const languageArray = Array.isArray(languages) ? languages : [languages];

    return languageArray
        .reduce((acc, curr) => {
            if (curr && languageMap[curr]) {
                acc.push(languageMap[curr]);
            }
            return acc;
        }, [])
        .join(", ");
};

// GraphQL Query
const GET_SIGNAGES = gql`
    query GetSignages($roomTvId: Int64!) {
        signages(filter: { roomTvTpCommonZoneId: $roomTvId }) {
            results {
                id
                name
                scheduleStart
                scheduleEnd
                available
                contentLanguageRef
                timeRanges {
                    endTime
                    startTime
                }
                isDefault
                scheduleDaysType
                scheduleDaysOfWeek
                ongoing
            }
        }
    }
`;

export const SeeSignageSchedule = ({ roomTvId }) => {
    const { t } = useTranslation();
    const [executeQuery, { data }] = useLazyQuery(GET_SIGNAGES, {
        fetchPolicy: "network-only",
        variables: { roomTvId },
    });
    const [languagesData, setLanguagesData] = useState([]);

    const [executeQueryLanguages, { dataLanguage }] = useLazyQuery(GET_LANGUAGES, {
        fetchPolicy: "network-only",
        onCompleted: (dataLanguage) => {
            setLanguagesData(dataLanguage?.languages?.results || []);
        },
    });

    const [expandedRowId, setExpandedRowId] = useState(null);

    useEffect(() => {
        executeQuery();
        executeQueryLanguages();
    }, [roomTvId]);

    const getTimesRangesFormatted = (timeRanges) => {
        if (!timeRanges || timeRanges.length === 0) {
            return t("all-day");
        }

        const formattedRanges = timeRanges
            ?.sort((a, b) => a?.endTime?.localeCompare(b.endTime))
            ?.map((range) => {
                const startTime = range.startTime.slice(0, 5);
                const endTime = range.endTime.slice(0, 5);
                return `${startTime} - ${endTime}`;
            });

        return formattedRanges.join(" / ");
    };

    const signageData = (data?.signages?.results || []).filter((val) => {
        if (!val.timeRanges || val.timeRanges.length === 0) {
            const now = new Date();
            const scheduleEndDate = new Date(`${val.scheduleEnd}T23:59:59`);
            const scheduleStartDate = new Date(`${val.scheduleStart}T00:00:00`);

            if (now < scheduleStartDate || now > scheduleEndDate) {
                return false;
            }
            return true;
        }

        return val.timeRanges.some((range) => {
            const now = new Date();
            const rangeEndTime = range.endTime ? new Date(`${val.scheduleEnd}T${range.endTime}`) : null;
            const scheduleEndDate = new Date(`${val.scheduleEnd}T23:59:59`);

            if (!rangeEndTime || rangeEndTime < now || rangeEndTime > scheduleEndDate) {
                return false;
            }

            return true;
        });
    });

    const languageMap = languagesData.reduce((acc, lang) => {
        acc[lang.ref] = lang.nativeName;
        return acc;
    }, {});

    return (
        <div className="overflow-y-auto">
            <Table
                id={"signage-table-details"}
                cols={["name", "schedule", "status", "language"]}
                showCount={true}
                header={{
                    name: { title: t("name") },
                    schedule: { title: t("schedule"), sortable: true },
                    status: { title: t("status") },
                    language: { title: t("language") },
                }}
                rows={signageData
                    .sort((a, b) => b.isDefault - a.isDefault)
                    .map((val) => {
                        const timeRangeFormatted = getTimesRangesFormatted(val.timeRanges || []);
                        const daysFormatted =
                            val.scheduleDaysType === "SPECIFIC_DAYS"
                                ? getDaysNamesByValues(val.scheduleDaysOfWeek || [], t)
                                : t("all-days-of-the-week");
                        const languagesFormatted = getLanguagesFormatted(val.contentLanguageRef || [], languageMap);

                        return {
                            id: val.id,
                            name: val.name,
                            schedule: val.isDefault ? (
                                <span className="text-white bg-blue-300 px-2 py-1 rounded">{t("default")}</span>
                            ) : (
                                ScheduledDateColumn({
                                    scheduleStart: val.scheduleStart,
                                    scheduleEnd: val.scheduleEnd,
                                })
                            ),
                            language: languagesFormatted,
                            status: (
                                <div>
                                    <span className={`${val.available ? "text-green-600" : "text-red-600"}`}>
                                        {val.available ? t("available") : t("unavailable")}
                                    </span>
                                    {val.ongoing && val.available && (
                                        <div className="text-sm mt-1">{t("on-going")}</div>
                                    )}
                                </div>
                            ),
                            rowConfig: {
                                expanded: !val.isDefault ? (
                                    <div className="expanded-row">
                                        <ExpandedRows display={daysFormatted} timeRange={timeRangeFormatted} />
                                    </div>
                                ) : null,
                                onClick: () => {
                                    if (!val.isDefault) {
                                        setExpandedRowId(expandedRowId === val.id ? null : val.id);
                                    }
                                },
                            },
                        };
                    })}
            />
        </div>
    );
};

const ExpandedRows = ({ display, timeRange }) => {
    console.log(timeRange);
    console.log(display);
    const { t } = useTranslation();

    return (
        <div className="text-sm text-gray-800 leading-6 grid grid-cols-1 w-7/12 ml-6">
            <div className="flex flex-col ml-2">
                <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <strong>{t("display")}</strong>: {display}
                </p>
                <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <strong>{t("time-range")}</strong>: {timeRange}
                </p>
            </div>
        </div>
    );
};
