import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { useDesigns } from "hooks/GraphqlCalls/Design/useDesigns";
import { useModal } from "components/Modal";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Select from "components/Select";
import Icon from "components/Icon";
import { CREATE_DESIGN, GET_DESIGN } from "hooks/GraphqlCalls/Design/utils/designsQueries";
import { useLazyQuery, useMutation } from "react-apollo";
import { Session } from "hooks/Utils/Session";

export const AddNewDesign = ({ refetch }) => {
    const { themesData, getDesignsTheme, selectProperties } = useThemes();
    const { isCorporate, chain } = useAuth();
    const [active, setActive] = useState(false);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [designName, setDesignName] = useState("");
    const [nameValidation, setNameValidation] = useState("");
    const [selectedThemeValidation, setSelectedThemeValidation] = useState("");
    const [selectedTheme, setSelectedTheme] = useState(themesData?.[0]?.options?.[0]?.value);

    const { t } = useTranslation();
    const { close } = useModal();
    const { createDesign } = useCreateDesign();

    useEffect(() => {
        getDesignsTheme();
    }, []);

    console.log(selectedTheme);
    const addDesign = () => {
        if (!designName) {
            setNameValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        } else {
            setNameValidation("");
        }
        if (!selectedTheme) {
            setSelectedThemeValidation(true);
            toast.error(t("errors-in-red"));
        } else {
            setSelectedThemeValidation(false);
        }
        if (designName && selectedTheme) {
            createDesign({
                name: designName,
                type: isCorporate ? "CORPORATE" : "LOCAL",
                currentDate: new Date()?.toISOString(),
                ...(isCorporate ? { visibleAll: selectedProperties === chain?.name } : {}),
                ...(isCorporate ? { assignedAll: selectedProperties === chain?.name && active } : {}),
                ...(isCorporate
                    ? { visibleAllProjectRef: (selectedProperties !== chain?.name && selectedProperties) || "" }
                    : {}),
                ...(isCorporate
                    ? {
                          assignedAllProjectRef:
                              (selectedProperties !== chain?.name && active && selectedProperties) || null,
                      }
                    : {}),
                themeID: selectedTheme,
            });
            setTimeout(() => refetch(), 500);
            close();
        }
    };

    return (
        <Modal
            id="add-design"
            title={t("add-design")}
            minWidth={isCorporate && `30rem`}
            className={`${isCorporate ? "w-8/12" : "w-1/3"} p-10`}
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="add" design="blue" onClick={addDesign}>
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <div className={`${isCorporate && "flex gap-3"}`}>
                <div className={`${isCorporate && "flex-1"}`}>
                    <label className="font-bold">{t("name")} *</label>
                    <p className="my-2" dangerouslySetInnerHTML={{ __html: t("design-name-instructions") }}></p>
                    <div className={`${isCorporate && "w-1/2"}`}>
                        <TextInput
                            id="design-name"
                            className={`mb-10 ${nameValidation}`}
                            onChange={(value) => setDesignName(value)}
                        />
                    </div>
                    <label className="font-bold">{t("default appearance")} *</label>
                    <p className="my-2">{t("default appearance instructions")}</p>
                    <Select
                        id="design-theme"
                        options={themesData}
                        allowUnselect={false}
                        error={selectedThemeValidation}
                        value={selectedTheme}
                        onChange={(value) => setSelectedTheme(value)}
                    />
                </div>
                {isCorporate && (
                    <div className="flex-1">
                        <label className="font-bold">{t("properties")}</label>
                        <p className="my-2">{t("design-properties-instructions")}</p>
                        <Select
                            id="properties"
                            placeholder={t("select-properties")}
                            options={selectProperties}
                            onChange={(value) => setSelectedProperties(value)}
                        />
                        <div className="mt-12">
                            <span dangerouslySetInnerHTML={{ __html: t("design-global-apply-instructions") }}></span>
                            <span>
                                <Icon
                                    type="info"
                                    className="ml-2 text-gray-700"
                                    size="xl"
                                    tooltip={t(
                                        "The default screen will only apply to those properties that have Zafiro Chain licenses"
                                    )}
                                />
                            </span>
                        </div>
                        {active ? (
                            <p
                                dangerouslySetInnerHTML={{ __html: t("design-global-apply-active") }}
                                className="text-gray-400 cursor-pointer"
                            ></p>
                        ) : (
                            <p className="t-link cursor-pointer" onClick={() => setActive(!active)}>
                                {t("design-global-apply-inactive")}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export const useCreateDesign = () => {
    const [createDesignMutation] = useMutation(CREATE_DESIGN);

    const createDesign = ({
        name,
        type,
        currentDate,
        assignedAll,
        themeID,
        visibleAllProjectRef,
        visibleAll,
        assignedAllProjectRef,
    }) => {
        createDesignMutation({
            variables: {
                name,
                type,
                currentDate,
                assignedAll,
                themeID,
                visibleAllProjectRef,
                visibleAll,
                assignedAllProjectRef,
            },
        });
    };

    return { createDesign };
};

export const useThemes = () => {
    const [themesData, setThemesData] = useState([]);

    const { isCorporate, chain } = useAuth();
    const { getChain, chainData } = useDesigns();
    const { t } = useTranslation();

    const [getDesignsTheme, { data: themes }] = useLazyQuery(
        GET_DESIGN,
        isCorporate && {
            variables: { type: ["CORPORATE"] },
        }
    );

    const projects = Session.getProjects().filter((p) => p?.hasCloud);
    const brands = JSON.parse(Session.getSessionProp("brands"));

    useEffect(() => {
        if (!chain?.name) {
            getChain();
        }
    }, [chain, chainData]);

    const selectProperties = [
        {
            label: "chain",
            options: [
                {
                    id: "all",
                    label: chain?.name,
                    value: chain?.name,
                },
            ],
        },
        {
            label: t("property-groups"),
            options: brands?.map((brand) => ({
                id: brand.id,
                label: brand.name,
                value: brand.ref,
                ref: brand.ref,
            })),
        },
        {
            label: t("properties"),
            options: projects?.map((project) => ({
                id: project.id,
                label: (
                    <div className="flex items-center gap-1">
                        {project?.hasChainModule && <Icon type="chain" size="xl" />}
                        <span>{project.name}</span>
                    </div>
                ),
                value: project.ref,
                ref: project.ref,
            })),
        },
    ];

    useEffect(() => {
        if (themes) {
            const categorizedThemes = themes?.data?.results.reduce((acc, theme) => {
                const category = theme.category === "CORPORATE" ? t("global-designs") : t("property-designs");
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({
                    id: theme.id,
                    label: theme.name,
                    value: theme.id,
                });
                return acc;
            }, {});

            const formattedThemes = Object.keys(categorizedThemes).map((category) => ({
                label: category,
                options: categorizedThemes[category],
            }));

            setThemesData(formattedThemes);
        }
    }, [themes]);

    return { themesData, getDesignsTheme, selectProperties };
};
