import React from "react";
import { useTranslation } from "react-i18next";
import UseSectionHeader from "../../../useSectionHeader";
import Table from "components/ZafiroTable";
import Modal, { useModal } from "components/Modal";
import { useListCZ } from "hooks/GraphqlCalls/Hotel/CommonZones/useListCZ";
import { BatchActionsModal } from "../Rooms/Rooms";
import { ROOM_TYPE } from "constants/rooms";
import { Session } from "hooks/Utils/Session";

export const CommonZonesList = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { rows, ready, floors, groups, refetch } = useListCZ();
    const key = `list-${new Date().getTime()}`;

    return (
        <>
            <UseSectionHeader title={t("common-zone-list")} />
            <Table
                id="common-zones-list"
                key={key}
                className="main-container"
                search={true}
                showCount={true}
                paginate={true}
                loading={!ready}
                header={{
                    name: { title: t("name"), sortable: true },
                    content: { title: t("content") },
                    group: { title: t("group") },
                    "devices-notices": {
                        title: t("device-status"),
                        sortable: true,
                    },
                }}
                rows={rows}
                cols={["name", "content", "group", "devices-notices"]}
                filters={[
                    {
                        id: "filter-by-floor",
                        title: t("floor"),
                        multiple: true,
                        options: floors,
                        onFilter: (value, row) => value.some((selectedValue) => row?.floorFilter === selectedValue),
                    },
                    {
                        id: "filter-by-group",
                        title: t("group"),
                        multiple: true,
                        options: groups,
                        onFilter: (value, row) =>
                            value.some((selectedValue) => row?.groupFilter?.includes(selectedValue)),
                    },
                ]}
                batchActions={{
                    options: [
                        {
                            id: "add-to-group",
                            label: t("add-to-group"),
                            value: "add-to-group",
                        },
                    ],
                    onChange: (action, rows) => {
                        if (action === "add-to-group") {
                            open(
                                <Modal
                                    id="add-to-group"
                                    title={<h1 className="text-2xl font-bold">{t("add-to-group")}</h1>}
                                    minWidth="30rem"
                                    className="w-1/2 p-10"
                                    onKeyPress={{ Enter: close }}
                                >
                                    <BatchActionsModal
                                        rows={rows}
                                        filters={groups}
                                        refetch={refetch}
                                        type={ROOM_TYPE.COMMONZONE}
                                    />
                                </Modal>
                            );
                        }
                    },
                }}
            />
        </>
    );
};
