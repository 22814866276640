import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst, getFromDAS } from "../../hooks/Utils/Utils";
import UseEditableField from "../../components/useEditableField";
import UseButton from "../../components/useButton";

import { changeActionValues, setActionItemAffected, setActionName, setActionValues } from "../../actions/globalActions";
import UseSelectWithSearch from "../useSelectWithSearch";
import { setSectionContent } from "../../actions/sectionActions";
import { openModal, setModalContent } from "../../actions/uiActions";
import NewInputGroup from "../../hooks/GraphqlCalls/Hotel/modals/NewInputGroup";
import { Session } from "../../hooks/Utils/Session";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { TVInputType } from "constants/devices";

export const UseModalInputsManagement = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const emptyInput = { name: null, type: null, device: null, icon: null };
    const actionData = useSelector((state) => state.action);
    const { dataToRestore } = useSelector((state) => state.sectionContent);
    const { modalContent } = useSelector((state) => state.ui);
    const actionValues = useSelector((state) => state.action.values);
    const actionDataRef = useRef();
    actionDataRef.current = actionData;

    const [inputList, setInputList] = useState();

    const INPUT_TYPES = [
        { id: TVInputType.HDMI1, name: "HDMI 1", color: "bg-blue-300" },
        { id: TVInputType.HDMI2, name: "HDMI 2", color: "bg-blue-300" },
        { id: TVInputType.HDMI3, name: "HDMI 3", color: "bg-blue-300" },
        { id: TVInputType.HDMI4, name: "HDMI 4", color: "bg-blue-300" },
        { id: TVInputType.USB, name: "USB", color: "bg-blue-100" },
        { id: TVInputType.BLUETOOTH, name: "Bluetooth", color: "bg-blue-400" },
        /*{ id: "USB3", name: "USB 3", color: "bg-blue-800" },
        { id: "USB4", name: "USB 4", color: "bg-blue-800" },*/
    ];
    const DEVICES = [{ id: "CHROMECAST", name: "Chromecast" }];

    const parseValuesToRestore = () => {
        let valuesToRestore = Session.getSessionProp("actionDataToRestore");
        if (valuesToRestore) {
            valuesToRestore = JSON.parse(valuesToRestore);
        }
        return valuesToRestore;
    };

    //Values to restore
    let valuesToRestore = parseValuesToRestore();

    const removeInput = (indexToRemove) => {
        let tmpValues = parseValuesToRestore();
        for (let index = 0; index < inputList.length; index++) {
            if (index > indexToRemove) {
                dispatch(changeActionValues({ [`input-${index - 1}-name`]: tmpValues.values[`input-${index}-name`] }));
                dispatch(changeActionValues({ [`input-${index - 1}-type`]: tmpValues.values[`input-${index}-type`] }));
                dispatch(
                    changeActionValues({ [`input-${index - 1}-device`]: tmpValues.values[`input-${index}-device`] })
                );
                dispatch(changeActionValues({ [`input-${index - 1}-icon`]: tmpValues.values[`input-${index}-icon`] }));
                dispatch(
                    changeActionValues({ [`input-${index - 1}-image`]: tmpValues.values[`input-${index}-image`] })
                );
            }
        }
        dispatch(changeActionValues({ [`input-${inputList.length - 1}-name`]: null }));
        dispatch(changeActionValues({ [`input-${inputList.length - 1}-type`]: null }));
        dispatch(changeActionValues({ [`input-${inputList.length - 1}-device`]: null }));
        dispatch(changeActionValues({ [`input-${inputList.length - 1}-icon`]: null }));
        dispatch(changeActionValues({ [`input-${inputList.length - 1}-image`]: null }));

        let tmpInputList = inputList.filter((input, index) => index !== indexToRemove);
        setInputList([]);
        setTimeout(function () {
            Session.setSessionProp("actionDataToRestore", JSON.stringify(actionDataRef.current));
            setInputList(tmpInputList);
        }, 50);

        dispatch(changeActionValues({ "input-list": tmpInputList }));
        dispatch(changeActionValues({ "inputs-number": tmpInputList.length }));
    };

    const addInput = () => {
        let tmpInputList = inputList;
        tmpInputList.push(emptyInput);
        setInputList(tmpInputList);
        dispatch(changeActionValues({ "input-list": tmpInputList }));
        dispatch(changeActionValues({ "inputs-number": tmpInputList.length }));
    };

    const cancelAction = () => {
        valuesToRestore = parseValuesToRestore();
        dispatch(setActionName(dataToRestore.actionName));
        if (dataToRestore?.inputIdInEdition) {
            dispatch(setActionItemAffected([dataToRestore.inputIdInEdition]));
        }
        if (valuesToRestore) {
            dispatch(setActionValues(valuesToRestore?.values));
            dispatch(setModalContent(NewInputGroup(valuesToRestore?.values, null, dataToRestore?.modalValue)));
            dispatch(openModal());
        }
    };

    const editIcon = (index) => {
        dispatch(setActionName(`select-input-icon`));
        dispatch(setActionItemAffected([index]));

        //Save modal current data to restore it after icon selection modal
        Session.setSessionProp("actionDataToRestore", JSON.stringify(actionData));

        if (actionValues?.[`input-${index}-icon`]) {
            dispatch(changeActionValues({ "icon-selected": actionValues[`input-${index}-icon`] }));
            dispatch(changeActionValues({ "option-resource-selected": "icon-library" }));
        }

        if (actionValues?.[`input-${index}-image`]) {
            dispatch(changeActionValues({ "image-selected": actionValues[`input-${index}-image`] }));
            dispatch(changeActionValues({ "option-resource-selected": "image-library" }));
        }
        dispatch(
            setModalContent({
                title: capitalizeFirst(t("add-icon")),
                bodyAdjust: "w-5/12",
                executeSectionQuery: true,
                inputs: [
                    {
                        text: capitalizeFirst(t("select-icon")) + ":",
                        type: "selectImageIcon",
                        closeBrowserModalAction: "open-modal-add-input-group-icon",
                        style: "",
                        value: null,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", actionCallback: () => cancelAction(), action: "none" },
                    {
                        name: "continue",
                        style: "blue",
                        action: "select-input-icon",
                    },
                ],
            })
        );
        //Launch modal
        dispatch(openModal());
    };

    useEffect(() => {
        library.add(fas);
        library.add(fab);
        //restore data
        dispatch(
            setSectionContent({
                restoreModal: true,
                actionName: actionData.actionName,
                dataToRestore: {
                    actionName: actionData.actionName,
                    modalValue: modalContent.value,
                    inputIdInEdition: actionData.itemsAffected[0],
                },
            })
        );

        if (valuesToRestore?.values?.["inputs-number"]) {
            let tmpInputList = [];
            for (let index = 0; index < valuesToRestore?.values?.["inputs-number"]; index++) {
                tmpInputList.push(emptyInput);
            }

            Object.keys(valuesToRestore.values).forEach((key) => {
                dispatch(
                    changeActionValues({
                        [key]: valuesToRestore.values[key],
                    })
                );
            });

            setInputList(tmpInputList);
        } else {
            setInputList([]);
        }

        dispatch(
            changeActionValues({
                "input-group-name": valuesToRestore?.values?.["input-group-name"],
            })
        );
        dispatch(
            changeActionValues({
                "inputs-number": valuesToRestore?.values?.["inputs-number"] || 0,
            })
        );
        // eslint-disable-next-line
    }, []);

    const noTvInputYet = () => {
        return (
            <div className="mx-2 w-full text-center border-b border-gray-200 py-2" id={`not-tv-input`}>
                {t("not-tvinput-yet")}
            </div>
        );
    };

    const printInput = (index) => {
        return (
            <div
                key={index}
                className="mx-2 w-full inline-flex border-b border-gray-200 py-2"
                id={`recipient_${index}`}
            >
                {/*<div className="w-0.5/12 pr-2 my-auto">
                    <span className="icon-drag-and-drop px-1 text-2xl text-gray-800"></span>
        </div>*/}
                <div className="w-3/12 pr-2 ">
                    <div className="w-11/12">
                        <UseEditableField
                            data={{
                                id: `input-${index}-name`,
                                name: `input-${index}-name`,
                                label: `input-${index}-name`,
                                noLabel: true,
                                placeholder: capitalizeFirst(t("input-name")),
                                value: actionValues[`input-${index}-name`],
                                inputAdjust: "h-11",
                                inEditionMode: true,
                                type: "text",
                            }}
                        ></UseEditableField>
                    </div>
                </div>
                <div className="w-3/12 pr-2 ">
                    <div className="w-11/12">
                        <UseSelectWithSearch
                            data={{
                                id: `input-${index}-type`,
                                optionData: INPUT_TYPES,
                                selectedIds: valuesToRestore?.values?.[`input-${index}-type`],
                                name: `input-${index}-type`,
                                hideSearch: true,
                                oneSelected: true,
                                selectPlaceHolder: capitalizeFirst(t("select-input")),
                                optionsAdjust: "mt-11",
                            }}
                        ></UseSelectWithSearch>
                    </div>
                </div>
                <div className="w-3/12 pr-2 ">
                    <div className="w-11/12">
                        <UseSelectWithSearch
                            data={{
                                id: `input-${index}-device`,
                                optionData: DEVICES,
                                selectedIds: valuesToRestore?.values?.[`input-${index}-device`],
                                name: `input-${index}-device`,
                                hideSearch: true,
                                cStyle: "w-11/12",
                                oneSelected: true,
                                selectPlaceHolder: capitalizeFirst(t("select-input-device")),
                                optionsAdjust: "mt-11",
                            }}
                        ></UseSelectWithSearch>
                    </div>
                </div>
                <div className="w-2/12 pr-2 ">
                    {!actionValues?.[`input-${index}-image`] && !actionValues?.[`input-${index}-icon`] ? (
                        <UseButton
                            id={`btn-add-icon-${index}`}
                            buttonName={t("add-icon")}
                            buttonColor="btn-white"
                            customClass={"w-5/12"}
                            action={function () {
                                editIcon(index);
                            }}
                        ></UseButton>
                    ) : (
                        <div className="">
                            <div className="w-2/5 rounded border border-gray-400 float-left">
                                {actionValues?.[`input-${index}-icon`]?.icon &&
                                typeof actionValues?.[`input-${index}-icon`]?.icon !== "undefined" ? (
                                    <div className="block  text-center p-2">
                                        <FontAwesomeIcon
                                            icon={[
                                                actionValues?.[`input-${index}-icon`]?.lib,
                                                actionValues?.[`input-${index}-icon`]?.icon,
                                            ]}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ width: "62%", marginLeft: "auto", marginRight: "auto" }}>
                                        <img
                                            alt=""
                                            src={getFromDAS(valuesToRestore.values[`input-${index}-image`])}
                                        ></img>
                                    </div>
                                )}
                            </div>
                            <div
                                id={`btn-add-icon-${index}`}
                                className="w-3/5 pt-3 pl-2 first-capital clickable link float-left"
                                onClick={function () {
                                    editIcon(index);
                                }}
                            >
                                {t("edit-icon")}
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-1/12 pr-2 my-auto text-center">
                    <span
                        id={`btn-delete-${index}`}
                        className="icon-delete px-1 text-2xl clickable text-gray-800"
                        onClick={(e) => removeInput(index)}
                    ></span>
                </div>
            </div>
        );
    };

    return (
        <>
            <div>
                <div>{t("manage-inputs-of-group")}</div>
                <div id="inputs_header" className="flex mt-4 pb-4 rounded border-b border-gray-200 font-bold text-lg">
                    {/* <div className="w-0.5/12 pr-2 my-auto"></div>*/}
                    <div className="pl-2 w-3/12">
                        {capitalizeFirst(t("name"))} {inputList?.length > 0 && "*"}
                    </div>
                    <div className="pl-2 w-3/12">
                        {capitalizeFirst(t("type-of-input"))} {inputList?.length > 0 && "*"}
                    </div>
                    <div className="pl-2 w-3/12">{capitalizeFirst(t("connected-device"))}</div>
                    <div className="pl-2 w-2/12">{capitalizeFirst(t("icon"))}</div>
                    <div className="w-1/12 pr-2 my-auto"></div>
                </div>
                <div
                    id="input_list"
                    className="block overflow-hidden rounded "
                    style={{
                        gridAutoRows: "min-content",
                        display: "inline-table",
                        height: "30vh",
                        width: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    {inputList?.length > 0 ? inputList?.map((input, index) => printInput(index)) : noTvInputYet()}
                </div>

                <div
                    id={`btn-add-input`}
                    className={`inline-flex mt-2 font-medium items-center text-zafiro-200 sidebar-submenu-link cursor-pointer
                `}
                    style={{
                        visibility: inputList?.length > 5 ? "hidden" : "",
                    }}
                    onClick={(e) => addInput()}
                >
                    <div className="icon icon-add mr-2"></div>
                    <div className="first-capital mt-1"> {t("add-input")}</div>
                </div>
            </div>
        </>
    );
};
