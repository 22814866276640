import React from "react";
import { useTranslation } from "react-i18next";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";

//Utils
import ListTaxZones from "../../../hooks/GraphqlCalls/Settings/ListTaxZones";
import UpdateTaxZones from "../../../hooks/GraphqlCalls/Settings/UpdateTaxZones";

import { useAuth } from "hooks/Session/auth";

import Warning from "components/Warning";

const TaxZones = () => {
    const { t } = useTranslation();
    const { hasChainModule, isCorporate, isMonoProject } = useAuth();

    ListTaxZones();

    return (
        <>
            <UseSectionHeader title={["tax-zones"]} />
            <UpdateTaxZones />
            <div>
                {!hasChainModule && !isCorporate && !isMonoProject ? (
                    <Warning icon="info" id="info-tax-zones">
                        {t("You will not be able to perform any actions on this section")}
                    </Warning>
                ) : null}
                <UseTable />
            </div>
        </>
    );
};
export default TaxZones;
