import React, { useState, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { useThemes } from "./AddDesigns";
import TextInput from "components/TextInput";
import Select from "components/Select";
import Button from "components/Button";
import Icon from "components/Icon";
import { toast } from "react-toastify";
import { EDIT_DESIGN } from "hooks/GraphqlCalls/Design/utils/designsQueries";
import { useMutation } from "react-apollo";

export const EditDesigns = ({ design, refetch }) => {
    const { isCorporate, chain } = useAuth();
    const [active, setActive] = useState(false);
    const [newAppareance, setNewAppareance] = useState(design?.themeID);
    const [defaultApparence, setDefaultApparence] = useState("");
    const [selectedProperties, setSelectedProperties] = useState(
        design?.visibleAllProject?.ref || (design?.assignedAll && chain?.name)
    );
    const [designName, setDesignName] = useState(design?.name);
    const [nameValidation, setNameValidation] = useState("");
    const { t } = useTranslation();
    const { close } = useModal();
    const { editDesignMutation } = useEditDesign();
    const { themesData, getDesignsTheme, selectProperties } = useThemes();

    useEffect(() => {
        getDesignsTheme();
    }, []);

    useEffect(() => {
        if (themesData) {
            setDefaultApparence(
                themesData?.flatMap((theme) => theme?.options?.filter((option) => option?.value === newAppareance))?.[0]
                    ?.id
            );
        }
    }, [themesData]);

    const editDesign = () => {
        if (!designName) {
            setNameValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        } else {
            editDesignMutation({
                name: designName,
                ID: design?.id,
                currentDate: new Date()?.toISOString(),
                ...(isCorporate ? { visibleAll: selectedProperties === chain?.name } : {}),
                ...(isCorporate ? { assignedAll: selectedProperties === chain?.name && active } : {}),
                ...(isCorporate
                    ? { visibleAllProjectRef: (selectedProperties !== chain?.name && selectedProperties) || "" }
                    : {}),
                ...(isCorporate
                    ? {
                          assignedAllProjectRef:
                              (selectedProperties !== chain?.name && active && selectedProperties) || null,
                      }
                    : {}),
                themeID: newAppareance,
            });
            setTimeout(() => refetch(), 500);
            close();
        }
    };

    return (
        <Modal
            id="edit-design"
            title={t("edit-design")}
            minWidth={isCorporate && `30rem`}
            className={`${isCorporate ? "w-8/12" : "w-1/3"} p-10`}
            onKeyPress={{ Enter: close }}
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="add" design="blue" onClick={editDesign}>
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <div className={`${isCorporate && "flex gap-3"}`}>
                <div className={`${isCorporate && "flex-1"}`}>
                    <label className="font-bold">{t("name")} *</label>
                    <p className="my-2" dangerouslySetInnerHTML={{ __html: t("design-name-instructions") }}></p>
                    <div className={`${isCorporate && "w-1/2"}`}>
                        <TextInput
                            id="design-name"
                            className={`mb-10 ${nameValidation}`}
                            onChange={(value) => setDesignName(value)}
                            value={designName}
                        />
                    </div>
                    <label className="font-bold">{t("default appearance")} *</label>
                    <p className="my-2">{t("default appearance instructions")}</p>
                    <Select
                        id="design-theme"
                        options={themesData}
                        search={true}
                        allowUnselect={false}
                        value={defaultApparence || ""}
                        onChange={(value) => setNewAppareance(value)}
                    />
                </div>
                {isCorporate && (
                    <div className="flex-1">
                        <label className="font-bold">{t("properties")}</label>
                        <p className="my-2">{t("design-properties-instructions")}</p>
                        <Select
                            id="properties"
                            placeholder={t("select-properties")}
                            options={selectProperties}
                            onChange={(value) => setSelectedProperties(value)}
                            value={selectedProperties}
                        />
                        <div className="mt-12">
                            <span dangerouslySetInnerHTML={{ __html: t("design-global-apply-instructions") }}></span>
                            <span>
                                <Icon
                                    type="info"
                                    className="ml-2 text-gray-700"
                                    size="xl"
                                    tooltip={t(
                                        "The default screen will only apply to those properties that have Zafiro Chain licenses"
                                    )}
                                />
                            </span>
                        </div>
                        {active ? (
                            <p
                                dangerouslySetInnerHTML={{ __html: t("design-global-apply-active") }}
                                className="text-gray-400 cursor-pointer"
                            ></p>
                        ) : (
                            <p className="t-link cursor-pointer" onClick={() => setActive(!active)}>
                                {t("design-global-apply-inactive")}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export const useEditDesign = () => {
    const [editDesign] = useMutation(EDIT_DESIGN);

    const editDesignMutation = ({
        name,
        ID,
        currentDate,
        assignedAll,
        themeID,
        visibleAllProjectRef,
        visibleAll,
        assignedAllProjectRef,
    }) => {
        editDesign({
            variables: {
                name,
                ID,
                currentDate,
                assignedAll,
                themeID,
                visibleAllProjectRef,
                visibleAll,
                assignedAllProjectRef,
            },
        });
    };

    return { editDesignMutation };
};
