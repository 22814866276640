import React, { useRef, useState } from "react";
import classNames from "classnames";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import ReactInputMask from "react-input-mask";

/**
 * CustomTimePicker Component
 *
 * @param {Object} props - Component properties.
 * @param {string} props.id - Unique identifier for the component instance.
 * @param {string} [props.value] - Initial time value in the format matching the units (e.g., "12:30:00").
 * @param {Function} [props.onChange] - Callback function invoked when the time value changes.
 * @param {Array.<string>} [props.units=['hours', 'minutes', 'seconds']] - Array of time units to include ('hours', 'minutes', 'seconds').
 * @param {boolean} [props.disabled=false] - If true, disables the input field.
 * @returns {JSX.Element} The rendered time picker component.
 */

const CustomTimePicker = ({
    id,
    value,
    onChange,
    onBlur,
    units = ["hours", "minutes", "seconds"],
    error = false,
    disabled = false,
    readOnly = false,
    closeUnitsOnSelect = false,
}) => {
    if (!id) {
        throw new Error("id in Custom Time Picker is required");
    }

    const containerRef = useRef(null);
    const customTimePickerRef = useRef(null);

    const generateMask = (units) => {
        const unitMasks = { hours: "99", minutes: "99", seconds: "99" };
        return units.map((unit) => unitMasks[unit]).join(":");
    };

    const mask = generateMask(units);
    const defaultTime = units.map(() => "00").join(":");
    const [manualInput, setManualInput] = useState(value || defaultTime);

    const inputClass = classNames("w-full rounded pl-8 pr-1 py-1 leading-7", {
        "bg-gray-200": !disabled && !readOnly,
        "bg-gray-300": disabled && !readOnly,
        "bg-transparent": readOnly,
    });

    const timeOptionButtonClass = (isSelected) =>
        classNames("px-2 text-center flex justify-center cursor-pointer mb-2", {
            "bg-zafiro-400 text-white": isSelected,
            "hover:bg-gray-200": !isSelected,
        });

    const parseTime = (timeString) => {
        const timeParts = timeString.split(":");
        const timeObj = {};
        units.forEach((unit, index) => {
            timeObj[unit] = timeParts[index] || "00";
        });
        return timeObj;
    };

    const handleSelect = (unit, selectedValue) => {
        const currentTime = parseTime(manualInput);
        const newTime = { ...currentTime, [unit]: selectedValue };
        const formattedTime = units.map((u) => newTime[u]).join(":");
        setManualInput(formattedTime);

        if (closeUnitsOnSelect && customTimePickerRef.current) {
            customTimePickerRef.current.close();
        }

        if (onChange) {
            onChange(formattedTime);
        }
    };

    const handleInputChange = (e) => {
        setManualInput(e.target.value);
    };

    const handleInputBlur = () => {
        console.log("Blur se disparó realmente (no al hacer clic en dropdown)");

        const timeParts = manualInput.split(":");
        if (timeParts.length === units.length) {
            let timeValues = {};
            units.forEach((unit, index) => {
                let val = parseInt(timeParts[index], 10);
                if (isNaN(val) || val < 0) val = 0;
                if (unit === "hours" && val > 23) val = 23;
                if ((unit === "minutes" || unit === "seconds") && val > 59) val = 59;
                timeValues[unit] = String(val).padStart(2, "0");
            });

            const formattedTime = units.map((u) => timeValues[u]).join(":");
            setManualInput(formattedTime);

            if (onChange) {
                onChange(formattedTime);
            }

            if (onBlur) {
                onBlur(formattedTime);
            }
        } else {
            setManualInput(value || defaultTime);
        }
    };

    const renderTimeGrid = (unit, range) => {
        const currentTime = parseTime(manualInput);
        return (
            <div className="flex flex-col">
                {range.map((val) => (
                    <Button
                        type="button"
                        key={`${unit}-${val}`}
                        id={`${id}-${unit}-${val}`}
                        className={timeOptionButtonClass(currentTime[unit] === val)}
                        onClick={() => handleSelect(unit, val)}
                    >
                        {val}
                    </Button>
                ))}
            </div>
        );
    };

    return (
        <div id={id} className="relative inline-block w-full" ref={containerRef}>
            <Dropdown
                disabled={disabled || readOnly}
                ref={customTimePickerRef}
                id={`dropdown-${id}`}
                showArrow={false}
                float={true}
                minWidth="handler"
                handler={
                    <div className={`relative ${error ? "border border-red-500 rounded" : ""}`}>
                        <Icon style={{ position: "absolute", top: "0.6rem", left: "0.5rem" }} type="clock" />
                        <ReactInputMask
                            mask={mask}
                            value={manualInput}
                            id={`input-${id}`}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            placeholder={units.map((unit) => unit.toUpperCase().substring(0, 2)).join(":")}
                            className={inputClass}
                            disabled={disabled || readOnly}
                        />
                    </div>
                }
            >
                <div
                    onMouseDown={(e) => e.preventDefault()}
                    className="w-full h-80  mt-2 p-4 bg-white border border-gray-300 rounded-md shadow-lg flex"
                >
                    {units.map((unit) => (
                        <div
                            key={unit}
                            style={{ maxHeight: "20rem", overflow: "scroll" }}
                            className="flex flex-col w-full custom-time-picker-unit"
                        >
                            {renderTimeGrid(
                                unit,
                                Array.from({ length: unit === "hours" ? 24 : 60 }, (_, i) => String(i).padStart(2, "0"))
                            )}
                        </div>
                    ))}
                </div>
            </Dropdown>
        </div>
    );
};

export default CustomTimePicker;
