import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useNavigate as useHistory } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";
import { useChains, useLoginChain } from "hooks/Data/useUser";

import ZafiroLogo from "components/ZafiroLogo";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Loading from "components/Loading";
import UserMenu from "components/UserMenu";

const PROFILE_STAFF = "staff";
const PROFILE_IMPERSONATE = "impersonate";

const SelectProfile = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const staffRef = useRef(null);
    const impersonateRef = useRef(null);
    const selectChainRef = useRef(null);
    const continueRef = useRef(null);

    const { lang, user, isChainLogged, isProjectLogged, logoutChain, loginChain } = useAuth();

    const { login } = useLoginChain({
        lang,
        onSuccess: (data) => {
            if (data?.token) {
                const loginData = {
                    user: {
                        id: data?.userID,
                        ref: data?.userRef,
                        pass: user?.pass, //TODO remove this when all works correctly
                        fullname: data?.userFullname,
                        superUser: data?.isSuperuser,
                        email: user?.email,
                        role: data?.userManagerRole,
                        corporate: data?.hasCorporateUser,
                        tos: data?.userTOSAccepted,
                        userAdvicedUpdate: data?.userAdvicedUpdate,
                        warnings: data?.warnings,
                    },
                    chain: {
                        id: data?.chainId,
                        name: data?.chainName,
                        ref: data?.chainRef,
                        app: data?.chainAppURL,
                        manager: data?.cloudManagerURL,
                        logo: data?.managerLogo,
                    },
                    projects: getProjects(data?.projects, data?.hasCorporateUser, t),
                };
                loginChain(loginData, data?.token);
                history("/select-project");
            }
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const { load: getChains, loading, data: chains } = useChains();

    const [profile, setProfile] = useState(null);

    const [selectedChain, setSelectedChain] = useState(null);

    const showingSelector = !loading && isChainLogged;
    const readyToContinue = profile && (profile !== PROFILE_STAFF || selectedChain || selectedChain === 0);

    useEffect(() => {
        if (isChainLogged) {
            logoutChain();
            return;
        }
        getChains();
    }, [isChainLogged, isProjectLogged]);

    useEffect(() => {
        setSelectedChain(null);
    }, [profile]);

    useEffect(() => {
        if (continueRef?.current) {
            continueRef.current.focus();
        }
    }, [selectedChain]);

    useEffect(() => {
        // Navigate through options with keyboard arrows
        const handleKeyDown = (e) => {
            const focusStaff = () => (staffRef?.current ? staffRef.current.focus() : false);
            const focusImpersonate = () => (impersonateRef?.current ? impersonateRef.current.focus() : false);
            const focusSelectChain = () => (selectChainRef?.current ? selectChainRef.current.focus() : false);
            const focusContinue = () => (continueRef?.current ? continueRef.current.focus() : false);
            if (String(e.key).startsWith("Arrow")) {
                e.preventDefault();
                if (continueRef?.current?.activeFocus()) {
                    if (e.key === "ArrowUp" && !focusSelectChain()) {
                        focusStaff();
                    }
                } else if (selectChainRef?.current?.activeFocus()) {
                    if (e.key === "ArrowUp") {
                        focusStaff();
                    } else if (e.key === "ArrowDown" && readyToContinue) {
                        focusContinue();
                    }
                } else {
                    if (e.key === "ArrowRight") {
                        focusImpersonate();
                    } else if (e.key === "ArrowLeft") {
                        focusStaff();
                    } else if (e.key === "ArrowDown" && selectChainRef?.current) {
                        focusSelectChain();
                    } else if (e.key === "ArrowDown" && readyToContinue) {
                        focusContinue();
                    }
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [showingSelector, readyToContinue]);

    const profileContinue = () => {
        if (profile === PROFILE_STAFF) {
            login({ chainRef: selectedChain?.ref });
        } else if (profile === PROFILE_IMPERSONATE) {
            history("/impersonate-list");
        }
    };

    return (
        <>
            <div className="absolute top-0 right-0 flex flex-row space-x-5 p-5">
                <UserMenu />
            </div>
            <div className="bg-white flex items-start pt-28 pb-10 justify-center min-h-screen">
                <div className="flex flex-col space-y-5 justify-center text-gray-900 text-center">
                    <ZafiroLogo />
                    <h2 className="text-2xl font-bold p-5">{t("welcome name", { name: user?.fullname })}</h2>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <h3 className="text-xl">{t("choose a profile to continue")}</h3>

                            <div className="py-5">
                                <div className="flex justify-between text-gray-600 space-x-20">
                                    <ProfileItem
                                        ref={staffRef}
                                        id="staff-profile-button"
                                        name={t("ES staff")}
                                        icon={profile === PROFILE_STAFF ? "zafiro-solid" : "zafiro-regular"}
                                        selected={profile === PROFILE_STAFF}
                                        onClick={() => setProfile(PROFILE_STAFF)}
                                    />
                                    <ProfileItem
                                        ref={impersonateRef}
                                        id="impersonate-profile-button"
                                        name={t("impersonate user")}
                                        icon={profile === PROFILE_IMPERSONATE ? "user" : "user-regular"}
                                        selected={profile === PROFILE_IMPERSONATE}
                                        onClick={() => setProfile(PROFILE_IMPERSONATE)}
                                    />
                                </div>

                                {profile === PROFILE_STAFF ? (
                                    <div className="flex flex-col justify-center mt-8 ">
                                        <div className="mb-6">{t("select account")}</div>
                                        <Select
                                            ref={selectChainRef}
                                            id="select-chain"
                                            placeholder={t("select an account")}
                                            className="w-full"
                                            options={chains?.map((chain) => ({
                                                value: chain?.id,
                                                label: chain?.name,
                                            }))}
                                            onChange={(value) => {
                                                if (value || value === 0) {
                                                    setSelectedChain(chains?.find((chain) => chain.id === value));
                                                } else {
                                                    setSelectedChain(null);
                                                }
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div>
                                <Button
                                    ref={continueRef}
                                    id="continue-profile-button"
                                    design={!readyToContinue ? "blue-outline" : "blue"}
                                    disabled={!readyToContinue}
                                    onClick={profileContinue}
                                    className="px-28 m-auto"
                                >
                                    {t("continue")}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const ProfileItem = forwardRef(({ id, name, icon, selected, onClick }, ref) => {
    const buttonClass = classNames({
        "flex flex-col space-y-5 text-center": true,
        "text-blue-300 font-bold": selected,
        "hover:font-bold hover:text-blue-300": !selected,
        "focus:font-bold focus:text-blue-300": !selected,
    });
    const iconClass = classNames({
        "rounded p-16 border-2": true,
        "border-blue-300": selected,
        "border-gray-600 hover:border-blue-300": !selected,
    });

    return (
        <Button ref={ref} id={id} className={buttonClass} onClick={onClick}>
            <div className={iconClass}>
                <Icon type={icon} size="6xl" />
            </div>
            <div>{name}</div>
        </Button>
    );
});

const getProjects = (projects, hasCorporateUser, t) => {
    projects.sort((a, b) => String(a.name).localeCompare(String(b.name)));
    //CORPORATE PROJECT
    if (hasCorporateUser) {
        projects.unshift({
            name: t("global-management"),
            trName: "global-management",
            id: 0,
            location: t("global-management-desc"),
            trLocation: "global-management-desc",
            ref: "CORPORATE",
            code: "CORPORATE",
            supportEmail: "zafiro@zafiro.tv",
            supportPhone: "1234",
            timeZone: "UTC",
            global: true,
        });
    }
    return projects;
};

export default SelectProfile;
