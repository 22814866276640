import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMatch, useResolvedPath } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";

import { useUpdateMarketing, useUpdateUnregisteredMarketing, useLogoutDevices } from "hooks/Data/useCustomer";
import Parser from "hooks/Utils/Parser";
import { Session } from "hooks/Utils/Session";

import UseSectionHeader from "components/useSectionHeader";
import ZafiroTable, { stringValue } from "components/ZafiroTable";
import Data from "components/Data";
import Button from "components/Button";
import Icon from "components/Icon";
import BooleanText from "components/BooleanText";
import Tag, { TAG_MOBILE_APP, TAG_WIFI_PORTAL, TAG_CHECK_IN } from "components/Tag";
import { useConfirm, useModal } from "components/Modal";
import ErrorInfo from "components/ErrorInfo";

const Loyalty = () => {
    const { t } = useTranslation();

    const { open: confirm } = useConfirm();

    const { update: updateMarketing } = useUpdateMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const { update: updateUnregisteredMarketing } = useUpdateUnregisteredMarketing({
        onError: (error) => {
            console.error("Error updating marketing", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const { logout: logoutDevices } = useLogoutDevices({
        onError: (error) => {
            console.error("Error logging out devices", error);
            toast.error(t("mutation-error"));
        },
        onSuccess: () => toast.success(t("operation-successful")),
    });

    const updateCustomerMarketing = (id, value) => {
        setCustomers(
            customers
                ? customers.map((c) => (c.id === id ? { ...c, marketingAccepted: value, lastUpdate: new Date() } : c))
                : []
        );
        const isNumericID = !isNaN(parseFloat(id));
        if (isNumericID) {
            updateMarketing({ id, value });
        } else {
            updateUnregisteredMarketing({ email: id, value });
        }
    };

    const [customers, setCustomers] = useState([]);

    const [getCustomersData, { loading, error, data }] = useLazyQuery(GET_CUSTOMERS_DATA, {
        fetchPolicy: "network-only",
    });
    const customersData = data?.customers?.results;
    const countries = data?.countries?.results;
    const mobileApps = data?.mobileApps?.results;
    const projects = data?.properties?.results;

    const { open: showAccessListModal } = useAccessListModal({ mobileApps, projects });

    const advancedPath = useResolvedPath("advanced");
    const advancedBack = advancedPath?.pathname.split("/").slice(0, -1).join("/");

    const isAdvanced = useMatch({ path: advancedPath?.pathname, end: true });

    const title = isAdvanced ? t("advanced-filter") : t("loyaltyCustomers");

    const tableData = customers
        ? customers
              .filter((c) => c)
              .map((customer) => {
                  const isRegistered = customer.registered;
                  const termsAccepted = !!customer.termsAccepted;
                  const marketingAccepted = !!customer.marketingAccepted;
                  const emailVerified = !!customer.emailVerified;
                  const signupOrigin = getOrigin(
                      customer.project,
                      customer.signupApp,
                      customer.signupWifi,
                      t,
                      mobileApps,
                      projects
                  );
                  const signinOrigins = customer.logins
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      .reduce((acc, login) => {
                          const { type, name, host, warning } = getOrigin(
                              customer.project,
                              login.app,
                              login.wifi,
                              t,
                              mobileApps,
                              projects
                          );
                          if (acc.filter((app) => app?.name === name && app?.type == type).length < 1) {
                              acc.push({ type, name, host, warning });
                          }
                          return acc;
                      }, []);

                  return customer
                      ? {
                            rowConfig: {
                                expanded: (
                                    <CustomerDetails
                                        customer={customer}
                                        isAdvanced={isAdvanced}
                                        mobileApps={mobileApps}
                                        projects={projects}
                                    />
                                ),
                                actions: isAdvanced
                                    ? null
                                    : [
                                          ...(isRegistered
                                              ? [
                                                    {
                                                        label: t("logout-devices"),
                                                        onClick: () => {
                                                            confirm({
                                                                id: "logout-devices",
                                                                message: Parser(
                                                                    t("logout-devices-confirm").replace(
                                                                        "%0",
                                                                        customer?.email || "-"
                                                                    )
                                                                ),
                                                                title: `${customer?.email} - ${t("logout-devices")}`,
                                                                onConfirm: () => {
                                                                    logoutDevices({ ref: customer.ref });
                                                                },
                                                                confirmText: t("logout-all"),
                                                                sensitive: true,
                                                            });
                                                        },
                                                    },
                                                ]
                                              : []),
                                          {
                                              label: t(
                                                  marketingAccepted
                                                      ? "deactivate-commercial-communications"
                                                      : "activate-commercial-communications"
                                              ),
                                              onClick: () => {
                                                  confirm({
                                                      id: "update-marketing",
                                                      message: t(
                                                          marketingAccepted
                                                              ? "deactivate-commercial-communications-confirm"
                                                              : "activate-commercial-communications-confirm",
                                                          {
                                                              account: customer?.email || "-",
                                                          }
                                                      ),
                                                      title: `${customer?.email} - ${t(
                                                          marketingAccepted
                                                              ? "deactivate-commercial-communications"
                                                              : "activate-commercial-communications"
                                                      )}`,
                                                      onConfirm: () => {
                                                          updateCustomerMarketing(customer.id, !marketingAccepted);
                                                      },
                                                  });
                                              },
                                          },
                                          ...(isRegistered
                                              ? [
                                                    {
                                                        label: t("access-list-record"),
                                                        onClick: () => {
                                                            showAccessListModal({
                                                                email: customer?.email,
                                                                logins: customer?.logins,
                                                            });
                                                        },
                                                    },
                                                ]
                                              : []),
                                      ],
                            },
                            ref: customer.ref,
                            id: customer.id,
                            email: isRegistered ? (
                                <div className="flex items-center space-x-3" alt={customer.email}>
                                    <Icon type="loyal-customers" size={1.125} className="text-zafiro-600 mt-1" />
                                    <div>{customer.email}</div>
                                </div>
                            ) : (
                                customer.email
                            ),
                            country: customer.country,
                            name: customer.name,
                            surname: customer.surname,
                            signupApp: customer.signupApp,
                            signupWifi: customer.signupWifi,
                            logins: customer.logins,
                            registered: isRegistered,
                            signup: (
                                <div className="whitespace-nowrap">
                                    {signupOrigin ? (
                                        <TagApp
                                            key={`tag-${signupOrigin?.name}`}
                                            type={signupOrigin?.type}
                                            name={signupOrigin?.name}
                                            host={signupOrigin?.host}
                                            warning={signupOrigin?.warning}
                                        >
                                            {signupOrigin?.name}
                                        </TagApp>
                                    ) : null}
                                </div>
                            ),
                            gdpr: (
                                <div alt={!!termsAccepted}>
                                    {!isRegistered || termsAccepted ? (
                                        ""
                                    ) : (
                                        <span
                                            className="text-red-100 font-bold truncate"
                                            data-tip={t("customer-pending-gdpr")}
                                            data-for="default-tooltip"
                                        >
                                            {t("gdpr")}
                                        </span>
                                    )}
                                </div>
                            ),
                            accesses: (
                                <div
                                    className="whitespace-nowrap max-w-2xl overflow-hidden"
                                    searchvalue={
                                        signinOrigins?.length
                                            ? signinOrigins.map((origin) => origin?.name).join(", ")
                                            : ""
                                    }
                                    sortvalue={signinOrigins?.length}
                                >
                                    {signinOrigins?.length
                                        ? signinOrigins.map((origin) => (
                                              <TagApp
                                                  key={`tag-${origin?.name}`}
                                                  type={origin?.type}
                                                  name={origin?.name}
                                                  host={origin?.host}
                                                  warning={origin?.warning}
                                              >
                                                  origin?.name
                                              </TagApp>
                                          ))
                                        : null}
                                </div>
                            ),
                            communications: (
                                <BooleanText
                                    alt={marketingAccepted}
                                    value={marketingAccepted}
                                    on={t("accepted")}
                                    off={t("not-accepted")}
                                />
                            ),
                            verification: (
                                <BooleanText
                                    alt={emailVerified}
                                    value={emailVerified}
                                    on={t("verified-user")}
                                    off={t("unverified")}
                                />
                            ),
                        }
                      : null;
              })
        : null;

    const filterOptionAll = { id: "all", value: null, label: `-- ${t("all")} --` };

    useEffect(() => {
        getCustomersData();
    }, []);

    useEffect(() => {
        setCustomers(
            customersData
                ? customersData.map((r) => {
                      // This is because Apollo does weird things if all records have the same ID
                      r.id = r.customerID;
                      r.registered = !!r.id;
                      if (!r.registered) {
                          // This is necessary because unregistered users don't have id or ref
                          r.id = r.email;
                          r.ref = r.email;
                      }
                      return r;
                  })
                : null
        );
    }, [customersData]);

    return (
        <>
            <UseSectionHeader title={title} navToSection={isAdvanced ? advancedBack : null} />

            {error ? (
                <ErrorInfo>{error}</ErrorInfo>
            ) : (
                <>
                    <div className="flex justify-end space-x-10 mb-2">
                        <ButtonExportCustomers data={customersData} mobileApps={mobileApps} projects={projects}>
                            {t("export-all-customer-list")}
                        </ButtonExportCustomers>

                        {!isAdvanced ? (
                            <Button
                                id="advanced-filter-button"
                                to="advanced"
                                design="classic-link"
                                className="font-bold"
                            >
                                {t("advanced-filter")}
                            </Button>
                        ) : null}
                    </div>
                    <div className="bg-white p-5 rounded">
                        <ZafiroTable
                            id="customers-list"
                            key={isAdvanced ? "advanced" : "basic"}
                            search={true}
                            showCount={true}
                            paginate={true}
                            perPage={8}
                            customTexts={{ countResults: (count) => t("x customers", { count }) }}
                            cols={
                                isAdvanced
                                    ? ["email", "accesses", "communications", "verification"]
                                    : ["email", "name", "surname", "signup", "gdpr"]
                            }
                            header={{
                                email: { title: t("email"), sortable: true, width: "18vw", minWidth: "18rem" },
                                name: { title: t("name"), sortable: true, width: "9vw", minWidth: "9rem" },
                                surname: { title: t("surname"), sortable: true, width: "12vw", minWidth: "12rem" },
                                signup: { title: t("signUp") },
                                gdpr: { align: "right" },
                                accesses: { title: t("accesses-text"), maxWidth: "20rem", sortable: true },
                                communications: { title: t("communications"), sortable: true, width: "15rem" },
                                verification: { title: t("verification"), sortable: true, width: "15rem" },
                            }}
                            loading={loading}
                            rows={tableData}
                            filters={[
                                {
                                    id: "filter-by-signup",
                                    title: isAdvanced ? t("accesses-text") : t("signUp"),
                                    multiple: true,
                                    options: [
                                        {
                                            id: "app",
                                            label: t("mobile-app"),
                                            options: [
                                                ...(mobileApps
                                                    ? mobileApps.map((app) => ({
                                                          id: app.url,
                                                          value: app.url,
                                                          label: app.shortName,
                                                      }))
                                                    : []),
                                            ],
                                        },
                                        {
                                            id: "wifi",
                                            label: t("portal-wifi"),
                                            options: [
                                                ...(projects
                                                    ? projects.map((project) => ({
                                                          id: project.ref,
                                                          value: project.ref,
                                                          label: `${t("wifi-word")} ${project.name}`,
                                                      }))
                                                    : []),
                                            ],
                                        },
                                    ],
                                    onFilter: (value, row) => {
                                        const values = value || [];
                                        if (isAdvanced) {
                                            if (row?.logins?.length) {
                                                if (
                                                    row?.logins?.find(
                                                        (login) =>
                                                            values.includes(login?.app) || values.includes(login?.wifi)
                                                    )
                                                ) {
                                                    return row;
                                                }
                                            }
                                        } else {
                                            if (values.includes(row?.signupApp) || values.includes(row?.signupWifi)) {
                                                return row;
                                            }
                                        }
                                        return null;
                                    },
                                },
                                ...(isAdvanced
                                    ? [
                                          {
                                              id: "filter-by-communications",
                                              title: t("communications"),
                                              options: [
                                                  filterOptionAll,
                                                  { id: "accepted", value: true, label: t("accepted") },
                                                  { id: "not-accepted", value: false, label: t("not-accepted") },
                                              ],
                                              onFilter: (value, row) => {
                                                  if (stringValue(row?.communications) === String(value)) {
                                                      return row;
                                                  }
                                                  return null;
                                              },
                                          },

                                          {
                                              id: "filter-by-verification",
                                              title: t("verification"),
                                              options: [
                                                  filterOptionAll,
                                                  { id: "verified-user", value: true, label: t("verified-user") },
                                                  { id: "unverified", value: false, label: t("unverified") },
                                              ],
                                              onFilter: (value, row) => {
                                                  if (stringValue(row?.verification) === String(value)) {
                                                      return row;
                                                  }
                                                  return null;
                                              },
                                          },
                                          {
                                              id: "filter-by-country",
                                              title: t("country"),
                                              options: [
                                                  filterOptionAll,
                                                  ...(countries
                                                      ? countries
                                                            .filter((c) => c)
                                                            .map((country) => {
                                                                const ref = String(country?.ref);
                                                                return {
                                                                    id: ref,
                                                                    value: ref,
                                                                    label: t(`country:${ref.toLowerCase()}`),
                                                                };
                                                            })
                                                            .sort((a, b) => a.label.localeCompare(b.label))
                                                      : []),
                                              ],
                                              onFilter: (value, row) => {
                                                  if (stringValue(row?.country) === String(value)) {
                                                      return row;
                                                  }
                                                  return null;
                                              },
                                          },
                                      ]
                                    : [
                                          {
                                              id: "filter-by-gdpr",
                                              title: t("gdpr"),
                                              options: [
                                                  filterOptionAll,
                                                  { id: "accepted", value: true, label: t("accepted") },
                                                  { id: "not-accepted", value: false, label: t("not-accepted") },
                                              ],
                                              onFilter: (value, row) => {
                                                  if (stringValue(row?.gdpr) === String(value)) {
                                                      return row;
                                                  }
                                                  return null;
                                              },
                                          },
                                      ]),
                                {
                                    id: "filter-by-registered",
                                    title: t("Registered"),
                                    options: [
                                        filterOptionAll,
                                        { id: "registered", value: true, label: t("Registered") },
                                        { id: "not-registered", value: false, label: t("Not registered") },
                                    ],
                                    onFilter: (value, row) => {
                                        if (String(row?.registered) === String(value)) {
                                            return row;
                                        }
                                        return null;
                                    },
                                },
                            ]}
                        />
                    </div>
                </>
            )}
        </>
    );
};

const TagApp = ({ name, host, type, warning, color: forceColor }) => {
    const { t } = useTranslation();
    return (
        <Tag
            key={`tag-${name}`}
            type={type}
            color={forceColor}
            icon={warning ? "warning" : null}
            tooltip={warning ? t("unknown") : host}
            tooltipType={warning ? "warning-tooltip" : "default-tooltip"}
        >
            {name}
        </Tag>
    );
};

const useAccessListModal = ({ mobileApps, projects }) => {
    const { t } = useTranslation();
    const { open: openModal, close: closeModal } = useModal();

    return {
        open: ({ email, logins }) => {
            let groupedLogins = [];
            if (logins) {
                logins
                    .filter((login) => login)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .forEach((login) => {
                        const existing = groupedLogins.find(
                            (group) => group.app === login.app && group.wifi === login.wifi
                        );
                        if (!existing) {
                            groupedLogins.push({
                                app: login.app,
                                wifi: login.wifi,
                                logins: logins
                                    ?.filter((l) => l && l.app === login.app && l.wifi === login.wifi)
                                    .map((l) => l?.date)
                                    .sort((a, b) => new Date(b) - new Date(a)),
                            });
                        }
                    });
            }

            openModal({
                id: "access-list-modal",
                title: `${email} - ${t("access-list-record")}`,
                children: (
                    <ZafiroTable
                        id="access-list"
                        search={true}
                        cols={["signin", "date"]}
                        header={{
                            signin: { title: t("accesses-text") },
                            date: { title: t("date-and-time") },
                        }}
                        rows={
                            groupedLogins?.length
                                ? groupedLogins.map((login, index) => {
                                      const origin = getOrigin(null, login.app, login.wifi, t, mobileApps, projects);
                                      const lastAccess = login.logins?.[0];
                                      const lastDate = <Data>{lastAccess ? new Date(lastAccess) : "-"}</Data>;
                                      const allDates = (
                                          <div className="flex flex-col">
                                              {login.logins?.map((date) => (
                                                  <Data key={date}>{date ? new Date(date) : "-"}</Data>
                                              ))}
                                          </div>
                                      );
                                      return {
                                          rowConfig: {
                                              expanded: login.logins?.length > 1,
                                          },
                                          signin: (
                                              <TagApp
                                                  key={`tag-${origin?.name}`}
                                                  type={origin?.type}
                                                  name={origin?.name}
                                                  host={origin?.host}
                                                  warning={origin?.warning}
                                                  alt={`${origin?.type} ${origin.host} ${origin?.name}`}
                                              >
                                                  {origin?.name}
                                              </TagApp>
                                          ),
                                          date: ({ expanded }) => (expanded ? allDates : lastDate),
                                      };
                                  })
                                : null
                        }
                    />
                ),
                footer: (
                    <Button design="blue" id="modal-button-close" onClick={closeModal}>
                        {t("close")}
                    </Button>
                ),
            });
        },
        close: closeModal,
    };
};

const GET_CUSTOMERS_DATA = gql`
    {
        customers(page: 1, size: 100000, orderBy: { field: "signupDate", criteria: "desc" }) {
            results {
                customerID: id
                ref
                name
                surname
                email
                signupApp
                signupWifi
                gender
                birthDate
                phone
                country
                language
                project
                lastAccess
                termsAccepted
                marketingAccepted
                emailVerified
                logins {
                    app
                    date
                    wifi
                }
            }
        }
        mobileApps {
            results {
                name
                shortName
                url
            }
        }
        countries(page: 1, size: 1000, byCustomer: true) {
            results {
                ref
            }
        }
        properties {
            results {
                ref
                id
                name
            }
        }
    }
`;

const CustomerDetails = ({ customer, isAdvanced, mobileApps, projects }) => {
    const { t } = useTranslation();

    const signupOrigin = getOrigin(customer.project, customer.signupApp, customer.signupWifi, t, mobileApps, projects);
    const isRegistered = !!customer.registered;
    const marketingAccepted = !!customer.marketingAccepted;
    const emailVerified = !!customer.emailVerified;
    const birthDate = customer.birthDate ? new Date(customer.birthDate).toLocaleDateString() : null;
    const countryName = customer.country ? t(`country:${customer.country.toLowerCase()}`) : null;
    const languageName = customer.language ? t(`language:${customer.language.toLowerCase()}`) : null;
    const phone = customer.phone;
    const gender = customer.gender;
    const name = customer.name;
    const surname = customer.surname;

    const getData = () => {
        if (!isRegistered) {
            // User not registered (from check-in)
            return [
                [
                    {
                        title: t("sent-commercial-communication"),
                        data: (
                            <p className={marketingAccepted ? "text-green-100" : ""}>
                                {marketingAccepted ? t("accepted") : t("not-accepted")}
                            </p>
                        ),
                    },
                ],
            ];
        } else if (isAdvanced) {
            // Advanced view for registered users
            return [
                [
                    {
                        title: t("personal-details"),
                        data: (
                            <p>
                                {t("name")}: {name || "-"} <br />
                                {t("surname")}: {surname || "-"} <br />
                                {t("birthdate")}: {birthDate || "-"}
                            </p>
                        ),
                    },
                ],
                [
                    {
                        data: (
                            <p>
                                {t("mobile-phone")}: {phone || "-"} <br />
                                {t("country")}: {countryName || "-"} <br />
                                {t("language")}: {languageName || "-"}
                            </p>
                        ),
                    },
                ],
                [
                    {
                        title: t("signUp"),
                        data: (
                            <div className="whitespace-nowrap">
                                {signupOrigin ? (
                                    <TagApp
                                        key={`tag-${signupOrigin?.name}`}
                                        type={signupOrigin?.type}
                                        name={signupOrigin?.name}
                                        host={signupOrigin?.host}
                                        warning={signupOrigin?.warning}
                                    >
                                        {signupOrigin?.name}
                                    </TagApp>
                                ) : null}
                            </div>
                        ),
                    },
                ],
            ];
        } else {
            // Basic view for registered users
            return [
                [
                    {
                        title: t("personal-details"),
                        data: (
                            <p>
                                {t("title-treatment")}: {gender ? t(gender) : "-"} <br />
                                {t("birthdate")}: {birthDate || "-"} <br />
                                {t("mobile-phone")}: {phone || "-"} <br />
                                {t("country")}: {countryName || "-"}
                            </p>
                        ),
                    },
                ],
                [
                    {
                        title: t("sent-commercial-communication"),
                        data: (
                            <p className={marketingAccepted ? "text-green-100" : ""}>
                                {marketingAccepted ? t("accepted") : t("not-accepted")}
                            </p>
                        ),
                    },
                    {
                        title: t("verification"),
                        data: (
                            <p className={emailVerified ? "text-green-100" : ""}>
                                {emailVerified ? t("verified-user") : t("unverified")}
                            </p>
                        ),
                    },
                ],
            ];
        }
    };

    const data = getData();

    return (
        <div
            className={`text-sm text-gray-800 leading-6 grid gap-4 grid-cols-${data?.length}`}
            style={{ marginLeft: "5.5rem" }}
        >
            {data.map((row, index) =>
                row ? (
                    <div key={`customer-detail-row-${index}`} className="grid grid-flow-row gap-2">
                        {row.map((col, index) => (
                            <div key={`customer-detail-col-${index}`}>
                                {col?.title ? (
                                    <p>
                                        <strong>{col.title}</strong>
                                    </p>
                                ) : null}
                                {col.data}
                            </div>
                        ))}
                    </div>
                ) : null
            )}
        </div>
    );
};

const getOrigin = (projectRef, appRef, portalRef, t, mobileApps, projects) => {
    let type, name, prefix, warning;

    if (appRef) {
        type = TAG_MOBILE_APP;
        name = getAppName(appRef, mobileApps);
        if (!name) {
            warning = true;
            name = appRef;
        }
    } else if (portalRef) {
        type = TAG_WIFI_PORTAL;
        prefix = t("wifi-word");
        name = getProjectName(portalRef, projects);
        if (!name) {
            warning = true;
            name = portalRef;
        }
    } else {
        type = TAG_CHECK_IN;
        prefix = t("Check-in");
        name = getProjectName(projectRef, projects);
        if (!name) {
            warning = true;
            name = projectRef;
        }
    }
    return { type, name, fullName: prefix ? `${prefix} ${name}` : name, host: appRef, warning };
};

const getAppName = (host, mobileApps) => {
    return host && mobileApps ? mobileApps.find((app) => app.url === host)?.shortName : null;
};

const getProjectName = (projectRef, projects) => {
    return projectRef && projects ? projects.find((project) => project.ref === projectRef)?.name : null;
};

const ButtonExportCustomers = ({ children, data, mobileApps, projects }) => {
    const { t } = useTranslation();

    return (
        <Button
            id="exportLink"
            action="csv"
            actionData={{
                filename: `${Session.getProject()?.name || "hotel"}-customers.csv`,
                headers: [
                    { label: t("email"), key: "email" },
                    { label: t("name"), key: "name" },
                    { label: t("surname"), key: "surname" },
                    { label: t("signUp"), key: "signUp" },
                    { label: t("accesses-text"), key: "access" },
                    { label: t("email-verified"), key: "emailVerified" },
                    { label: t("commercial-communications"), key: "marketingAccepted" },
                ],
                data:
                    data?.map((c) => {
                        const o = getOrigin(c.project, c.signupApp, c.signupWifi, t, mobileApps, projects);
                        return {
                            email: c.email,
                            name: c.name,
                            surname: c.surname,
                            signUp: o?.fullName,
                            access: c.logins
                                ?.map((l) => {
                                    const o = getOrigin(c.project, l.app, l.wifi, t, mobileApps, projects);
                                    return o?.fullName;
                                })
                                .join(", "),
                            emailVerified: c.emailVerified ? t("verified") : t("unverified"),
                            marketingAccepted: c.marketingAccepted ? t("accepted") : t("not-accepted"),
                        };
                    }) || [],
            }}
            design="classic-link"
            className="font-bold"
        >
            {children}
        </Button>
    );
};

export default Loyalty;
