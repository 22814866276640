import { POLLINTERVAL_30S } from "hooks/Utils/Utils";
import { useEffect, useState } from "react";
import { getTVChannelName } from "hooks/Utils/Customers/AlarmUtils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";
import { extractRoomsList } from "./utils/alarmsUtils";
import { useLazyQuery, useMutation } from "react-apollo";
import { DELETE_ALARM, GET_ALARMS } from "./utils/alarmsQueries";
import { AlarmModel } from "./models/AlarmModel";
import { AlarmStateType } from "constants/alarms";

export const useListAlarms = () => {
    const [alarmList, setAlarmList] = useState([]);
    const [roomFilter, setRoomFilter] = useState([]);
    const [loadingRefresh, setLoadingRefresh] = useState(false);

    const { t } = useTranslation();
    const { channels } = useSelector((state) => state.sectionContent);
    const { open, close } = useModal();

    const [executeQuery, { data: alarmsData, loading, error, refetch, networkStatus, called }] = useLazyQuery(
        GET_ALARMS,
        {
            pollInterval: POLLINTERVAL_30S,
        }
    );
    const [executeMutation] = useMutation(DELETE_ALARM);

    useEffect(() => {
        executeQuery({
            variables: {
                state: AlarmStateType.PENDING,
            },
        });
    }, []);

    const deleteAlarm = (id) => {
        executeMutation({
            variables: {
                id,
            },
        });
    };

    const execute = (state) => {
        executeQuery({
            variables: {
                state,
            },
        });
    };

    useEffect(() => {
        if (alarmsData && !loading && !error && networkStatus === 7) {
            const alarms = arrangeData(alarmsData);
            const roomFilter = extractRoomsList(alarmsData);
            setAlarmList(alarms);
            setRoomFilter(roomFilter);
        }
    }, [alarmsData]);

    const arrangeData = (data) => {
        const alarmsData = [];
        // eslint-disable-next-line
        data?.alarms?.results?.map((alarm) => {
            alarmsData.push(
                AlarmModel(
                    { ...alarm, channelName: getTVChannelName(channels, alarm.tvChannelId) },
                    t,
                    open,
                    deleteAlarm,
                    close,
                    refetch,
                    setLoadingRefresh
                )
            );
        });
        return alarmsData;
    };

    return { alarmList, ready: !loading && called, roomFilter, execute, refetch, setLoadingRefresh, loadingRefresh };
};
