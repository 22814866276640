import React from "react";
import { useTranslation } from "react-i18next";

import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListTemplates from "../../../hooks/GraphqlCalls/Design/ListTemplates";
import UpdateTemplate from "../../../hooks/GraphqlCalls/Design/UpdateTemplate";

const Templates = () => {
    const { t } = useTranslation();
    ListTemplates();

    return (
        <>
            <UpdateTemplate />
            <UseSectionHeader title={t("List of templates")} />
            <UseTable />
        </>
    );
};

export default Templates;
