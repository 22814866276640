import React from "react";

//Utils
import ReactTooltip from "components/ReactTooltip";
import { ItemActions } from "components/ItemActions";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";
import Icon from "components/Icon";
import { DeleteRoomModal } from "./TopologyComponents/DeleteRoomModal";
import { EditModal } from "./TopologyComponents/EditModal";

export const editName = "wingname";
export const editPmsNumber = "pmsnumber";
export const deleteWing = "delete";
export const deleteFloor = "delete";
export const editNameFloor = "floorname";

const TopologyItem = ({ data, active, showOptions, wingId, floorId, mapRefetch }) => {
    const {
        name,
        number,
        numRoomTV,
        isCommonZone,
        isRoom,
        isFloor,
        isWing,
        parentSectionName,
        defaultPmsFormat,
        recentGuests = false,
    } = data;
    const sectionName = `${parentSectionName}-item`;
    const { t } = useTranslation();
    const { open } = useModal();

    const renderTooltip = () =>
        name.length > 9 ? (
            <ReactTooltip id={`${name}_complete_tooltip`} delayShow={1000} type="light">
                {name}
            </ReactTooltip>
        ) : null;

    const renderItemActions = (actions) => (
        <div className="float-left text-gray-900 text-base">
            <ItemActions id="wing-actions" actions={actions} />
        </div>
    );

    const wingActions = [
        {
            id: `topology-action-edit-name`,
            label: t("edit-name"),
            onClick: () =>
                open(<EditModal name={name} wingId={wingId} mapRefetch={mapRefetch} mode={editName} isWing={true} />),
        },
        {
            id: `topology-action-edit-pmsnumber`,
            label: t("edit-pmsnumber"),
            onClick: () =>
                open(
                    <EditModal
                        pmsNumber={defaultPmsFormat}
                        wingId={wingId}
                        mapRefetch={mapRefetch}
                        mode={editPmsNumber}
                        isWing={true}
                    />
                ),
        },
        {
            id: `topology-action-delete`,
            label: t("delete"),
            onClick: () =>
                open(<EditModal name={name} wingId={wingId} mapRefetch={mapRefetch} mode={deleteWing} isWing={true} />),
        },
    ];

    const floorActions = [
        {
            id: `topology-action-edit-name`,
            label: t("edit-name"),
            onClick: () =>
                open(<EditModal name={name} floorId={floorId} mapRefetch={mapRefetch} mode={editNameFloor} />),
        },
        {
            id: `topology-action-delete`,
            label: t("delete"),
            onClick: () => open(<EditModal name={name} floorId={floorId} mapRefetch={mapRefetch} mode={deleteFloor} />),
        },
    ];

    const roomActions = [
        {
            id: `${sectionName}-action-edit-room`,
            label: isRoom ? t("edit-room") : t("edit-common-zone"),
            to: isRoom
                ? `/hotel/property-settings/topology/edit-room/${data.id}`
                : `/hotel/property-settings/topology/edit-common-zone/${data.id}`,
        },
        {
            id: `${sectionName}-action-delete`,
            label: t("delete"),
            onClick: () =>
                open(
                    <DeleteRoomModal
                        name={name}
                        recentGuests={recentGuests}
                        id={data?.id}
                        mapRefetch={mapRefetch}
                        isCommonZone={isCommonZone}
                    />
                ),
        },
    ];

    const isRoomOrCommonZone = isRoom || isCommonZone;

    return (
        <div
            className={`map-item-container ${isRoomOrCommonZone ? "py-2" : ""} table map-item-${
                active ? "active" : ""
            }`}
        >
            <div
                className={`block overflow-hidden whitespace-no-wrap overflow-ellipsis ${
                    isRoomOrCommonZone ? "w-9/12 leading-4" : "w-full"
                }`}
            >
                <a id={`id-${floorId || wingId}`} data-tip data-for={`${name}_complete_tooltip`} href={() => false}>
                    {isRoomOrCommonZone ? <div className="text-sm inline-block">{name}</div> : null}
                    <div>{isRoomOrCommonZone ? number : name}</div>
                </a>
                {renderTooltip()}
            </div>
            {isWing && (
                <div
                    id={`${sectionName}-actions-container`}
                    className={`field-icon-map ${!showOptions ? "hidden" : ""}`}
                >
                    {renderItemActions(wingActions)}
                </div>
            )}
            {isFloor && (
                <div
                    id={`${sectionName}-actions-container`}
                    className={`field-icon-map ${!showOptions ? "hidden" : ""}`}
                >
                    {renderItemActions(floorActions)}
                </div>
            )}
            {isRoomOrCommonZone && (
                <div id={`${sectionName}-actions-container`} className="field-icon-map">
                    {showOptions ? (
                        renderItemActions(roomActions)
                    ) : (
                        <>
                            <div className="float-left text-xl">
                                <Icon type="tv-solid" />
                            </div>
                            <div className="pl-1">{numRoomTV}</div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default TopologyItem;
