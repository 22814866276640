import { gql } from "apollo-boost";
import useGQLQuery from "hooks/useQuery";

const useThemes = ({ onSuccess, onError } = {}) => {
    const query = gql`
        query themes($filter: themeFilter) {
            themes(filter: $filter) {
                info {
                    count
                }
                results {
                    config {
                        colors {
                            accent
                            accentContrast
                            activeBackground
                            activeForeground
                            defaultBackground
                            defaultForeground
                            focusBackground
                            focusForeground
                            focusBorderSize
                            login
                        }
                        fontStyles {
                            heading {
                                alignment
                                bold
                                font {
                                    externalUrl
                                    libraryRef
                                }
                                italic
                                name
                                size
                                underline
                            }
                            paragraph {
                                alignment
                                bold
                                font {
                                    externalUrl
                                    libraryRef
                                }
                                italic
                                name
                                size
                                underline
                            }
                        }
                    }
                    contentStyle
                    hasDesigns
                    hasMobileApps
                    id
                    isCopyableToProperty
                    mobileApps {
                        mobileAppId
                    }
                    name
                    projectId
                    themeLogos {
                        id
                        imageRef
                        themeId
                        themeLogoCustoms {
                            imageRef
                            themeLogoId
                            type
                        }
                        type
                        version
                    }
                    themeType
                    type
                }
            }
        }
    `;

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.themes?.results);
            }
        },
        onError,
    });
    return {
        ...q,
        data: q.data?.themes?.results,
        count: q.data?.themes?.info?.count,
        load: ({
            filter = {
                id: undefined, // int
                designID: undefined, // int
                type: undefined, // DESIGN_TYPE = CORPORATE |  LOCAL
                themeType: undefined, // THEME_TYPE = DESIGN | MOBILE
                search: undefined, // string
            },
        } = {}) =>
            q.load({
                filter,
            }),
    };
};

export const useTheme = (id, { onSuccess, onError } = {}) => {
    const q = useThemes({
        onSuccess: (results) => {
            if (onSuccess) {
                onSuccess(results?.[0]);
            }
        },
        onError,
    });
    return {
        ...q,
        load: (params) =>
            id
                ? q.load({
                      filter: {
                          ...params?.filter,
                          id,
                      },
                  })
                : null,
        data: q.data?.[0],
        count: q.data?.[0] ? 1 : 0,
    };
};

export default useThemes;
