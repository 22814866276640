import React, { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import DeviceContentSelector from "../Hotel/CommonZones/DeviceContentSelector";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "react-apollo";
import Icon from "components/Icon";
import { toast } from "react-toastify";

export const TV_CONTENTS = gql`
    query {
        roomtvContents
    }
`;
export const UPDATE_COMMONZONE = gql`
    mutation UpdateRoomTV($roomTvID: Int64!, $contents: [String!]!) {
        updateRoomTV(roomTvID: $roomTvID, contents: $contents) {
            ok
            error
        }
    }
`;
export const GET_CONTENTS = gql`
    {
        rooms(filter: { type: COMMONZONE }) {
            results {
                id
                name
                number
                roomTVs {
                    id
                    tvInputID
                    name
                    contents
                }
            }
        }
    }
`;
function UseAvailableContentCard({ data, zoneId, roomName }) {
    const [executeContents, { data: dataContents, loading: contentsLoading }] = useLazyQuery(GET_CONTENTS, {
        fetchPolicy: "network-only",
    });

    const [executeQueryTvContents, { data: dataTvContents }] = useLazyQuery(TV_CONTENTS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (zoneId) {
            executeContents();
            executeQueryTvContents();
        }
    }, [zoneId]);

    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        if (!contentsLoading && dataContents) {
            setRooms(dataContents?.rooms?.results);
            setIsLoading(false);
        }
    }, [dataContents, contentsLoading]);

    const { headerText } = data;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t } = useTranslation();
    const { open } = useModal();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Verifica si el clic fue fuera del dropdown y del botón que lo abre
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false); // Close dropdown
            }
        };

        // Añadir el evento solo cuando el dropdown está abierto
        if (isDropdownOpen) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        // Cleanup event listener on component unmount or when dropdown is closed
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen]);

    const projectContents = dataTvContents?.roomtvContents || [];

    const [availableContents, setAvailableContents] = useState([]);

    const [updateRoomTV] = useMutation(UPDATE_COMMONZONE, {
        onCompleted: () => {
            executeContents();
        },
        onError: (err) => {
            console.error("Error updating RoomTV:", err);
        },
    });

    useEffect(() => {
        if (rooms && rooms.length > 0) {
            const filteredRooms = rooms.filter((room) => room.id === Number(zoneId));
            const roomTVs = filteredRooms.map((room) => room.roomTVs).flat();

            roomTVs.forEach((roomTv) => {
                if (roomTv.contents.length === 0) {
                    updateRoomTV({
                        variables: {
                            roomTvID: roomTv.id,
                            contents: projectContents,
                        },
                    }).catch((err) => {
                        console.error(`Failed to update RoomTV ${roomTv.id}:`, err);
                    });
                }
            });

            const contents = roomTVs
                .map((roomTv) => (roomTv.contents.length > 0 ? roomTv.contents : projectContents))
                .flat();

            setAvailableContents(contents);
        }
    }, [rooms, zoneId, projectContents]);

    const contentsUSe = Array.isArray(availableContents) ? availableContents : [];

    const predefinedContent = contentsUSe && contentsUSe.length > 0 ? contentsUSe : projectContents;

    const contentTypes = [
        { key: "TVCHANNELS", type: "tv-settings", color: "green-400", tooltip: t("tv-channels") },
        { key: "SIGNAGESEQUENCES", type: "advertising", color: "purple-900", tooltip: t("Signage") },
        { key: "CASTINSTRUCTIONSCREEN", type: "wificast", color: "zafiro-400", tooltip: t("ccinstruction-screen") },
    ];

    return (
        <div className={`dc-card-container bg-white text-blue-400 grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            {projectContents.length > 1 && (
                <div
                    id="actions-button-available-content"
                    className="icon-row-actions text-lg cursor-pointer text-right"
                    onClick={toggleDropdown}
                    ref={buttonRef}
                >
                    {isDropdownOpen && (
                        <div
                            ref={dropdownRef}
                            className="absolute mt-2 left-1/2 transform -translate-x-1/2 w-60 bg-white border border-gray-300 rounded z-50 text-left font-sans"
                        >
                            <div
                                className="px-4 py-4 cursor-pointer hover:bg-gray-100 text-left"
                                onClick={() => {
                                    open(
                                        <ManageContentModal
                                            onConfirm={() => {}}
                                            availableContents={projectContents}
                                            roomTv={rooms}
                                            roomName={roomName}
                                            contentsUSe={contentsUSe}
                                            executeQueryTvContents={executeQueryTvContents}
                                            executeContents={executeContents}
                                            dataContents={dataContents}
                                            zoneId={zoneId}
                                        />
                                    );
                                }}
                            >
                                {t("manage-content")}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className="col-span-3 flex space-x-4 mt-2 relative ">
                {contentTypes.map(({ key, type, color, tooltip }) =>
                    predefinedContent.includes(key) && projectContents.includes(key) ? (
                        <div className="border-b border-gray-100 py-4" key={key}>
                            <div
                                className={`flex items-center justify-center p-1 w-10 border-2 border-${color} rounded-lg`}
                            >
                                <Icon
                                    type={type}
                                    className={`icon inline-block text-xl lg:text-2xl text-${color} font-bold`}
                                    size={2}
                                    tooltip={tooltip}
                                />
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
}
const ManageContentModal = ({
    availableContents,
    roomName,
    contentsUSe,
    onConfirm,
    executeQueryTvContents,
    executeContents,
    dataContents,
    zoneId,
}) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const [selectedOptions, setSelectedOptions] = useState({});
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        if (dataContents?.rooms?.results) {
            const newState = {};

            const filteredRooms = dataContents.rooms.results.filter((room) => String(room.id) === String(zoneId));

            filteredRooms.forEach((room) => {
                room.roomTVs.forEach((roomTv) => {
                    const contents = roomTv.contents || [];
                    const contentsToUse = contents.length === 0 ? contentsUSe : contents;

                    newState[roomTv.id] = {
                        signage: contentsToUse.includes("SIGNAGESEQUENCES"),
                        tvChannels: contentsToUse.includes("TVCHANNELS"),
                        castScreen: contentsToUse.includes("CASTINSTRUCTIONSCREEN"),
                    };
                });
            });

            setSelectedOptions(newState);
            setInitialState(newState);
        }
    }, [dataContents, contentsUSe, zoneId]);

    const [executeUpdateRoomTV] = useMutation(UPDATE_COMMONZONE);

    const handleSave = async () => {
        const hasSelection = Object.values(selectedOptions).every((options) => {
            return options.signage || options.tvChannels || options.castScreen;
        });

        if (!hasSelection) {
            toast.error(t("please-select-an-option"));
            return;
        }

        const updates = Object.keys(selectedOptions).map((roomTvID) => {
            const options = selectedOptions[roomTvID];
            const contents = [];
            if (options.signage) contents.push("SIGNAGESEQUENCES");
            if (options.tvChannels) contents.push("TVCHANNELS");
            if (options.castScreen) contents.push("CASTINSTRUCTIONSCREEN");

            return { roomTvID: parseInt(roomTvID, 10), contents };
        });

        // Realizar la mutación para actualizar los contenidos de los roomTVs
        for (const update of updates) {
            try {
                const { data } = await executeUpdateRoomTV({
                    variables: update,
                });
                if (!data.updateRoomTV.ok) {
                    console.error("Error updating RoomTV", data.updateRoomTV.error);
                }
            } catch (error) {
                console.error("Mutation error:", error);
            }
        }

        if (executeQueryTvContents) await executeQueryTvContents();
        if (executeContents) await executeContents();

        if (onConfirm) {
            onConfirm();
        }

        toast.success(t("operation-successful"));
        close();
    };

    return (
        <Modal
            id={`manage-content-modal-${roomName}`}
            title={
                <div className="flex items-center text-3xl font-sans font-bold">
                    {`${roomName} - ${t("manage-content")}`}
                    <Icon
                        className="ml-2 -mt-1"
                        type="info"
                        tooltip={t("please-note-device-function-display-signage")}
                        size={1.5}
                        tooltipType={"dangerous-html-tooltip"}
                    />
                </div>
            }
            onClose={close}
            className="w-2/7 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" className="btn-white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="alert-save" design="blue" onClick={handleSave}>
                        {t("save")}
                    </Button>
                </footer>
            }
        >
            <div className="mt-2 mb-2">{t("select-available-content-location-common-zone")}:</div>
            <DeviceContentSelector
                roomTVs={dataContents.rooms.results
                    .filter((room) => String(room.id) === String(zoneId))
                    .flatMap((room) => room.roomTVs || [])}
                availableContents={availableContents}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
        </Modal>
    );
};

export default UseAvailableContentCard;
