export const General = [
    "apply",
    "group",
    "hotel",
    "save",
    "accept",
    "cancel",
    "erase",
    "operation-successful",
    "error-mutation",
    "hotel-selected",
    "configure-area-before-booking",
    "configure-schedule-before-booking",
    "configure-capacity-before-booking",
    "privacy-policy-title",
    "privacy-policy-text",
    "errors-in-red",
    "mutation-error",
    "group-guests",
    "filter-by",
    "selected",
    "theres-not",
    "available",
    "write-name",
    "area",
    "all",
    "allas",
    "or",
    "category",
    "check",
    "view all days",
    "check form",
    "first",
    "save-before",
    "save-before-continue",
    "unspecified",
    "search",
    "error-select-at-least-one",
    "past-date-no-valid",
    "arrival-before-departure",
    "free-access",
    "error-no-items-selected",
    "add-item",
    "items",
    "room-code",
    "showing-all",
    "showing-all-results",
    "preview",
    "next",
    "previous",
    "tvs",
    "loading-more-results",
    "no-results",
    "years",
    "search-name-email",
    "batch-title",
    "recovery-mail-to",
    "enter-code",
    "recover-password",
    "resend-code",
    "edit-name",
    "enter-name-and-translations",
    "default-lang",
    "translations",
    "manageProperties",
    "manageBrands",
    "chars",
    "media-library",
    "add-media",
    "drop-files-to-upload",
    "drop-file-to-upload",
    "or",
    "select-files",
    "select-file",
    "maximum-upload-file-size",
    "global-management",
    "global-management-desc",
    "properties",
    "input-error",
    "rooms",
    "property-groups",
    "choose",
    "error",
    "success",
    "name",
    "email",
    "duplicate",
    "tv-power-on",
    "tv-power-off",
    "tv-reload",
    "tv-power-on-text",
    "tv-power-off-text",
    "tv-uninstall-text",
    "device-uninstall-text-no-number",
    "chromecast-unassign",
    "tv-unassign-cc",
    "tv-reload-text",
    "no-results-found",
    "error-file-without-dataType",
    "error-file-not-valid-dataType",
    "search-for",
    "choose-redirecton-screen",
    "url",
    "pwa-name",
    "none",
    "link",
    "access",
    "date-and-time",
    "finance",
    "delete",
    "set-as-available",
    "set-as-unavailable",
    "error-add-at-least-one-option",
    "services",
    "orders",
];
